import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Members from './pages/Members';
import Profile from './pages/Profile';
import Announcements from './pages/Announcements';
import Login from './pages/Login';
import FileManager from './pages/FileManager';
import Activities from './pages/Activities';
import Videos from './pages/Videos';
import { useAuth } from './contexts/AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/zh-tw';
import { AuthProvider } from './contexts/AuthContext';
import PhotoSubmission from './pages/public/PhotoSubmission';
import PhotoReview from './pages/PhotoReview';
import PublicHome from './pages/public/Home';
import PublicLayout from './components/PublicLayout';
import { AnimatePresence } from 'framer-motion';
import PWAPrompt from './components/PWAPrompt';
import { useEffect } from 'react';
import Album from './pages/public/Album';
import Feedback from './pages/public/Feedback';
import FeedbackManagement from './pages/admin/FeedbackManagement';
import DepartmentManagement from './pages/DepartmentManagement';
import MeetingMinutes from './pages/document/MeetingMinutes';
import MeetingSubmissions from './pages/document/MeetingSubmissions';
import MeetingReview from './pages/document/MeetingReview';
import ArtSubmission from './pages/art/ArtSubmission';
import ArtReview from './pages/art/ArtReview';
import ArtSubmissions from './pages/art/ArtSubmissions';
import ActivitySubmission from './pages/activity/ActivitySubmission';
import ActivitySubmissions from './pages/activity/ActivitySubmissions';
import ActivityReview from './pages/activity/ActivityReview';
import TaskAssignment from './pages/tasks/TaskAssignment';
import TaskList from './pages/tasks/TaskList';
import TaskManagement from './pages/tasks/TaskManagement';
import VideoSubmit from './pages/video/VideoSubmit';
import VideoSubmissions from './pages/video/VideoSubmissions';
import VideoReview from './pages/video/VideoReview';
import VideoProgress from './pages/video/VideoProgress';
import PostManagement from './pages/posts/PostManagement';
import PostList from './pages/posts/PostList';

function ProtectedRoute({ children }) {
  const { user } = useAuth();
  if (!user) return <Navigate to="/login" />;
  return children;
}

// 創建部門路由保護組件
function DepartmentRoute({ department, children }) {
  const { user } = useAuth();
  if (!user) return <Navigate to="/login" />;
  if (user.department !== department && user.role !== 'president') {
    return <Navigate to="/dashboard" />;
  }
  return children;
}

// 創建會長路由保護組件
function PresidentRoute({ children }) {
  const { user } = useAuth();
  if (!user) return <Navigate to="/login" />;
  if (user.role !== 'president') {
    return <Navigate to="/dashboard" />;
  }
  return children;
}

// 將路由邏輯移到獨立組件
function AnimatedRoutes() {
  const location = useLocation();
  const { user } = useAuth();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        {/* 公開路由 */}
        <Route element={<PublicLayout />}>
          <Route index element={<PublicHome />} /> {/* 根路徑直接顯示 PublicHome */}
          <Route path="/home" element={<Navigate to="/" replace />} /> {/* 將 /home 重定向到根路徑 */}
          <Route path="/photo-submission" element={<PhotoSubmission />} />
          <Route path="/album" element={<Album />} />
          <Route path="/feedback" element={<Feedback />} />
        </Route>

        {/* 登入路由 */}
        <Route 
          path="/login" 
          element={!user ? <Login /> : <Navigate to="/dashboard" />} 
        />

        {/* 受保護的路由 */}
        <Route 
          path="/dashboard/*" 
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          {/* 文務組路由 */}
          <Route path="document/*" element={
            <DepartmentRoute department="文務">
              <Routes>
                <Route path="meeting-minutes" element={<MeetingMinutes />} />
                <Route path="meeting-submissions" element={<MeetingSubmissions />} />
                <Route path="meeting-review" element={<MeetingReview />} />
              </Routes>
            </DepartmentRoute>
          } />
          
          
          {/* 美宣組路由 */}
          <Route path="art/*" element={
            <DepartmentRoute department="美宣">
              <Routes>
                <Route path="submit" element={<ArtSubmission />} />
                <Route path="submissions" element={<ArtSubmissions />} />
                <Route path="review" element={<ArtReview />} />
              </Routes>
            </DepartmentRoute>
          } />
           {/* 任務相關路由 */}
           <Route path="tasks">
            {/* 指派任務只有會長可以訪問 */}
            <Route path="assign" element={
              <PresidentRoute>
                <TaskAssignment />
              </PresidentRoute>
            } />
            {/* 任務列表所有人都可以看 */}
            <Route path="list" element={<TaskList />} />
            {/* 任務管理只有會長可以訪問 */}
            <Route path="manage" element={
              <PresidentRoute>
                <TaskManagement />
              </PresidentRoute>
            } />
          </Route>
          {/* 活動組路由 */}
          <Route path="activity/*" element={
            <DepartmentRoute department="活動">
              <Routes>
                <Route path="submit" element={<ActivitySubmission />} />
                <Route path="submissions" element={<ActivitySubmissions />} />
                <Route path="review" element={<ActivityReview />} />
              </Routes>
            </DepartmentRoute>
          } />
          
          {/* 影片組路由 */}
          <Route path="video/*" element={
            <DepartmentRoute department="影器">
              <Routes>
                <Route path="submit" element={<VideoSubmit />} />
                <Route path="submissions" element={<VideoSubmissions />} />
                <Route path="review" element={<VideoReview />} />
                <Route path="progress" element={<VideoProgress />} />
              </Routes>
            </DepartmentRoute>
          } />
          
          {/* 貼文管理（只有會長可以訪問） */}
          <Route path="posts/manage" element={
            <PresidentRoute>
              <PostManagement />
            </PresidentRoute>
          } />
          
          {/* 貼文列表（所有成員可見） */}
          <Route path="posts" element={<PostList />} />
          
          {/* ... 其他路由 ... */}
          <Route index element={<Dashboard />} />
          <Route path="members" element={<Members />} />
          <Route path="profile" element={<Profile />} />
          <Route path="photo-review" element={<PhotoReview />} />
          <Route path="announcements" element={<Announcements />} />
          <Route path="feedback" element={<FeedbackManagement />} />
          <Route path="files" element={<FileManager />} />
          <Route path="activities" element={<Activities />} />
          <Route path="videos" element={<Videos />} />
          <Route path="department-management" element={
            <PresidentRoute>
              <DepartmentManagement />
            </PresidentRoute>
          } />
        </Route>

        {/* 捕捉所有未匹配的路徑，重定向到首頁 */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <PWAPrompt key="pwa-prompt" />
    </AnimatePresence>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="zh-tw">
          <AnimatedRoutes />
        </LocalizationProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App; 