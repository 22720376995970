import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
  Grid
} from '@mui/material';
import { supabase } from '../../utils/supabase';
import { useAuthCheck } from '../../hooks/useAuthCheck';

export default function TaskManagement() {
  const { loading: authLoading, authorized, user } = useAuthCheck(null, true, ['president']);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const departments = ['文務', '美宣', '活動', '媒體'];
  const priorities = [
    { value: 'high', label: '高' },
    { value: 'medium', label: '中' },
    { value: 'low', label: '低' }
  ];

  useEffect(() => {
    if (authorized) {
      fetchTasks();
    }
  }, [authorized]);

  const fetchTasks = async () => {
    try {
      const { data, error } = await supabase
        .from('tasks')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;

      setTasks(data);
    } catch (error) {
      console.error('Error:', error);
      setError('獲取任務列表失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    try {
      if (!editData.title || !editData.department) {
        throw new Error('請填寫必填欄位');
      }

      const { error: updateError } = await supabase
        .from('tasks')
        .update({
          ...editData,
          updated_at: new Date().toISOString()
        })
        .eq('id', selectedTask.id);

      if (updateError) throw updateError;

      setSuccess('任務已更新');
      fetchTasks();
      setOpenDialog(false);
      setEditMode(false);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || '更新失敗');
    }
  };

  const handleDelete = async (taskId) => {
    try {
      const { error: deleteError } = await supabase
        .from('tasks')
        .delete()
        .eq('id', taskId);

      if (deleteError) throw deleteError;

      setSuccess('任務已刪除');
      fetchTasks();
      setOpenDialog(false);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || '刪除失敗');
    }
  };

  const getPriorityChip = (priority) => {
    const config = {
      high: { label: '高', color: 'error' },
      medium: { label: '中', color: 'warning' },
      low: { label: '低', color: 'success' }
    };
    return <Chip label={config[priority].label} color={config[priority].color} size="small" />;
  };

  const getStatusChip = (status) => {
    const config = {
      pending: { label: '待處理', color: 'warning' },
      in_progress: { label: '進行中', color: 'info' },
      completed: { label: '已完成', color: 'success' }
    };
    return <Chip label={config[status].label} color={config[status].color} size="small" />;
  };

  if (authLoading || loading) {
    return <CircularProgress />;
  }

  if (!authorized) return null;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          任務管理
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>任務標題</TableCell>
                <TableCell>部門</TableCell>
                <TableCell>優先級</TableCell>
                <TableCell>截止日期</TableCell>
                <TableCell>狀態</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task) => (
                <TableRow key={task.id}>
                  <TableCell>{task.title}</TableCell>
                  <TableCell>{task.department}</TableCell>
                  <TableCell>{getPriorityChip(task.priority)}</TableCell>
                  <TableCell>{task.due_date || '無'}</TableCell>
                  <TableCell>{getStatusChip(task.status)}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      onClick={() => {
                        setSelectedTask(task);
                        setEditData(task);
                        setOpenDialog(true);
                        setEditMode(false);
                      }}
                    >
                      管理
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog 
        open={openDialog} 
        onClose={() => {
          setOpenDialog(false);
          setEditMode(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editMode ? '編輯任務' : '任務詳情'}
        </DialogTitle>
        <DialogContent>
          {selectedTask && (
            <Box sx={{ pt: 2 }}>
              {editMode ? (
                // 編輯模式
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="任務標題"
                      value={editData.title}
                      onChange={(e) => setEditData({ ...editData, title: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="任務描述"
                      value={editData.description || ''}
                      onChange={(e) => setEditData({ ...editData, description: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth required>
                      <InputLabel>部門</InputLabel>
                      <Select
                        value={editData.department}
                        label="部門"
                        onChange={(e) => setEditData({ ...editData, department: e.target.value })}
                      >
                        {departments.map((dept) => (
                          <MenuItem key={dept} value={dept}>{dept}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="date"
                      label="截止日期"
                      value={editData.due_date || ''}
                      onChange={(e) => setEditData({ ...editData, due_date: e.target.value })}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>優先級</InputLabel>
                      <Select
                        value={editData.priority}
                        label="優先級"
                        onChange={(e) => setEditData({ ...editData, priority: e.target.value })}
                      >
                        {priorities.map((priority) => (
                          <MenuItem key={priority.value} value={priority.value}>
                            {priority.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                // 查看模式
                <>
                  <Typography variant="subtitle2">任務標題</Typography>
                  <Typography paragraph>{selectedTask.title}</Typography>
                  
                  <Typography variant="subtitle2">任務描述</Typography>
                  <Typography paragraph>{selectedTask.description || '無'}</Typography>
                  
                  <Typography variant="subtitle2">部門</Typography>
                  <Typography paragraph>{selectedTask.department}</Typography>
                  
                  <Typography variant="subtitle2">截止日期</Typography>
                  <Typography paragraph>{selectedTask.due_date || '無'}</Typography>
                  
                  <Typography variant="subtitle2">優先級</Typography>
                  <Box sx={{ mb: 2 }}>{getPriorityChip(selectedTask.priority)}</Box>
                  
                  <Typography variant="subtitle2">狀態</Typography>
                  <Box sx={{ mb: 2 }}>{getStatusChip(selectedTask.status)}</Box>
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {editMode ? (
            <>
              <Button onClick={() => setEditMode(false)}>取消</Button>
              <Button onClick={handleEdit} variant="contained">
                保存
              </Button>
            </>
          ) : (
            <>
              <Button 
                color="error" 
                onClick={() => handleDelete(selectedTask.id)}
              >
                刪除
              </Button>
              <Button onClick={() => setEditMode(true)}>
                編輯
              </Button>
              <Button onClick={() => setOpenDialog(false)}>
                關閉
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
} 