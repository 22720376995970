import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Alert,
  CircularProgress
} from '@mui/material';
import { useAuthCheck } from '../../hooks/useAuthCheck';
import { supabase } from '../../utils/supabase';

export default function PostManagement() {
  const { loading: authLoading, authorized, user } = useAuthCheck(null, true, ['president']);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    image_url: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const { error: uploadError } = await supabase
        .from('posts')
        .insert({
          title: formData.title,
          content: formData.content,
          image_url: formData.image_url,
          author: user.name,
          created_at: new Date().toISOString()
        });

      if (uploadError) throw uploadError;

      setSuccess('貼文發布成功！');
      setFormData({
        title: '',
        content: '',
        image_url: ''
      });
    } catch (error) {
      console.error('Error:', error);
      setError('貼文發布失敗');
    } finally {
      setLoading(false);
    }
  };

  if (authLoading) return <CircularProgress />;
  if (!authorized) return null;

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          發布貼文
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            required
            label="標題"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />

          <TextField
            required
            label="內容"
            multiline
            rows={4}
            value={formData.content}
            onChange={(e) => setFormData({ ...formData, content: e.target.value })}
          />

          <TextField
            label="圖片連結（選填）"
            value={formData.image_url}
            onChange={(e) => setFormData({ ...formData, image_url: e.target.value })}
          />

          <Button 
            type="submit" 
            variant="contained" 
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? '發布中...' : '發布貼文'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
} 