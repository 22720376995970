import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  CircularProgress
} from '@mui/material';
import { supabase } from '../../utils/supabase';
import { useAuthCheck } from '../../hooks/useAuthCheck';

export default function VideoReview() {
  const { loading: authLoading, authorized, user } = useAuthCheck(null, true, ['president']);
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [reviewComment, setReviewComment] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (authorized) {
      fetchSubmissions();
    }
  }, [authorized]);

  const fetchSubmissions = async () => {
    try {
      const { data, error } = await supabase
        .from('video_proposals')
        .select('*')
        .eq('department', '影片')
        .eq('status', 'pending')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setSubmissions(data);
    } catch (error) {
      console.error('Error:', error);
      setError('獲取提交記錄失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleReview = async (status) => {
    try {
      if (status === 'rejected' && !reviewComment) {
        throw new Error('駁回時必須填寫審核意見');
      }

      const { error } = await supabase
        .from('video_proposals')
        .update({
          status: status,
          review_comment: reviewComment,
          reviewed_by: user.id,
          reviewer_name: user.name,
          reviewed_at: new Date().toISOString(),
          progress_status: status === 'approved' ? 'preparing' : null,
          updated_at: new Date().toISOString()
        })
        .eq('id', selectedSubmission.id);

      if (error) throw error;

      setSuccess(`企劃已${status === 'approved' ? '通過' : '駁回'}`);
      setOpenDialog(false);
      setReviewComment('');
      fetchSubmissions();
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || '審核失敗');
    }
  };

  if (authLoading || loading) {
    return <CircularProgress />;
  }

  if (!authorized) return null;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          影片企劃審核
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>影片標題</TableCell>
                <TableCell>提交者</TableCell>
                <TableCell>提交時間</TableCell>
                <TableCell>預計完成日期</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {submissions.map((submission) => (
                <TableRow key={submission.id}>
                  <TableCell>{submission.title}</TableCell>
                  <TableCell>{submission.submitted_by}</TableCell>
                  <TableCell>
                    {new Date(submission.submitted_at).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {new Date(submission.deadline).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      onClick={() => {
                        setSelectedSubmission(submission);
                        setOpenDialog(true);
                      }}
                    >
                      審核
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>審核影片企劃</DialogTitle>
        <DialogContent>
          {selectedSubmission && (
            <Box sx={{ pt: 2 }}>
              <Typography variant="subtitle2">影片標題</Typography>
              <Typography paragraph>{selectedSubmission.title}</Typography>
              
              <Typography variant="subtitle2">影片說明</Typography>
              <Typography paragraph>{selectedSubmission.description || '無'}</Typography>
              
              <Typography variant="subtitle2">創意概念</Typography>
              <Typography paragraph>{selectedSubmission.concept || '無'}</Typography>
              
              <Typography variant="subtitle2">目標觀眾</Typography>
              <Typography paragraph>{selectedSubmission.target_audience || '無'}</Typography>
              
              <Typography variant="subtitle2">腳本/分鏡</Typography>
              <Typography paragraph>{selectedSubmission.script || '無'}</Typography>
              
              <Typography variant="subtitle2">需要器材</Typography>
              <Box sx={{ mb: 2 }}>
                {selectedSubmission.equipment?.map((item, index) => (
                  <Chip key={index} label={item} sx={{ mr: 1, mb: 1 }} />
                ))}
              </Box>

              <TextField
                fullWidth
                multiline
                rows={4}
                label="審核意見"
                value={reviewComment}
                onChange={(e) => setReviewComment(e.target.value)}
                sx={{ mt: 2 }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>取消</Button>
          <Button 
            color="error" 
            onClick={() => handleReview('rejected')}
          >
            駁回
          </Button>
          <Button 
            color="primary" 
            onClick={() => handleReview('approved')}
          >
            通過
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
} 