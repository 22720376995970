import { Outlet } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Container,
  Box,
  useScrollTrigger,
  Slide,
  keyframes
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { motion } from 'framer-motion';

// 定義動畫
const marquee = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(-100%); }
`;

const sparkle = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

// 定義動畫變體
const pageTransition = {
  initial: { opacity: 0, y: 20 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.5 }
  },
  exit: { 
    opacity: 0, 
    y: -20,
    transition: { duration: 0.3 }
  }
};

// 添加 iOS PWA 檢測
const isIOSPWA = window.navigator.standalone;

export default function PublicLayout() {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageTransition}
    >
      <Box 
        className={isIOSPWA ? 'ios-pwa' : ''}
        sx={{ 
          minHeight: '100vh',
          display: 'flex', 
          flexDirection: 'column',
          overflow: 'hidden'
        }}
      >
        <Box
          className={isIOSPWA ? 'ios-pwa-content' : ''}
          sx={{ 
            flex: 1,
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch'
          }}
        >
          <AppBar 
            position="fixed" 
            elevation={0}
            sx={{ 
              backgroundColor: 'rgba(255, 255, 255, 0.95)', 
              backdropFilter: 'blur(8px)',
              borderBottom: 'none',
              zIndex: (theme) => theme.zIndex.drawer + 2
            }}
          >
            <Container maxWidth="lg">
              <Toolbar 
                sx={{ 
                  minHeight: { xs: '56px', md: '64px' },
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: { xs: 1, md: 2 },
                  px: { xs: 1, md: 2 }
                }}
              >
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: { xs: 0.5, md: 2 }
                  }}
                >
                  <img 
                    src="/qq.png" 
                    alt="Logo"
                    style={{ 
                      height: '28px',
                      width: 'auto'
                    }} 
                  />
                  <Typography 
                    variant="h6" 
                    component="div" 
                    sx={{ 
                      cursor: 'pointer',
                      fontWeight: 700,
                      color: 'primary.main',
                      letterSpacing: { xs: 0, md: '0.5px' },
                      fontSize: { xs: '0.9rem', md: '1.25rem' },
                      whiteSpace: 'nowrap'
                    }}
                    onClick={() => navigate('/home')}
                  >
                    中山畢聯會
                  </Typography>
                </Box>
                
                <Box 
                  sx={{ 
                    display: 'flex', 
                    gap: { xs: 0.5, md: 2 },
                    zIndex: 2
                  }}
                >
                  <Button 
                    variant="outlined"
                    onClick={() => navigate('/photo-submission')}
                    sx={{
                      minWidth: { xs: 'auto', md: '80px' },
                      py: { xs: 0.3, md: 1 },
                      px: { xs: 1, md: 2 },
                      fontSize: { xs: '0.75rem', md: '0.875rem' },
                    }}
                  >
                    上傳照片
                  </Button>
                  {user ? (
                    <Button 
                      variant="contained"
                      onClick={() => navigate('/dashboard')}
                      sx={{
                        background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
                        boxShadow: '0 3px 5px 2px rgba(26, 35, 126, .3)',
                        color: 'white',
                        textTransform: 'none',
                        fontWeight: 500,
                        fontSize: { xs: '0.8rem', md: '0.875rem' },
                        py: { xs: 0.5, md: 1 },
                        px: { xs: 1.5, md: 2 },
                        '&:hover': {
                          background: 'linear-gradient(45deg, #0d47a1 30%, #1a237e 90%)',
                        }
                      }}
                    >
                      進入系統
                    </Button>
                  ) : (
                    <Button 
                      variant="contained"
                      onClick={() => navigate('/login')}
                      sx={{
                        background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
                        boxShadow: '0 3px 5px 2px rgba(26, 35, 126, .3)',
                        color: 'white',
                        textTransform: 'none',
                        fontWeight: 500,
                        fontSize: { xs: '0.8rem', md: '0.875rem' },
                        py: { xs: 0.5, md: 1 },
                        px: { xs: 1.5, md: 2 },
                        '&:hover': {
                          background: 'linear-gradient(45deg, #0d47a1 30%, #1a237e 90%)',
                        }
                      }}
                    >
                      登入
                    </Button>
                  )}
                </Box>
              </Toolbar>
            </Container>

            {/* 跑馬燈 */}
            <Box 
              sx={{ 
                width: '100%',
                background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
                color: 'white',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                height: { xs: '32px', md: '36px' },
                borderTop: '1px solid rgba(255,255,255,0.1)',
                boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.2)'
              }}
            >
              <Box
                sx={{
                  whiteSpace: 'nowrap',
                  animation: {
                    xs: `${marquee} 10s linear infinite`,
                    md: `${marquee} 15s linear infinite`
                  },
                  display: 'flex',
                  alignItems: 'center',
                  '& > *': {
                    mx: { xs: 1, md: 3 }
                  }
                }}
              >
                {[...Array(2)].map((_, index) => (
                  <Typography 
                    key={index}
                    variant="body2"
                    component="span"
                    sx={{ 
                      fontWeight: 500,
                      fontSize: { xs: '0.85rem', md: '0.95rem' },
                      display: 'flex',
                      alignItems: 'center',
                      gap: { xs: 1, md: 2 },
                      letterSpacing: { xs: '0.3px', md: '0.5px' },
                      '& .star': {
                        animation: `${sparkle} 1.5s ease-in-out infinite`,
                        fontSize: { xs: '0.9rem', md: '1rem' }
                      }
                    }}
                  >
                    <span className="star">✨</span>
                    114學年度學測加油！學測就上！
                    <span style={{ opacity: 0.6 }}>|</span>
                    <span className="star">🎯</span>
                    相信自己，你一定可以！
                  </Typography>
                ))}
              </Box>
            </Box>
          </AppBar>

          <Box 
            component="main" 
            sx={{ 
              flexGrow: 1,
              pt: { xs: 11, md: 12 },
              pb: 3,
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              <Outlet />
            </motion.div>
          </Box>

          {/* Footer 部分 */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            <Box 
              component="footer" 
              sx={{ 
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: theme => theme.palette.grey[200]
              }}
            >
              <Container maxWidth="lg">
                <Typography variant="body2" color="text.secondary" align="center">
                  © {new Date().getFullYear()} 中山高中畢業生聯合會
                </Typography>
              </Container>
            </Box>
          </motion.div>
        </Box>
      </Box>
    </motion.div>
  );
}
