import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Alert,
  CircularProgress
} from '@mui/material';
import { supabase } from '../../utils/supabase';
import { useAuth } from '../../contexts/AuthContext';
import { useAuthCheck } from '../../hooks/useAuthCheck';

export default function ArtSubmission() {
  const { loading: authLoading, authorized, user } = useAuthCheck('美宣', true);
  const [formData, setFormData] = useState({
    title: '',
    description: ''
  });
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      // 創建預覽
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setUploading(true);
      setError(null);

      if (!file) {
        throw new Error('請選擇圖片');
      }

      if (!formData.title) {
        throw new Error('請輸入作品標題');
      }

      // 1. 上傳圖片
      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}.${fileExt}`;

      const { error: uploadError, data } = await supabase.storage
        .from('art-images')
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) {
        console.error('Upload error:', uploadError);
        throw new Error('圖片上傳失敗');
      }

      // 2. 獲取圖片URL
      const { data: { publicUrl } } = supabase.storage
        .from('art-images')
        .getPublicUrl(fileName);

      // 3. 創建提交記錄
      const submitData = {
        title: formData.title,
        description: formData.description,
        image_url: publicUrl,
        department: '美宣',
        submitted_by: user.name,
        submitted_at: new Date().toISOString(),
        status: 'pending'
      };

      console.log('Submitting data:', submitData);

      const { error: submitError } = await supabase
        .from('art_submissions')
        .insert([submitData]);

      if (submitError) {
        console.error('Submit error:', submitError);
        throw new Error('記錄創建失敗');
      }

      setSuccess('作品已提交，等待審核');
      setFormData({ title: '', description: '' });
      setFile(null);
      setPreview(null);
    } catch (error) {
      console.error('Error:', error);
      setError(typeof error === 'string' ? error : error.message || '提交失敗');
    } finally {
      setUploading(false);
    }
  };

  if (authLoading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          提交美宣作品
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            required
            label="作品標題"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          
          <TextField
            label="作品描述"
            multiline
            rows={4}
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />

          <Box>
            <input
              accept="image/*"
              type="file"
              id="image-upload"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <label htmlFor="image-upload">
              <Button variant="outlined" component="span">
                選擇圖片
              </Button>
            </label>
          </Box>

          {preview && (
            <Box sx={{ mt: 2 }}>
              <img 
                src={preview} 
                alt="預覽" 
                style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }} 
              />
            </Box>
          )}

          <Button 
            type="submit" 
            variant="contained" 
            disabled={uploading}
            sx={{ mt: 2 }}
          >
            {uploading ? '提交中...' : '提交'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
} 