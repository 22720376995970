import { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Skeleton,
  Dialog,
  IconButton,
  useTheme,
  useMediaQuery,
  Fade,
  Zoom,
  Chip,
  Stack,
  TextField,
  InputAdornment,
  Alert,
  Tabs,
  Tab,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse
} from '@mui/material';
import {
  Close as CloseIcon,
  Search as SearchIcon,
  Class as ClassIcon,
  PhotoLibrary as PhotoIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from '../../utils/supabase';
import { CLASS_MAPPING, getClassDisplayName } from '../../utils/classMapping';

export default function Album() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [selectedClass, setSelectedClass] = useState('all');
  const [classes, setClasses] = useState([]);
  const [expandedClass, setExpandedClass] = useState(null);

  // 照片類型選項
  const photoTypes = [
    { value: 'personal', label: '個人照' },
    { value: 'friends', label: '朋友合照' },
    { value: 'class', label: '全班合照' }
  ];

  useEffect(() => {
    fetchPhotos();
  }, [selectedType, searchTerm, selectedClass]);

  const fetchPhotos = async () => {
    try {
      let query = supabase
        .from('photo_submissions')
        .select('*')
        .eq('status', 'pending')
        .order('created_at', { ascending: false });

      if (selectedType !== 'all') {
        query = query.eq('photo_type', selectedType);
      }

      if (selectedClass !== 'all') {
        query = query.eq('class', selectedClass);
      }

      if (searchTerm) {
        query = query.or(`student_name.ilike.%${searchTerm}%`);
      }

      const { data, error } = await query;

      if (error) throw error;

      // 獲取所有不重複的班級
      const uniqueClasses = [...new Set(data.map(photo => photo.class))].sort();
      setClasses(uniqueClasses);

      const photosWithUrls = await Promise.all(data.map(async (photo) => {
        const { data: signedUrlData } = await supabase.storage
          .from('yearbook')
          .createSignedUrl(photo.photo_url, 3600);

        return {
          ...photo,
          publicUrl: signedUrlData?.signedUrl
        };
      }));

      const validPhotos = photosWithUrls.filter(photo => photo.publicUrl);
      setPhotos(validPhotos);

    } catch (error) {
      console.error('Error fetching photos:', error);
      setError('載入照片時發生錯誤');
    } finally {
      setLoading(false);
    }
  };

  // 按班級分組照片
  const groupedPhotos = photos.reduce((acc, photo) => {
    if (!acc[photo.class]) {
      acc[photo.class] = [];
    }
    acc[photo.class].push(photo);
    return acc;
  }, {});

  // 處理班級點擊
  const handleClassClick = (className) => {
    setExpandedClass(expandedClass === className ? null : className);
  };

  return (
    <Box sx={{ py: { xs: 4, md: 8 }, bgcolor: '#f8f9fa' }}>
      <Container maxWidth="lg">
        <Fade in timeout={800}>
          <Typography 
            variant="h4" 
            sx={{ 
              mb: 4,
              fontWeight: 700,
              textAlign: 'center',
              position: 'relative',
              '&::after': {
                content: '""',
                display: 'block',
                width: '60px',
                height: '4px',
                background: 'linear-gradient(90deg, #1a237e 0%, #0d47a1 100%)',
                margin: '16px auto 0',
                borderRadius: '2px'
              }
            }}
          >
            畢業相簿
          </Typography>
        </Fade>

        {/* 搜尋區 */}
        <Box sx={{ mb: 4 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="搜尋姓名..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {error && (
          <Alert 
            severity="error" 
            sx={{ mb: 3 }}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        )}

        {/* 班級列表 */}
        {loading ? (
          [...Array(8)].map((_, index) => (
            <Skeleton 
              key={index}
              variant="rectangular" 
              height={72} 
              sx={{ 
                borderRadius: 2,
                mb: 2
              }}
            />
          ))
        ) : (
          <List sx={{ 
            width: '100%',
            bgcolor: 'background.paper',
            borderRadius: 2,
            overflow: 'hidden'
          }}>
            {Object.entries(groupedPhotos)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([className, classPhotos]) => (
                <Box key={className}>
                  <ListItemButton 
                    onClick={() => handleClassClick(className)}
                    sx={{
                      borderRadius: 1,
                      mb: 1,
                      bgcolor: expandedClass === className ? 'action.selected' : 'transparent',
                      '&:hover': {
                        bgcolor: 'action.hover'
                      }
                    }}
                  >
                    <ListItemIcon>
                      <ClassIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText 
                      primary={getClassDisplayName(className)}
                      secondary={`${classPhotos.length} 張照片`}
                      primaryTypographyProps={{
                        fontWeight: 600
                      }}
                    />
                    {expandedClass === className ? 
                      <ExpandLessIcon /> : 
                      <ExpandMoreIcon />
                    }
                  </ListItemButton>

                  <Collapse 
                    in={expandedClass === className} 
                    timeout="auto" 
                    unmountOnExit
                  >
                    <Box sx={{ p: 2 }}>
                      <Grid container spacing={3}>
                        {classPhotos.map((photo) => (
                          <Grid item xs={12} sm={6} md={4} lg={3} key={photo.id}>
                            <Zoom in timeout={500}>
                              <Card
                                sx={{
                                  cursor: 'pointer',
                                  transition: 'all 0.3s ease',
                                  '&:hover': {
                                    transform: 'translateY(-8px)',
                                    boxShadow: '0 12px 24px rgba(0,0,0,0.15)'
                                  }
                                }}
                                onClick={() => setSelectedPhoto(photo)}
                              >
                                <CardMedia
                                  component="img"
                                  height="200"
                                  image={photo.publicUrl}
                                  alt={`${photo.student_name}的照片`}
                                  sx={{ objectFit: 'cover' }}
                                />
                                <CardContent>
                                  <Typography variant="subtitle2" noWrap>
                                    {photo.student_name}
                                  </Typography>
                                  <Chip
                                    icon={<PhotoIcon />}
                                    label={
                                      photo.photo_type === 'personal' ? '個人照' :
                                      photo.photo_type === 'friends' ? '朋友合照' :
                                      '全班合照'
                                    }
                                    size="small"
                                    sx={{ mt: 1 }}
                                  />
                                </CardContent>
                              </Card>
                            </Zoom>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Collapse>
                </Box>
            ))}
          </List>
        )}

        {/* 照片查看對話框 */}
        <Dialog
          fullScreen={isMobile}
          open={Boolean(selectedPhoto)}
          onClose={() => setSelectedPhoto(null)}
          maxWidth="lg"
          PaperProps={{
            sx: {
              bgcolor: 'rgba(0,0,0,0.9)',
              boxShadow: 'none'
            }
          }}
        >
          {selectedPhoto && (
            <Box sx={{ position: 'relative' }}>
              <IconButton
                onClick={() => setSelectedPhoto(null)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'white'
                }}
              >
                <CloseIcon />
              </IconButton>
              
              <Box
                component="img"
                src={selectedPhoto.publicUrl}
                alt={`${selectedPhoto.student_name}的照片`}
                sx={{
                  width: '100%',
                  height: '100%',
                  maxHeight: '90vh',
                  objectFit: 'contain'
                }}
              />
            </Box>
          )}
        </Dialog>
      </Container>
    </Box>
  );
}