import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Chip,
  Stack
} from '@mui/material';
import { supabase } from '../../utils/supabase';
import { useAuthCheck } from '../../hooks/useAuthCheck';

export default function VideoSubmit() {
  const { loading: authLoading, authorized, user } = useAuthCheck('影器', true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    concept: '',
    target_audience: '',
    script: '',
    equipment: [],
    deadline: '',
    department: '影器',
    status: 'pending'
  });

  // 處理器材輸入
  const [equipmentInput, setEquipmentInput] = useState('');
  const handleAddEquipment = () => {
    if (equipmentInput.trim()) {
      setFormData({
        ...formData,
        equipment: [...formData.equipment, equipmentInput.trim()]
      });
      setEquipmentInput('');
    }
  };

  const handleRemoveEquipment = (index) => {
    setFormData({
      ...formData,
      equipment: formData.equipment.filter((_, i) => i !== index)
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const { error } = await supabase
        .from('video_proposals')
        .insert({
          ...formData,
          director: user.name,
          submitted_by: user.name,
          submitted_at: new Date().toISOString(),
          status: 'pending',
          progress_status: 'preparing',
          department: '影片',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        });

      if (error) throw error;

      setSuccess('企劃提交成功！');
      setFormData({
        title: '',
        description: '',
        concept: '',
        target_audience: '',
        script: '',
        equipment: [],
        deadline: '',
        department: '影片',
        status: 'pending'
      });
    } catch (error) {
      console.error('Error:', error);
      setError('企劃提交失敗');
    } finally {
      setLoading(false);
    }
  };

  if (authLoading) {
    return <CircularProgress />;
  }

  if (!authorized) return null;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          影片企劃提交
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="影片標題"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            required
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            label="影片說明"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            multiline
            rows={3}
            required
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            label="創意概念"
            value={formData.concept}
            onChange={(e) => setFormData({ ...formData, concept: e.target.value })}
            multiline
            rows={3}
            required
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            label="目標觀眾"
            value={formData.target_audience}
            onChange={(e) => setFormData({ ...formData, target_audience: e.target.value })}
            required
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            label="腳本/分鏡"
            value={formData.script}
            onChange={(e) => setFormData({ ...formData, script: e.target.value })}
            multiline
            rows={4}
            required
            sx={{ mb: 2 }}
          />

          {/* 器材輸入區 */}
          <Box sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
              <TextField
                label="需要器材"
                value={equipmentInput}
                onChange={(e) => setEquipmentInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddEquipment();
                  }
                }}
              />
              <Button 
                onClick={handleAddEquipment}
                variant="outlined"
              >
                新增器材
              </Button>
            </Box>
            
            <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
              {formData.equipment.map((item, index) => (
                <Chip
                  key={index}
                  label={item}
                  onDelete={() => handleRemoveEquipment(index)}
                />
              ))}
            </Stack>
          </Box>

          <TextField
            fullWidth
            label="預計完成日期"
            type="datetime-local"
            value={formData.deadline}
            onChange={(e) => setFormData({ ...formData, deadline: e.target.value })}
            required
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 3 }}
          />

          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{ minWidth: 120 }}
          >
            {loading ? <CircularProgress size={24} /> : '提交企劃'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
} 