import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
} from '@mui/material';
import { supabase } from '../../utils/supabase';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useAuthCheck } from '../../hooks/useAuthCheck';

export default function MeetingMinutes() {
  const { loading, authorized, user } = useAuthCheck('文務', true);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    date: '',
    attendees: '',
    content: '',
    decisions: '',
    next_meeting: ''
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6">檢查權限中...</Typography>
            <Typography color="textSecondary">
              {user ? `當前用戶: ${user.name} (${user.department})` : '載入用戶資料...'}
            </Typography>
          </Box>
        </Paper>
      </Container>
    );
  }

  if (!authorized) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('=== Submit Debug ===');
      
      // 檢查用戶數據
      console.log('Current user:', user);

      // 檢查必填欄位
      if (!formData.date || !formData.attendees || !formData.content || !formData.decisions) {
        setError('請填寫所有必填欄位');
        return;
      }

      // 檢查用戶
      if (!user) {
        throw new Error('未登入或用戶資料未載入');
      }

      const submitData = {
        ...formData,
        submitted_by: user.name,
        department: user.department,
        status: 'pending'
      };

      console.log('Submitting data:', submitData);

      const { data, error: submitError } = await supabase
        .from('meeting_minutes')
        .insert([submitData])
        .select();

      if (submitError) {
        console.error('Submit error:', submitError);
        throw submitError;
      }

      console.log('Submission successful:', data);
      
      setSuccess('會議記錄已提交，等待審核');
      setFormData({
        date: '',
        attendees: '',
        content: '',
        decisions: '',
        next_meeting: ''
      });
    } catch (error) {
      console.error('Error details:', error);
      setError(error.message || '提交失敗');
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          會議記錄提交
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            required
            type="date"
            label="會議日期"
            value={formData.date}
            onChange={(e) => setFormData({...formData, date: e.target.value})}
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            fullWidth
            required
            label="出席人員"
            value={formData.attendees}
            onChange={(e) => setFormData({...formData, attendees: e.target.value})}
            sx={{ mb: 2 }}
            helperText="請輸入出席人員姓名，以逗號分隔"
          />

          <TextField
            fullWidth
            required
            multiline
            rows={4}
            label="會議內容"
            value={formData.content}
            onChange={(e) => setFormData({...formData, content: e.target.value})}
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            required
            multiline
            rows={3}
            label="決議事項"
            value={formData.decisions}
            onChange={(e) => setFormData({...formData, decisions: e.target.value})}
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            type="date"
            label="下次會議日期"
            value={formData.next_meeting}
            onChange={(e) => setFormData({...formData, next_meeting: e.target.value})}
            sx={{ mb: 3 }}
            InputLabelProps={{ shrink: true }}
          />

          <Button 
            type="submit"
            variant="contained"
            fullWidth
            size="large"
          >
            提交會議記錄
          </Button>
        </Box>
      </Paper>
    </Container>
  );
} 