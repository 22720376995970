import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  Divider,
  Chip,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// 添加圖標引入
import SearchIcon from '@mui/icons-material/Search';
import HistoryIcon from '@mui/icons-material/History';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';

import { useEffect, useState } from 'react';
import { supabase } from '../utils/supabase';
import axios from 'axios';

export default function Announcements() {
  const theme = useTheme();
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [readStatus, setReadStatus] = useState({});
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [history, setHistory] = useState([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newAnnouncement, setNewAnnouncement] = useState({
    title: '',
    content: '',
    type: 'normal',
    visibility: 'internal'
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editingAnnouncement, setEditingAnnouncement] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);

  useEffect(() => {
    fetchData();
    checkUserRole();
  }, []);

  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log('Current user:', user);
    console.log('User role:', user?.role);
    const hasAdminRole = user?.role === 'president' || 
                        user?.role === 'vice_president' || 
                        user?.role === '會長' || 
                        user?.role === '副會長';
    console.log('Is admin:', hasAdminRole);
    setIsAdmin(hasAdminRole);
  };

  const handleCreateAnnouncement = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      
      if (!user?.id) {
        console.error('User ID not found');
        return;
      }

      // 儲存公告
      const { data, error } = await supabase
        .from('announcements')
        .insert({
          title: newAnnouncement.title,
          content: newAnnouncement.content,
          type: newAnnouncement.type,
          visibility: newAnnouncement.visibility,
          created_by: user.id
        })
        .select()
        .single();

      if (error) throw error;

      // 只有內部公告才發送 LINE 訊息
      if (newAnnouncement.visibility === 'internal') {
        try {
          const lineResponse = await axios.post('/api/line-bot', {
            title: newAnnouncement.title,
            content: newAnnouncement.content,
            type: newAnnouncement.type,
            author: user.name
          });

          console.log('LINE Response:', lineResponse.data);
        } catch (lineError) {
          console.error('LINE Bot Error:', lineError);
        }
      }

      // 重置表單
      setNewAnnouncement({
        title: '',
        content: '',
        type: 'normal',
        visibility: 'internal'
      });
      setOpenCreateDialog(false);
      
      fetchData();
    } catch (error) {
      console.error('Error creating announcement:', error);
      alert('發布公告時發生錯誤，請稍後再試');
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const user = JSON.parse(localStorage.getItem('user'));

      if (!user?.id) {
        console.error('User ID not found');
        return;
      }

      const { data: announcementsData, error: announcementsError } = await supabase
        .from('announcements')
        .select(`
          *,
          users:created_by (name),
          announcement_reads!left (
            read_at,
            user_id
          )
        `)
        .order('created_at', { ascending: false });

      if (announcementsError) throw announcementsError;

      const status = {};
      announcementsData?.forEach(announcement => {
        const isRead = announcement.announcement_reads?.some(
          read => read.user_id === user.id && read.read_at
        );
        status[announcement.id] = isRead;
      });

      console.log('Read status:', status);  // 調試用
      
      setAnnouncements(announcementsData || []);
      setReadStatus(status);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const markAsRead = async (announcementId) => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      
      if (!user?.id) {
        console.error('User ID not found');
        return;
      }

      const { data: existingRead } = await supabase
        .from('announcement_reads')
        .select('*')
        .eq('announcement_id', announcementId)
        .eq('user_id', user.id)
        .single();

      if (!existingRead) {
        const { error: insertError } = await supabase
          .from('announcement_reads')
          .insert({
            announcement_id: announcementId,
            user_id: user.id,
            read_at: new Date().toISOString()
          });

        if (insertError) throw insertError;
      }

      setReadStatus(prev => ({
        ...prev,
        [announcementId]: true
      }));
    } catch (error) {
      console.error('Error marking as read:', error);
    }
  };

  const handleAnnouncementClick = async (announcementId) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user?.id) {
      console.error('User ID not found');
      return;
    }

    if (!readStatus[announcementId]) {
      await markAsRead(announcementId);
    }
  };

  const filteredAnnouncements = announcements.filter(announcement =>
    announcement.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    announcement.content?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchHistory = async (announcementId) => {
    try {
      const { data, error } = await supabase
        .from('announcement_history')
        .select(`
          *,
          users:modified_by (name)
        `)
        .eq('announcement_id', announcementId)
        .order('modified_at', { ascending: false });

      if (error) throw error;
      setHistory(data || []);
    } catch (error) {
      console.error('Error fetching history:', error);
    }
  };

  const handleHistoryClick = async (announcement) => {
    setSelectedAnnouncement(announcement);
    await fetchHistory(announcement.id);
    setHistoryOpen(true);
  };

  // 檢查是否是公告作者
  const isAuthor = (announcement) => {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log('Checking authorship:', {
      userId: user?.id,
      createdBy: announcement.created_by,
      isMatch: announcement.created_by === user?.id
    });
    return announcement.created_by === user?.id;
  };

  // 處理編輯
  const handleEdit = (announcement, event) => {
    event.stopPropagation(); // 防止觸發公��的點擊事件
    setEditingAnnouncement({
      id: announcement.id,
      title: announcement.title,
      content: announcement.content,
      type: announcement.type
    });
    setOpenEditDialog(true);
  };

  // 處理更新
  const handleUpdate = async () => {
    try {
      const { error } = await supabase
        .from('announcements')
        .update({
          title: editingAnnouncement.title,
          content: editingAnnouncement.content,
          type: editingAnnouncement.type
        })
        .eq('id', editingAnnouncement.id);

      if (error) throw error;

      setOpenEditDialog(false);
      setEditingAnnouncement(null);
      fetchData(); // 重新獲取公告列表
    } catch (error) {
      console.error('Error updating announcement:', error);
    }
  };

  // 處理刪除
  const handleDelete = async (announcementId, event) => {
    event.stopPropagation();
    
    if (window.confirm('確定要刪除這則公告嗎？')) {
      try {
        const { error } = await supabase
          .rpc('delete_announcement', {
            target_id: announcementId
          });

        if (error) {
          console.error('Delete error:', error);
          throw error;
        }

        console.log('Successfully deleted announcement');
        fetchData();
      } catch (error) {
        console.error('Error deleting announcement:', error);
        alert('刪除公告時發生錯誤，請稍後再試');
      }
    }
  };

  const validateUserData = () => {
    try {
      const userStr = localStorage.getItem('user');
      console.log('Raw user string:', userStr);  // 調試用

      if (!userStr) {
        console.error('No user data in localStorage');
        return null;
      }

      const user = JSON.parse(userStr);
      console.log('Parsed user data:', user);  // 調試用

      if (!user.id) {
        console.error('User data missing ID');
        // 如果有其他識別欄位，可以嘗試重新獲取完整用戶資料
        return refreshUserData(user);
      }

      return user;
    } catch (error) {
      console.error('Error validating user data:', error);
      return null;
    }
  };

  const refreshUserData = async (partialUser) => {
    try {
      const { data: userData, error } = await supabase
        .from('users')
        .select('*')
        .eq('account', partialUser.account)  // 使用可用的識別欄位
        .single();

      if (error) throw error;

      const completeUser = {
        id: userData.id,
        account: userData.account,
        name: userData.name,
        role: userData.role,
        class: userData.class,
        seat_number: userData.seat_number
      };

      localStorage.setItem('user', JSON.stringify(completeUser));
      return completeUser;
    } catch (error) {
      console.error('Error refreshing user data:', error);
      return null;
    }
  };

  return (
    <Box sx={{ p: { xs: 2, sm: 3 } }}>
      {/* 頁面標題區域 - 改進設計 */}
      <Paper
        elevation={0}
        sx={{
          p: 3,
          mb: 4,
          borderRadius: 3,
          background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
          color: 'white',
          position: 'relative',
          overflow: 'visible'
        }}
      >
        {/* 裝飾背景 */}
        <Box
          sx={{
            position: 'absolute',
            top: '-50%',
            right: '-10%',
            width: '60%',
            height: '200%',
            background: 'linear-gradient(45deg, transparent, rgba(255,255,255,0.1))',
            transform: 'rotate(-20deg)',
            borderRadius: '50%',
            pointerEvents: 'none'
          }}
        />
        
        <Box sx={{ position: 'relative', zIndex: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <HistoryIcon sx={{ fontSize: 40 }} />
            <Typography variant="h4" fontWeight="bold">
              公告列表
            </Typography>
          </Box>
          
          {/* 添加統計信息 */}
          <Box sx={{ display: 'flex', gap: 4, mt: 3 }}>
            <Box>
              <Typography variant="body2" sx={{ opacity: 0.7 }}>總公告數</Typography>
              <Typography variant="h4" fontWeight="bold">
                {filteredAnnouncements.length}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ opacity: 0.7 }}>重要公告</Typography>
              <Typography variant="h4" fontWeight="bold">
                {filteredAnnouncements.filter(a => a.type === 'important').length}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ opacity: 0.7 }}>本月新增</Typography>
              <Typography variant="h4" fontWeight="bold">
                {filteredAnnouncements.filter(a => 
                  new Date(a.created_at).getMonth() === new Date().getMonth()
                ).length}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* 發布按鈕 */}
        {isAdmin && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenCreateDialog(true)}
            sx={{
              position: 'absolute',
              right: 24,
              top: '50%',
              transform: 'translateY(-50%)',
              borderRadius: 2,
              px: 3,
              py: 1,
              bgcolor: 'rgba(255,255,255,0.9)',
              color: 'primary.main',
              zIndex: 2,
              '&:hover': {
                bgcolor: 'white',
                transform: 'translateY(-50%) scale(1.05)',
              },
              transition: 'all 0.3s ease',
              boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
            }}
          >
            發布公告
          </Button>
        )}
      </Paper>

      {/* 搜索和篩選區域 */}
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 3,
          borderRadius: 3,
          border: `1px solid ${theme.palette.divider}`
        }}
      >
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
            fullWidth
            placeholder="搜尋公告..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              )
            }}
            sx={{ flex: 1 }}
          />
          
          {/* 添加篩選器 */}
          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
            <Chip
              label="全部"
              onClick={() => setSearchTerm('')}
              color={searchTerm === '' ? 'primary' : 'default'}
              variant={searchTerm === '' ? 'filled' : 'outlined'}
            />
            <Chip
              label="重要"
              onClick={() => setSearchTerm('重要')}
              color={searchTerm === '重要' ? 'error' : 'default'}
              variant={searchTerm === '重要' ? 'filled' : 'outlined'}
            />
            <Chip
              label="內部"
              onClick={() => setSearchTerm('內部')}
              color={searchTerm === '內部' ? 'info' : 'default'}
              variant={searchTerm === '內部' ? 'filled' : 'outlined'}
            />
          </Stack>
        </Stack>
      </Paper>

      {/* 改進公告列表樣式 */}
      <Paper 
        elevation={0}
        sx={{ 
          borderRadius: 3,
          border: `1px solid ${theme.palette.divider}`,
          overflow: 'hidden'
        }}
      >
        <List>
          {filteredAnnouncements.map((announcement, index) => (
            <ListItem 
              key={announcement.id}
              sx={{ 
                p: 3,
                borderLeft: `4px solid ${
                  announcement.type === 'important' 
                    ? theme.palette.error.main
                    : theme.palette.primary.main
                }`,
                '&:hover': {
                  bgcolor: 'rgba(0,0,0,0.02)',
                  transform: 'translateX(8px)'
                },
                transition: 'all 0.3s ease'
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  mb: 1 
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: readStatus[announcement.id] ? 400 : 600,
                        color: readStatus[announcement.id] ? 'text.primary' : 'primary.main'
                      }}
                    >
                      {announcement.title}
                    </Typography>
                    <Chip 
                      label={readStatus[announcement.id] ? "已讀" : "未讀"} 
                      size="small"
                      sx={{
                        borderRadius: 1,
                        bgcolor: readStatus[announcement.id] ? 'grey.100' : 'primary.lighter',
                        color: readStatus[announcement.id] ? 'text.secondary' : 'primary.main',
                        fontWeight: 500
                      }}
                    />
                    <Chip 
                      label={announcement.visibility === 'internal' ? '內部' : '外部'} 
                      size="small"
                      sx={{
                        borderRadius: 1,
                        bgcolor: announcement.visibility === 'internal' ? 'info.lighter' : 'success.lighter',
                        color: announcement.visibility === 'internal' ? 'info.main' : 'success.main',
                        fontWeight: 500
                      }}
                    />
                    <Chip 
                      label={announcement.type === 'important' ? '重要' : '一般'} 
                      size="small"
                      sx={{
                        borderRadius: 1,
                        bgcolor: announcement.type === 'important' ? 'error.lighter' : 'grey.100',
                        color: announcement.type === 'important' ? 'error.main' : 'text.secondary',
                        fontWeight: 500
                      }}
                    />
                  </Box>
                  {isAuthor(announcement) && (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton 
                        size="small"
                        onClick={(e) => handleEdit(announcement, e)}
                        sx={{
                          color: 'primary.main',
                          '&:hover': {
                            bgcolor: 'primary.lighter'
                          }
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton 
                        size="small"
                        onClick={(e) => handleDelete(announcement.id, e)}
                        sx={{
                          color: 'error.main',
                          '&:hover': {
                            bgcolor: 'error.lighter'
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    mb: 1
                  }}
                >
                  {announcement.content}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <Typography variant="caption" color="text.secondary">
                    {announcement.users?.name} · {new Date(announcement.created_at).toLocaleString()}
                  </Typography>
                  <Button
                    size="small"
                    startIcon={<HistoryIcon />}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleHistoryClick(announcement);
                    }}
                    sx={{
                      color: 'text.secondary',
                      '&:hover': {
                        bgcolor: 'grey.100'
                      }
                    }}
                  >
                    歷史紀錄
                  </Button>
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* 美化對話框 */}
      <Dialog
        open={openCreateDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0,0,0,0.1)'
          }
        }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">發布新公告</Typography>
            <IconButton onClick={() => setOpenCreateDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="標題"
              value={newAnnouncement.title}
              onChange={(e) => setNewAnnouncement(prev => ({
                ...prev,
                title: e.target.value
              }))}
              sx={{ mb: 2 }}
            />
            
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>重要程度</InputLabel>
              <Select
                value={newAnnouncement.type}
                label="重要程度"
                onChange={(e) => setNewAnnouncement(prev => ({
                  ...prev,
                  type: e.target.value
                }))}
              >
                <MenuItem value="normal">一般</MenuItem>
                <MenuItem value="important">重要</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>公告類型</InputLabel>
              <Select
                value={newAnnouncement.visibility}
                label="公告類型"
                onChange={(e) => setNewAnnouncement(prev => ({
                  ...prev,
                  visibility: e.target.value
                }))}
              >
                <MenuItem value="internal">內部公告</MenuItem>
                <MenuItem value="external">外部公告</MenuItem>
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="內容"
              multiline
              rows={4}
              value={newAnnouncement.content}
              onChange={(e) => setNewAnnouncement(prev => ({
                ...prev,
                content: e.target.value
              }))}
              sx={{ mb: 3 }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button 
                variant="outlined" 
                onClick={() => setOpenCreateDialog(false)}
              >
                取消
              </Button>
              <Button 
                variant="contained"
                onClick={handleCreateAnnouncement}
                disabled={!newAnnouncement.title || !newAnnouncement.content}
                sx={{ 
                  '&[aria-hidden="true"]': {
                    display: 'none'
                  }
                }}
              >
                發布
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)}>
        <DialogTitle>LINE 訊息預覽</DialogTitle>
        <DialogContent>
          <Box sx={{ 
            p: 2, 
            bgcolor: '#ffffff',
            borderRadius: 2,
            border: '1px solid #e0e0e0'
          }}>
            <Typography variant="h6" color={newAnnouncement.type === 'important' ? 'error' : 'primary'}>
              {newAnnouncement.type === 'important' ? '⚠️ 重要公告' : '📢 一般公告'}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              {newAnnouncement.title}
            </Typography>
            <Typography variant="body1">
              {newAnnouncement.content}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
} 