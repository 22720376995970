import { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  TextField,
  InputAdornment,
  useTheme,
  IconButton,
  Tooltip,
  Fade,
  Collapse,
  Grid,
  Avatar,
  Card,
  CardContent,
  CardActionArea,
  Divider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import GroupIcon from '@mui/icons-material/Group';
import ClearIcon from '@mui/icons-material/Clear';
import { supabase } from '../utils/supabase';
import { useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PeopleIcon from '@mui/icons-material/People';
import StarIcon from '@mui/icons-material/Star';

export default function Members() {
  const theme = useTheme();
  const [members, setMembers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [expandedId, setExpandedId] = useState(null);

  // 獲取成員數據
  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*');

      if (error) throw error;

      // 自定義排序：會長 > 副會長 > 組長 > 一般成員
      const sortedData = data.sort((a, b) => {
        const roleOrder = {
          'president': 1,
          'vice_president': 2,
          'leader': 3,
          'member': 4
        };
        
        if (roleOrder[a.role] !== roleOrder[b.role]) {
          return roleOrder[a.role] - roleOrder[b.role];
        }
        
        // 如果角色相同，則按班級排序
        return a.class.localeCompare(b.class);
      });

      setMembers(sortedData || []);
    } catch (error) {
      console.error('Error fetching members:', error);
    } finally {
      setLoading(false);
    }
  };

  // 添加角色映射函數
  const getRoleDisplay = (role) => {
    const roleMap = {
      'president': '會長',
      'vice_president': '副會長',
      'leader': '組長',
      'member': '成員'
    };
    return roleMap[role] || role;
  };

  // 修改搜尋邏輯
  const filteredMembers = members.filter(member => {
    const searchTermLower = searchTerm.toLowerCase().trim();
    
    // 如果搜尋詞為空，返回所有成員
    if (!searchTermLower) return true;
    
    // 檢查各個欄位
    const nameMatch = member.name?.toLowerCase().includes(searchTermLower);
    const classMatch = member.class?.toLowerCase().includes(searchTermLower);
    
    // 修改角色匹配邏輯，使用完全匹配而不是部分匹配
    const roleMatch = (() => {
      const searchRole = searchTermLower;
      switch (searchRole) {
        case '會長':
          return member.role === 'president';
        case '副會長':
          return member.role === 'vice_president';
        case '組長':
          return member.role === 'leader';
        case '成員':
          return member.role === 'member';
        default:
          return false;
      }
    })();

    const bioMatch = member.bio?.toLowerCase().includes(searchTermLower);
    const skillsMatch = member.skills?.some(skill => 
      skill.toLowerCase().includes(searchTermLower)
    );

    // 任一欄位符合即返回 true
    return nameMatch || classMatch || roleMatch || bioMatch || skillsMatch;
  });

  // 處理分頁
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // 更新角色樣式
  const getRoleStyle = (role) => {
    const styles = {
      president: {
        label: '會長',
        style: {
          background: `linear-gradient(45deg, ${theme.palette.error.main}, ${theme.palette.error.dark})`,
          color: 'white',
          fontWeight: 'bold',
          boxShadow: `0 4px 12px ${theme.palette.error.main}40`,
          padding: '4px 12px',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: `0 6px 16px ${theme.palette.error.main}60`,
          },
          transition: 'all 0.3s ease'
        }
      },
      vice_president: {
        label: '副會長',
        style: {
          background: `linear-gradient(45deg, ${theme.palette.warning.main}, ${theme.palette.warning.dark})`,
          color: 'white',
          fontWeight: 'bold',
          boxShadow: `0 4px 12px ${theme.palette.warning.main}40`,
          padding: '4px 12px',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: `0 6px 16px ${theme.palette.warning.main}60`,
          },
          transition: 'all 0.3s ease'
        }
      },
      leader: {
        label: '組長',
        style: {
          background: `linear-gradient(45deg, ${theme.palette.info.main}, ${theme.palette.info.dark})`,
          color: 'white',
          fontWeight: 500,
          boxShadow: `0 4px 12px ${theme.palette.info.main}40`,
          padding: '4px 12px',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: `0 6px 16px ${theme.palette.info.main}60`,
          },
          transition: 'all 0.3s ease'
        }
      },
      member: {
        label: '成員',
        style: {
          bgcolor: 'grey.100',
          color: 'text.secondary',
          padding: '4px 12px',
          '&:hover': {
            bgcolor: 'grey.200'
          }
        }
      }
    };

    return styles[role] || styles.member;
  };

  // 根據角色返回行樣式
  const getRowStyle = (role) => {
    switch (role) {
      case 'president':
        return {
          backgroundColor: 'rgba(211, 47, 47, 0.04)',
          fontWeight: 'bold'
        };
      case 'vice_president':
        return {
          backgroundColor: 'rgba(244, 67, 54, 0.04)',
          fontWeight: 'bold'
        };
      case 'leader':
        return {
          backgroundColor: 'rgba(255, 152, 0, 0.04)',
          fontWeight: 500
        };
      default:
        return {};
    }
  };

  const getAvatarEmoji = (name) => {
    const animalEmojis = [
      '🐰', '🐱', '🐨', '🐼', '🦊', 
      '🐯', '🦁', '🐮', '🐷', '🐸',
      '🦉', '🐧', '🦄', '🐶', '🐹'
    ];
    
    const charCode = name?.charCodeAt(0) || 0;
    const index = charCode % animalEmojis.length;
    return animalEmojis[index];
  };

  const getPastelColor = (name) => {
    const pastelColors = [
      '#FFB3BA', // 粉紅
      '#BAFFC9', // 薄荷綠
      '#BAE1FF', // 天藍
      '#FFFFBA', // 檸檬黃
      '#FFB3F7', // 粉紫
      '#B3ECFF', // 淺藍
      '#FFC8BA', // 蜜桃
      '#E5BAFF', // 淺紫
      '#BAF7C3', // 淺綠
    ];
    
    const charCode = name?.charCodeAt(0) || 0;
    const index = charCode % pastelColors.length;
    return pastelColors[index];
  };

  return (
    <Box sx={{ p: { xs: 2, sm: 3 } }}>
      {/* 頁面標題區域 - 添加統計信息 */}
      <Paper
        elevation={0}
        sx={{
          p: 3,
          mb: 4,
          borderRadius: 3,
          background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
          color: 'white',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        {/* 裝飾背景 */}
        <Box
          sx={{
            position: 'absolute',
            top: '-50%',
            right: '-10%',
            width: '60%',
            height: '200%',
            background: 'linear-gradient(45deg, transparent, rgba(255,255,255,0.1))',
            transform: 'rotate(-20deg)',
            borderRadius: '50%'
          }}
        />
        
        <Grid container spacing={3} sx={{ position: 'relative' }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <GroupIcon sx={{ fontSize: 40 }} />
              <Typography variant="h4" fontWeight="bold">
                成員名單
              </Typography>
            </Box>
            <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
              管理和查看所有畢聯會成員資訊
            </Typography>
          </Grid>
          
          {/* 添加簡單統計 */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {[
                { label: '總成員數', value: members.length, icon: <PeopleIcon /> },
                { 
                  label: '幹部人數', 
                  value: members.filter(m => m.role !== 'member').length,
                  icon: <StarIcon />
                }
              ].map((stat, index) => (
                <Grid item xs={6} key={index}>
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      bgcolor: 'rgba(255,255,255,0.1)',
                      backdropFilter: 'blur(8px)'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      {stat.icon}
                      <Typography variant="body2">{stat.label}</Typography>
                    </Box>
                    <Typography variant="h4" fontWeight="bold">
                      {stat.value}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* 搜索和篩選區域 */}
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 3,
          borderRadius: 3,
          border: `1px solid ${theme.palette.divider}`,
          bgcolor: 'background.paper'
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="搜尋成員姓名、班級..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <Tooltip title="清除搜尋">
                      <IconButton size="small" onClick={() => setSearchTerm('')}>
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: 2,
                  bgcolor: 'grey.50'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {['會長', '副會長', '組長', '成員'].map((role) => (
                <Chip
                  key={role}
                  label={role}
                  onClick={() => setSearchTerm(role)}
                  variant={searchTerm === role ? 'filled' : 'outlined'}
                  color={searchTerm === role ? 'primary' : 'default'}
                  sx={{ 
                    borderRadius: 2,
                    transition: 'all 0.3s ease'
                  }}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* 成員卡片網格 */}
      <Grid container spacing={3}>
        {loading ? (
          <Grid item xs={12}>
            <Typography align="center">載入中...</Typography>
          </Grid>
        ) : filteredMembers.length === 0 ? (
          <Grid item xs={12}>
            <Typography align="center">沒有找到符合的成員</Typography>
          </Grid>
        ) : (
          filteredMembers
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((member) => {
              const roleStyle = getRoleStyle(member.role);
              
              return (
                <Grid item xs={12} sm={6} md={4} key={member.id}>
                  <Card 
                    elevation={0}
                    sx={{ 
                      borderRadius: 3,
                      border: `1px solid ${theme.palette.divider}`,
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: '0 8px 24px rgba(0,0,0,0.1)'
                      }
                    }}
                  >
                    <CardActionArea 
                      onClick={() => setExpandedId(expandedId === member.id ? null : member.id)}
                      sx={{ p: 2 }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Avatar
                          sx={{ 
                            width: 56,
                            height: 56,
                            bgcolor: getPastelColor(member.name),
                            fontSize: '1.5rem'
                          }}
                        >
                          {getAvatarEmoji(member.name)}
                        </Avatar>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="h6" sx={{ mb: 0.5 }}>
                            {member.name}
                          </Typography>
                          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Chip 
                              label={member.class}
                              size="small"
                              variant="outlined"
                            />
                            <Chip 
                              label={roleStyle.label}
                              sx={roleStyle.style}
                              size="small"
                            />
                          </Box>
                        </Box>
                        {expandedId === member.id ? 
                          <ExpandLessIcon /> : <ExpandMoreIcon />
                        }
                      </Box>
                    </CardActionArea>
                    
                    <Collapse in={expandedId === member.id}>
                      <CardContent sx={{ pt: 0 }}>
                        <Divider sx={{ my: 2 }} />
                        <Grid container spacing={2}>
                          {/* 自我介紹區塊 */}
                          <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary">
                              自我介紹
                            </Typography>
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                mt: 0.5,
                                color: member.bio ? 'text.primary' : 'text.disabled',
                                fontStyle: member.bio ? 'normal' : 'italic'
                              }}
                            >
                              {member.bio || '尚未填寫自我介紹'}
                            </Typography>
                          </Grid>

                          {/* 專長與技能區塊 */}
                          <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                              專長與技能
                            </Typography>
                            {member.skills?.length > 0 ? (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {member.skills.map((skill, index) => (
                                  <Chip
                                    key={index}
                                    label={skill}
                                    size="small"
                                    variant="outlined"
                                  />
                                ))}
                              </Box>
                            ) : (
                              <Typography 
                                variant="body2" 
                                sx={{ 
                                  color: 'text.disabled',
                                  fontStyle: 'italic'
                                }}
                              >
                                尚未填寫專長與技能
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              );
            })
        )}
      </Grid>

      {/* 分頁控制 - 美化樣式 */}
      <Paper
        elevation={0}
        sx={{
          mt: 3,
          p: 2,
          borderRadius: 3,
          border: `1px solid ${theme.palette.divider}`,
          bgcolor: 'background.paper'
        }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredMembers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="每頁顯示筆數："
          labelDisplayedRows={({ from, to, count }) => 
            `${from}-${to} 筆，共 ${count} 筆`
          }
          sx={{
            '.MuiTablePagination-select': {
              borderRadius: 2,
              mr: 1,
              bgcolor: 'grey.50'
            },
            '.MuiTablePagination-selectIcon': {
              color: 'primary.main'
            }
          }}
        />
      </Paper>
    </Box>
  );
} 