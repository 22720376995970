import { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Divider,
  Alert
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@mui/icons-material';
import { supabase } from '../../utils/supabase';

export default function FeedbackManagement() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedId, setExpandedId] = useState(null);

  useEffect(() => {
    fetchFeedbacks();
  }, []);

  const fetchFeedbacks = async () => {
    try {
      const { data, error } = await supabase
        .from('feedback')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setFeedbacks(data);
    } catch (error) {
      setError('載入意見資料時發生錯誤');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const getTypeText = (type) => {
    const types = {
      'suggestion': '建議',
      'complaint': '申訴',
      'question': '問題詢問',
      'other': '其他'
    };
    return types[type] || type;
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography 
        variant="h4" 
        sx={{ 
          mb: 4,
          fontWeight: 700,
          textAlign: 'center',
          position: 'relative',
          '&::after': {
            content: '""',
            display: 'block',
            width: '60px',
            height: '4px',
            background: 'linear-gradient(90deg, #1a237e 0%, #0d47a1 100%)',
            margin: '16px auto 0',
            borderRadius: '2px'
          }
        }}
      >
        意見箱
      </Typography>

      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}

      <Paper 
        elevation={3}
        sx={{ 
          borderRadius: 2,
          overflow: 'hidden'
        }}
      >
        <List>
          {feedbacks.map((feedback, index) => (
            <Box key={feedback.id}>
              {index > 0 && <Divider />}
              <ListItem 
                button 
                onClick={() => handleClick(feedback.id)}
                sx={{
                  transition: 'all 0.2s',
                  '&:hover': {
                    bgcolor: 'action.hover'
                  }
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        {feedback.title}
                      </Typography>
                      <Typography 
                        variant="caption" 
                        color="text.secondary"
                        sx={{ 
                          bgcolor: 'action.hover',
                          px: 1,
                          py: 0.5,
                          borderRadius: 1
                        }}
                      >
                        {getTypeText(feedback.type)}
                      </Typography>
                    </Box>
                  }
                  secondary={new Date(feedback.created_at).toLocaleString()}
                />
                {expandedId === feedback.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={expandedId === feedback.id} timeout="auto" unmountOnExit>
                <Box sx={{ p: 3, bgcolor: 'grey.50' }}>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      whiteSpace: 'pre-wrap',
                      mb: 2 
                    }}
                  >
                    {feedback.content}
                  </Typography>
                  {feedback.contact && (
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{
                        mt: 2,
                        p: 1,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        display: 'inline-block'
                      }}
                    >
                      聯絡方式：{feedback.contact}
                    </Typography>
                  )}
                </Box>
              </Collapse>
            </Box>
          ))}
        </List>
      </Paper>
    </Container>
  );
} 