import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Chip
} from '@mui/material';
import { supabase } from '../../utils/supabase';
import { useAuthCheck } from '../../hooks/useAuthCheck';

export default function VideoProgress() {
  const { loading: authLoading, authorized, user } = useAuthCheck('影器', true);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [progressNote, setProgressNote] = useState('');
  const [videoUrl, setVideoUrl] = useState('');

  // 進度步驟定義
  const progressSteps = [
    { label: '準備拍攝', value: 'preparing' },
    { label: '拍攝中', value: 'filming' },
    { label: '拍攝完成', value: 'filmed' },
    { label: '剪輯中', value: 'editing' },
    { label: '剪輯完畢', value: 'edited' },
    { label: '已上傳', value: 'uploaded' }
  ];

  useEffect(() => {
    if (authorized) {
      fetchProjects();
    }
  }, [authorized]);

  const fetchProjects = async () => {
    try {
      const { data, error } = await supabase
        .from('video_proposals')
        .select('*')
        .eq('status', 'approved')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setProjects(data);
    } catch (error) {
      console.error('Error:', error);
      setError('獲取專案失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProgress = async (newStatus) => {
    try {
      if (!progressNote) {
        throw new Error('請填寫進度備註');
      }

      if (newStatus === 'uploaded' && !videoUrl) {
        throw new Error('請填寫影片連結');
      }

      const { error } = await supabase
        .from('video_proposals')
        .update({
          progress_status: newStatus,
          progress_notes: progressNote,
          progress_updated_at: new Date().toISOString(),
          progress_updated_by: user.name,
          video_url: newStatus === 'uploaded' ? videoUrl : selectedProject.video_url,
          updated_at: new Date().toISOString()
        })
        .eq('id', selectedProject.id);

      if (error) throw error;

      setSuccess('進度更新成功！');
      setOpenDialog(false);
      setProgressNote('');
      setVideoUrl('');
      fetchProjects();
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || '更新進度失敗');
    }
  };

  const getNextStep = (currentStatus) => {
    const currentIndex = progressSteps.findIndex(step => step.value === currentStatus);
    return progressSteps[currentIndex + 1];
  };

  if (authLoading || loading) {
    return <CircularProgress />;
  }

  if (!authorized) return null;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          影片製作進度
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <Grid container spacing={3}>
          {projects.map((project) => (
            <Grid item xs={12} key={project.id}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="h6">
                      {project.title}
                    </Typography>
                    <Chip 
                      label={`截止日期: ${new Date(project.deadline).toLocaleDateString()}`}
                      color={new Date(project.deadline) < new Date() ? "error" : "default"}
                    />
                  </Box>
                  
                  <Stepper 
                    activeStep={progressSteps.findIndex(step => 
                      step.value === project.progress_status
                    )}
                    sx={{ mb: 3 }}
                  >
                    {progressSteps.map((step) => (
                      <Step key={step.value}>
                        <StepLabel>{step.label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        最後更新：
                        {project.progress_updated_at 
                          ? new Date(project.progress_updated_at).toLocaleString()
                          : '尚未開始'}
                      </Typography>
                      {project.progress_updated_by && (
                        <Typography variant="body2" color="text.secondary">
                          更新者：{project.progress_updated_by}
                        </Typography>
                      )}
                      {project.progress_notes && (
                        <Typography variant="body2" color="text.secondary">
                          備註：{project.progress_notes}
                        </Typography>
                      )}
                    </Box>
                    
                    {project.progress_status !== 'uploaded' && (
                      <Button
                        variant="contained"
                        onClick={() => {
                          setSelectedProject(project);
                          setOpenDialog(true);
                          setVideoUrl(project.video_url || '');
                        }}
                      >
                        更新進度
                      </Button>
                    )}
                  </Box>

                  {project.video_url && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        影片連結：
                        <a href={project.video_url} target="_blank" rel="noopener noreferrer">
                          {project.video_url}
                        </a>
                      </Typography>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>更新專案進度</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <Typography gutterBottom>
              目前進度：
              {progressSteps.find(step => 
                step.value === selectedProject?.progress_status
              )?.label || '準備拍攝'}
            </Typography>

            {selectedProject?.progress_status === 'edited' && (
              <TextField
                fullWidth
                label="影片連結"
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
                sx={{ mb: 2 }}
              />
            )}

            <TextField
              fullWidth
              multiline
              rows={4}
              label="進度備註"
              value={progressNote}
              onChange={(e) => setProgressNote(e.target.value)}
              required
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>取消</Button>
          {selectedProject && getNextStep(selectedProject.progress_status) && (
            <Button
              variant="contained"
              onClick={() => handleUpdateProgress(getNextStep(selectedProject.progress_status).value)}
            >
              進入{getNextStep(selectedProject.progress_status).label}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
} 