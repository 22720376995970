import { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Alert,
  CircularProgress
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../utils/supabase';

// 保留班級反向映射
const REVERSE_CLASS_MAPPING = {
  'class301': '301',
  'class302': '302',
  'class303': '303',
  'class304': '304',
  'class601': '高三忠',
  'class602': '高三孝',
  'class603': '高三仁',
  'class604': '高三愛'
};

const PhotoReview = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    fetchSubmissions();
  }, []);

  const fetchSubmissions = async () => {
    try {
      setLoading(true);
      setError(null);
      
      console.log('開始獲取提交記錄...');
      
      const { data, error } = await supabase
        .from('photo_submissions')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (error) {
        console.error('獲取記錄時發生錯誤:', error);
        throw error;
      }

      console.log('獲取到的原始資料:', data);

      if (!data || data.length === 0) {
        console.log('沒有找到任何記錄');
        setSubmissions([]);
        return;
      }

      // 處理資料
      const processedSubmissions = data.map(submission => ({
        ...submission,
        displayClass: REVERSE_CLASS_MAPPING[submission.class] || submission.class,
        displayTime: new Date(submission.created_at).toLocaleString('zh-TW')
      }));

      console.log('處理後的資料:', processedSubmissions);
      setSubmissions(processedSubmissions);
    } catch (error) {
      console.error('Error fetching submissions:', error);
      setError('載入資料時發生錯誤');
    } finally {
      setLoading(false);
    }
  };

  // 如果用戶未登入，顯示錯誤信息
  if (!user) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">
          請先登入以查看上傳紀錄
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        照片上傳紀錄
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : submissions.length === 0 ? (
        <Alert severity="info">目前沒有上傳紀錄</Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>上傳時間</TableCell>
                <TableCell>學號</TableCell>
                <TableCell>姓名</TableCell>
                <TableCell>班級</TableCell>
                <TableCell>照片類型</TableCell>
                <TableCell>狀態</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {submissions.map((submission) => (
                <TableRow key={submission.id}>
                  <TableCell>
                    {submission.displayTime}
                  </TableCell>
                  <TableCell>{submission.student_id}</TableCell>
                  <TableCell>{submission.student_name}</TableCell>
                  <TableCell>
                    {submission.displayClass}
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={submission.photo_type === 'personal' ? '個人照' : '生活照'}
                      color="primary"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label="已上傳"
                      color="success"
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default PhotoReview;