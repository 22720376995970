import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { 
  Box, 
  AppBar, 
  Toolbar, 
  Typography, 
  Button,
  IconButton,
  Container,
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
  Stack,
  Badge,
  Divider,
  Drawer,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  keyframes
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  Announcement as AnnouncementIcon,
  Event as EventIcon,
  Folder as FolderIcon,
  VideoLibrary as VideoLibraryIcon,
  PhotoLibrary as PhotoLibraryIcon,
  Person as PersonIcon,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Logout as LogoutIcon,
  Notifications as NotificationsIcon
} from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import PWAPrompt from './PWAPrompt';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import DepartmentSidebar from './DepartmentSidebar';

// 定義動畫
const marquee = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(-100%); }
`;

const sparkle = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

// 定義動畫變體
const pageVariants = {
  initial: { 
    opacity: 0, 
    y: 20 
  },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.5 }
  },
  exit: { 
    opacity: 0, 
    y: -20,
    transition: { duration: 0.3 }
  }
};

// 添加列表項目動畫變體
const listItemVariants = {
  hidden: { 
    opacity: 0, 
    x: -20 
  },
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: i * 0.1,
      duration: 0.5,
      ease: "easeOut"
    }
  }),
  hover: {
    scale: 1.02,
    transition: {
      duration: 0.2
    }
  }
};

// 添加 NotificationButton 組件定義
const NotificationButton = ({ notifications, onOpen }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <Tooltip title="通知">
      <IconButton 
        onClick={onOpen}
        sx={{ 
          color: 'primary.main',
          transition: 'all 0.2s',
          '&:hover': {
            bgcolor: 'primary.lighter'
          }
        }}
      >
        <Badge 
          badgeContent={notifications.length} 
          color="error"
          sx={{ 
            '& .MuiBadge-badge': { 
              fontSize: '0.8rem',
              minWidth: '20px',
              height: '20px',
              padding: '0 6px'
            } 
          }}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  </motion.div>
);

// 在文件頂部添加這些函數（和 Profile.jsx 中的一樣）
const getAvatarEmoji = (name) => {
  const animalEmojis = [
    '🐰', '🐱', '🐨', '🐼', '🦊', 
    '🐯', '🦁', '🐮', '🐷', '🐸',
    '🦉', '🐧', '🦄', '🐶', '🐹'
  ];
  
  const charCode = name?.charCodeAt(0) || 0;
  const index = charCode % animalEmojis.length;
  return animalEmojis[index];
};

const getPastelColor = (name) => {
  const pastelColors = [
    '#FFB3BA', // 粉紅
    '#BAFFC9', // 薄荷綠
    '#BAE1FF', // 天藍
    '#FFFFBA', // 檸檬黃
    '#FFB3F7', // 粉紫
    '#B3ECFF', // 淺藍
    '#FFC8BA', // 蜜桃
    '#E5BAFF', // 淺紫
    '#BAF7C3', // 淺綠
  ];
  
  const charCode = name?.charCodeAt(0) || 0;
  const index = charCode % pastelColors.length;
  return pastelColors[index];
};

// 修改點擊處理函數
const handleContentClick = () => {
  if (mobileOpen) {
    setMobileOpen(false);
  }
  if (sidebarOpen) {
    setSidebarOpen(false);
  }
};

// 手機版和桌面版收合按鈕的共用樣式
const collapseButtonStyles = (isOpen, isMobile = false) => ({
  display: isMobile ? { xs: 'flex', md: 'none' } : { xs: 'none', md: 'flex' },
  position: 'fixed',
  left: isOpen ? 280 : 0,
  top: '50%',
  transform: 'translateY(-50%)',
  bgcolor: isOpen ? 'primary.main' : 'rgba(255, 255, 255, 0.9)',
  color: isOpen ? 'white' : 'text.primary',
  border: 1,
  borderColor: isOpen ? 'primary.main' : 'rgba(0, 0, 0, 0.08)',
  borderLeft: isOpen ? 1 : 0,
  borderRadius: isOpen ? '0 16px 16px 0' : '16px',
  zIndex: 1201,
  transition: 'all 0.3s ease',
  width: 56,
  height: 150,
  backdropFilter: isOpen ? 'none' : 'blur(8px)',
  boxShadow: isOpen ? 
    '2px 0 12px rgba(0,0,0,0.2)' : 
    '2px 0 12px rgba(0,0,0,0.1)',
  '&:hover': {
    bgcolor: isOpen ? 
      'primary.dark' : 
      'rgba(255, 255, 255, 0.95)',
    width: 62,
    '& .MuiSvgIcon-root': {
      transform: isOpen ? 
        'rotate(180deg) translateX(-4px)' : 
        'rotate(0deg) translateX(4px)',
    }
  },
  '& .MuiSvgIcon-root': {
    transition: 'all 0.3s ease',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    fontSize: '2.5rem',
    color: isOpen ? 'white' : 'rgba(25, 118, 210, 0.8)'
  }
});

export default function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const [notifications, setNotifications] = useState([]);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const muiTheme = useMuiTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [error, setError] = useState(null);
  const [showPWAPrompt, setShowPWAPrompt] = useState(false);
  const [isPWASupported, setIsPWASupported] = useState(false);
  const isIOSPWA = window.navigator.standalone;
  const [adminAnchorEl, setAdminAnchorEl] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setIsPWASupported(isIOS && isSafari);
  }, []);

  const fetchNotifications = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user) return;
      
      const { data, error } = await supabase
        .from('announcements')
        .select(`
          *,
          users:created_by (name),
          announcement_reads!left (
            read_at,
            user_id
          )
        `)
        .order('created_at', { ascending: false })
        .limit(5);

      if (error) throw error;

      const unreadAnnouncements = data?.filter(announcement => 
        !announcement.announcement_reads?.some(read => read.user_id === user.id)
      ) || [];

      setNotifications(unreadAnnouncements);
      setError(null);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setError(error.message);
      setNotifications([]);
    }
  };

  const markAsRead = async (announcementId) => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      
      const { error } = await supabase
        .from('announcement_reads')
        .insert({
          announcement_id: announcementId,
          user_id: user.id
        });

      if (error) throw error;
      
      // 重新獲取通知
      fetchNotifications();
    } catch (error) {
      console.error('Error marking as read:', error);
    }
  };

  const menuItems = [
    {
      text: '儀表板',
      icon: DashboardIcon,
      path: '/dashboard'
    },
    {
      text: '成員管理',
      icon: PeopleIcon,
      path: '/dashboard/members'
    },
    {
      text: '公告管理',
      icon: AnnouncementIcon,
      path: '/dashboard/announcements'
    },
    {
      text: '活動管理',
      icon: EventIcon,
      path: '/dashboard/activities'
    },
    {
      text: '檔案管理',
      icon: FolderIcon,
      path: '/dashboard/files'
    },
    {
      text: '影片管理',
      icon: VideoLibraryIcon,
      path: '/dashboard/videos'
    },
    {
      text: '照片審核',
      icon: PhotoLibraryIcon,
      path: '/dashboard/photo-review'
    }
  ];

  const handleLogout = () => {
    localStorage.removeItem('user');
    window.location.href = '/login';
  };

  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNotificationsOpen = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleAdminMenuOpen = (event) => {
    setAdminAnchorEl(event.currentTarget);
  };

  const handleAdminMenuClose = () => {
    setAdminAnchorEl(null);
  };

  // 定義功能選單內容
  const drawer = (
    <>
      {/* Logo 標題區 */}
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        alignItems: 'center',
        background: 'linear-gradient(90deg, #1a237e 0%, #0d47a1 100%)',
        color: 'white',
        borderBottom: '1px solid rgba(255,255,255,0.1)',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <img 
          src="/qq.png"
          alt="畢聯會 Logo"
          style={{
            height: '40px',
            marginRight: '10px',
            filter: 'brightness(0) invert(1)'
          }}
        />
        <Typography variant="h6" sx={{ 
          fontWeight: 600,
          letterSpacing: '0.5px'
        }}>
          畢聯會系統
        </Typography>
      </Box>

      {/* 功能選單列表 */}
      <List sx={{ p: 2 }}>
        {menuItems.map((item, index) => {
          const isCurrentPath = location.pathname === item.path;
          return (
            <ListItem 
              key={item.text} 
              disablePadding 
              sx={{ mb: 1 }}
            >
              <ListItemButton
                onClick={() => {
                  navigate(item.path);
                  setMobileMenuOpen(false);
                }}
                sx={{
                  bgcolor: isCurrentPath ? 'primary.main' : 'white',
                  color: isCurrentPath ? 'white' : 'primary.main',
                  borderRadius: '12px',
                  transition: 'all 0.3s ease',
                  boxShadow: isCurrentPath ? 
                    '0 4px 8px rgba(26, 35, 126, 0.2)' : 
                    '0 2px 4px rgba(0,0,0,0.05)',
                  '&:hover': {
                    bgcolor: isCurrentPath ? 'primary.dark' : 'primary.lighter',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 12px rgba(26, 35, 126, 0.2)',
                  },
                }}
              >
                <ListItemIcon 
                  sx={{ 
                    color: isCurrentPath ? 'white' : 'primary.main',
                    minWidth: 40,
                    transition: 'all 0.3s ease'
                  }}
                >
                  <item.icon />
                </ListItemIcon>
                <ListItemText 
                  primary={item.text}
                  primaryTypographyProps={{
                    fontWeight: isCurrentPath ? 600 : 500,
                    letterSpacing: '0.5px'
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      {/* 底部按鈕 */}
      <Box sx={{ 
        mt: 'auto', 
        p: 2, 
        background: 'linear-gradient(90deg, #1a237e 0%, #0d47a1 100%)',
        borderTop: '1px solid rgba(255,255,255,0.1)'
      }}>
        <Button
          fullWidth
          variant="contained"
          startIcon={<AddToHomeScreenIcon />}
          onClick={() => {
            setShowPWAPrompt(true);
            setMobileMenuOpen(false);
          }}
          sx={{
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            color: 'white',
            borderRadius: '12px',
            py: 1.5,
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.2)',
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
            },
            transition: 'all 0.3s ease'
          }}
        >
          加入主畫面
        </Button>
      </Box>
    </>
  );

  return (
    <Box 
      className={isIOSPWA ? 'ios-pwa' : ''}
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
    >
      <Box 
        className={isIOSPWA ? 'ios-pwa-content' : ''}
        sx={{ flex: 1, overflow: 'auto' }}
      >
        <AppBar 
          component={motion.div}
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
          position="fixed" 
          elevation={0}
          sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.95)', 
            backdropFilter: 'blur(8px)',
            borderBottom: 'none'
          }}
        >
          <Container maxWidth="xl">
            <Toolbar 
              disableGutters 
              sx={{ 
                minHeight: '64px',
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2
              }}
            >
              {/* 手機版漢堡選單按鈕 */}
              <IconButton
                edge="start"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                sx={{ 
                  mr: 2, 
                  display: { xs: 'flex', md: 'none' },
                  color: 'primary.main',
                  '&:hover': {
                    bgcolor: 'primary.lighter',
                  },
                  transition: 'all 0.3s ease',
                  transform: mobileMenuOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                  width: 40,
                  height: 40,
                  borderRadius: '12px',
                  border: '2px solid',
                  borderColor: 'primary.main',
                  '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem',
                    transition: 'all 0.3s ease',
                  },
                  '&:active': {
                    transform: mobileMenuOpen ? 'rotate(90deg) scale(0.95)' : 'rotate(0deg) scale(0.95)',
                  }
                }}
              >
                <MenuIcon />
              </IconButton>

              {/* Logo */}
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  flexGrow: isMobile ? 1 : 0,
                  minWidth: isMobile ? 'auto' : 200
                }}
              >
                {!isMobile && (
                  <img 
                    src="/qq.png"
                    alt="畢聯會 Logo"
                    style={{
                      height: '40px',
                      marginRight: '10px'
                    }}
                  />
                )}
                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    fontWeight: 700,
                    color: 'primary.main',
                    textDecoration: 'none',
                    cursor: 'pointer'
                  }}
                  onClick={() => navigate('/dashboard')}
                >
                  畢聯會系統
                </Typography>
              </Box>

              {/* 桌面版導航選單 */}
              {!isMobile && (
                <Box 
                  sx={{ 
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Stack 
                    direction="row" 
                    spacing={2}
                  >
                    {menuItems.map((item) => {
                      const isActive = 
                        item.path === location.pathname ||
                        (item.path !== '/dashboard' && location.pathname.startsWith(item.path));

                      return (
                        <Button
                          key={item.text}
                          onClick={() => navigate(item.path)}
                          startIcon={
                            <item.icon 
                              sx={{ 
                                color: isActive ? 'white' : 'inherit'
                              }} 
                            />
                          }
                          sx={{
                            backgroundColor: isActive ? 'primary.main' : 'transparent',
                            color: isActive ? 'white' : 'text.primary',
                            '&:hover': {
                              backgroundColor: isActive 
                                ? 'primary.dark'
                                : 'rgba(0, 0, 0, 0.04)',
                            },
                            borderRadius: 2,
                            px: 3,
                            py: 1,
                            minWidth: 100,
                            transition: 'all 0.2s ease-in-out',
                            fontWeight: isActive ? 600 : 400,
                            textTransform: 'none',  // 防止文字全大寫
                          }}
                        >
                          {item.text}
                        </Button>
                      );
                    })}
                  </Stack>
                </Box>
              )}

              {/* Right: User Menu */}
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  minWidth: 200,  // 確保用戶區域有固定寬度
                  justifyContent: 'flex-end',
                  gap: 2
                }}
              >
                {!error && (
                  <>
                    <NotificationButton 
                      notifications={notifications} 
                      onOpen={handleNotificationsOpen}
                    />
                    
                    {/* 加通知菜單 */}
                    <Menu
                      anchorEl={notificationAnchorEl}
                      open={Boolean(notificationAnchorEl)}
                      onClose={() => setNotificationAnchorEl(null)}
                      PaperProps={{
                        sx: { 
                          width: 320,
                          maxHeight: 400
                        }
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      {notifications.length === 0 ? (
                        <MenuItem disabled>
                          <Typography variant="body2">沒有新通知</Typography>
                        </MenuItem>
                      ) : (
                        <>
                          {notifications.map((notification) => (
                            <MenuItem 
                              key={notification.id}
                              onClick={async () => {
                                await markAsRead(notification.id);
                                navigate('/dashboard/announcements');
                                setNotificationAnchorEl(null);
                              }}
                              sx={{ 
                                py: 1.5,
                                px: 2,
                                borderBottom: '1px solid',
                                borderColor: 'divider'
                              }}
                            >
                              <Box sx={{ width: '100%' }}>
                                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                  {notification.title}
                                </Typography>
                                <Typography 
                                  variant="body2" 
                                  color="text.secondary"
                                  sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                  }}
                                >
                                  {notification.content}
                                </Typography>
                                <Typography 
                                  variant="caption" 
                                  color="text.secondary"
                                  sx={{ mt: 0.5, display: 'block' }}
                                >
                                  {new Date(notification.created_at).toLocaleString()}
                                </Typography>
                              </Box>
                            </MenuItem>
                          ))}
                          <MenuItem 
                            onClick={() => {
                              navigate('/dashboard/announcements');
                              setNotificationAnchorEl(null);
                            }}
                            sx={{ 
                              justifyContent: 'center',
                              color: 'primary.main',
                              fontWeight: 500
                            }}
                          >
                            查看所有公告
                          </MenuItem>
                        </>
                      )}
                    </Menu>
                  </>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {/* 會長專用管理選單 */}
                  {user?.role === 'president' && (
                    <>
                      <Tooltip title="管理功能">
                        <IconButton
                          onClick={handleAdminMenuOpen}
                          sx={{ 
                            color: 'primary.main',
                            '&:hover': {
                              bgcolor: 'primary.lighter'
                            }
                          }}
                        >
                          <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                          >
                            <PeopleIcon />
                          </motion.div>
                        </IconButton>
                      </Tooltip>

                      <Menu
                        anchorEl={adminAnchorEl}
                        open={Boolean(adminAnchorEl)}
                        onClose={handleAdminMenuClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
                            mt: 1.5,
                            minWidth: 200,
                            borderRadius: 2,
                            '& .MuiMenuItem-root': {
                              px: 2,
                              py: 1.5,
                              borderRadius: 1,
                              mx: 0.5,
                              mb: 0.5
                            }
                          }
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                        <Box sx={{ px: 2, py: 1.5 }}>
                          <Typography variant="subtitle2" color="text.secondary">
                            管理功能
                          </Typography>
                        </Box>
                        
                        <Divider sx={{ my: 0.5 }} />
                        
                        <MenuItem onClick={() => {
                          navigate('/dashboard/department-management');
                          handleAdminMenuClose();
                        }}>
                          <ListItemIcon>
                            <PeopleIcon fontSize="small" color="primary" />
                          </ListItemIcon>
                          <ListItemText 
                            primary="組別管理"
                            primaryTypographyProps={{
                              variant: 'body2',
                              fontWeight: 500
                            }}
                          />
                        </MenuItem>
                      </Menu>
                    </>
                  )}

                  {/* 用戶頭像 */}
                  <Tooltip title="開啟設定">
                    <IconButton onClick={handleOpenUserMenu}>
                      <Avatar 
                        sx={{ 
                          bgcolor: getPastelColor(user?.name),
                          width: 35,
                          height: 35,
                          fontSize: '1.2rem'
                        }}
                      >
                        {getAvatarEmoji(user?.name)}
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  sx={{ mt: '45px' }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={() => {
                    navigate('/dashboard/profile');
                    handleCloseUserMenu();
                  }}>
                    <PersonIcon sx={{ mr: 1 }} /> 個人資料
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <LogoutIcon sx={{ mr: 1 }} /> 登出
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Container>

          {/* 添加跑馬燈 */}
          <Box 
            sx={{ 
              width: '100%',
              background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
              color: 'white',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              height: { xs: '32px', md: '36px' },  // RWD 高度
              borderTop: '1px solid rgba(255,255,255,0.1)',
              boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.2)'
            }}
          >
            <Box
              sx={{
                whiteSpace: 'nowrap',
                animation: {
                  xs: `${marquee} 10s linear infinite`,  // 手機版更快的動畫
                  md: `${marquee} 15s linear infinite`   // 桌面版正常速度
                },
                display: 'flex',
                alignItems: 'center',
                '& > *': {
                  mx: { xs: 1, md: 3 }  // RWD 間距
                }
              }}
            >
              {[...Array(2)].map((_, index) => (
                <Typography 
                  key={index}
                  variant="body2"
                  component="span"
                  sx={{ 
                    fontWeight: 500,
                    fontSize: { xs: '0.85rem', md: '0.95rem' },  // RWD 字體大小
                    display: 'flex',
                    alignItems: 'center',
                    gap: { xs: 1, md: 2 },  // RWD 間距
                    letterSpacing: { xs: '0.3px', md: '0.5px' },  // RWD 字距
                    '& .star': {
                      animation: `${sparkle} 1.5s ease-in-out infinite`,
                      fontSize: { xs: '0.9rem', md: '1rem' }  // RWD 圖示大小
                    }
                  }}
                >
                  <span className="star">✨</span>
                  {isMobile ? '114學年度學測加油！' : '114學年度學測加油！學測就上！'}  {/* 手機版縮短文字 */}
                  <span style={{ opacity: 0.6 }}>|</span>
                  <span className="star">🎯</span>
                  {isMobile ? '相信自己！' : '相信自己，你定可以！'}  {/* 手機版縮短文字 */}
                </Typography>
              ))}
            </Box>
          </Box>
        </AppBar>

        {/* 添加功能選單抽屜 */}
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              width: 280,
              boxSizing: 'border-box',
              bgcolor: 'background.paper',
              borderRight: 1,
              borderColor: 'divider',
              zIndex: 1300,
            },
          }}
        >
          {drawer}
        </Drawer>

        {/* 手機版收合按鈕 */}
        <IconButton
          onClick={() => setMobileOpen(!mobileOpen)}
          sx={collapseButtonStyles(mobileOpen, true)}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1.5
          }}>
            <ChevronLeftIcon />
            <Typography 
              variant="caption" 
              sx={{ 
                fontSize: '0.8rem',
                fontWeight: 'bold',
                writingMode: 'vertical-rl',
                textOrientation: 'mixed',
                letterSpacing: 2,
                color: mobileOpen ? 'white' : 'text.primary',
                textShadow: mobileOpen ? 
                  '0 1px 2px rgba(0,0,0,0.2)' : 
                  'none'
              }}
            >
              {mobileOpen ? '收合選單' : '展開選單'}
            </Typography>
          </Box>
        </IconButton>

        {/* 桌面版收合按鈕 */}
        <IconButton
          onClick={() => setSidebarOpen(!sidebarOpen)}
          sx={collapseButtonStyles(sidebarOpen)}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1.5
          }}>
            <ChevronLeftIcon />
            <Typography 
              variant="caption" 
              sx={{ 
                fontSize: '0.8rem',
                fontWeight: 'bold',
                writingMode: 'vertical-rl',
                textOrientation: 'mixed',
                letterSpacing: 2,
                color: sidebarOpen ? 'white' : 'text.primary',
                textShadow: sidebarOpen ? 
                  '0 1px 2px rgba(0,0,0,0.2)' : 
                  'none'
              }}
            >
              {sidebarOpen ? '收合選單' : '展開選單'}
            </Typography>
          </Box>
        </IconButton>

        {/* 手機版側邊欄 */}
        <Box
          component="nav"
          sx={{
            width: 280,
            flexShrink: 0,
            display: { xs: 'block', md: 'none' },
            position: 'fixed',
            top: 100,
            left: mobileOpen ? 0 : -280,
            height: 'calc(100vh - 100px)',
            bgcolor: 'background.paper',
            borderRight: 1,
            borderColor: 'divider',
            zIndex: 1200,
            transition: 'left 0.3s ease',
            boxShadow: '2px 0 8px rgba(0,0,0,0.1)',
          }}
        >
          <DepartmentSidebar user={user} />
        </Box>

        {/* 遮罩層 - 不包含頂部 */}
        <Box
          sx={{
            position: 'fixed',
            top: 100,
            left: 0,
            width: '100%',
            height: 'calc(100vh - 100px)',
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(3px)',
            zIndex: 1199,
            opacity: sidebarOpen || mobileOpen ? 1 : 0,
            visibility: sidebarOpen || mobileOpen ? 'visible' : 'hidden',
            transition: 'all 0.3s ease',
            pointerEvents: sidebarOpen || mobileOpen ? 'auto' : 'none',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (mobileOpen) {
              setMobileOpen(false);
            }
            if (sidebarOpen) {
              setSidebarOpen(false);
            }
          }}
        />

        {/* 桌面版側邊欄容器 */}
        <Box
          component="nav"
          sx={{
            width: 280,
            flexShrink: 0,
            display: { xs: 'none', md: 'block' },
            position: 'fixed',
            top: 100,
            left: sidebarOpen ? 0 : -280,
            height: 'calc(100vh - 100px)',
            bgcolor: 'background.paper',
            borderRight: 1,
            borderColor: 'divider',
            zIndex: 1200,
            transition: 'left 0.3s ease',
            boxShadow: '2px 0 8px rgba(0,0,0,0.1)',
          }}
        >
          <DepartmentSidebar user={user} />
        </Box>

        {/* 主要內容區域 - 移除模糊效果 */}
        <Box
          component="main"
          onClick={() => {
            if (mobileOpen) {
              setMobileOpen(false);
            }
            if (sidebarOpen) {
              setSidebarOpen(false);
            }
          }}
          sx={{
            flexGrow: 1,
            p: 3,
            width: '100%',
            marginTop: '100px',
            paddingLeft: { xs: 3, md: '30px' },
            transition: 'all 0.3s ease',
            minHeight: 'calc(100vh - 100px)',
            bgcolor: '#f8f9fa',
            position: 'relative',
            zIndex: 1,
            cursor: (sidebarOpen || mobileOpen) ? 'pointer' : 'default',
          }}
        >
          <Container maxWidth="xl">
            <Outlet />
          </Container>
        </Box>

        {/* 在最後添加 PWAPrompt */}
        {isPWASupported && (
          <PWAPrompt 
            open={showPWAPrompt}
            onClose={() => setShowPWAPrompt(false)}
          />
        )}
      </Box>
    </Box>
  );
} 