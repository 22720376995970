import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  CircularProgress,
  TextField
} from '@mui/material';
import { supabase } from '../../utils/supabase';
import { useAuthCheck } from '../../hooks/useAuthCheck';

export default function VideoSubmissions() {
  const { loading: authLoading, authorized, user } = useAuthCheck('影器', true);
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewDialog, setViewDialog] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [editDialog, setEditDialog] = useState(false);
  const [editFormData, setEditFormData] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    if (authorized) {
      fetchSubmissions();
    }
  }, [authorized]);

  const fetchSubmissions = async () => {
    try {
      const { data, error } = await supabase
        .from('video_proposals')
        .select('*')
        .eq('department', '影器')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setSubmissions(data);
    } catch (error) {
      console.error('Error:', error);
      setError('獲取提交記錄失敗');
    } finally {
      setLoading(false);
    }
  };

  const getStatusChip = (status) => {
    const statusConfig = {
      pending: { label: '待審核', color: 'warning' },
      approved: { label: '已通過', color: 'success' },
      rejected: { label: '已駁回', color: 'error' }
    };
    const config = statusConfig[status] || { label: '未知', color: 'default' };
    return <Chip label={config.label} color={config.color} size="small" />;
  };

  const getProgressChip = (status) => {
    const progressConfig = {
      preparing: { label: '準備拍攝', color: 'default' },
      filming: { label: '拍攝中', color: 'warning' },
      filmed: { label: '拍攝完成', color: 'info' },
      editing: { label: '剪輯中', color: 'warning' },
      edited: { label: '剪輯完畢', color: 'info' },
      uploaded: { label: '已上傳', color: 'success' }
    };
    const config = progressConfig[status] || progressConfig.preparing;
    return <Chip label={config.label} color={config.color} size="small" />;
  };

  const handleEdit = (submission) => {
    setEditFormData({
      ...submission,
      deadline: submission.deadline.slice(0, 16) // 格式化日期時間
    });
    setEditDialog(true);
  };

  const handleUpdate = async () => {
    try {
      const { error } = await supabase
        .from('video_proposals')
        .update({
          title: editFormData.title,
          description: editFormData.description,
          concept: editFormData.concept,
          target_audience: editFormData.target_audience,
          script: editFormData.script,
          equipment: editFormData.equipment,
          deadline: editFormData.deadline,
          updated_at: new Date().toISOString()
        })
        .eq('id', editFormData.id);

      if (error) throw error;

      setSuccess('更新成功！');
      setEditDialog(false);
      fetchSubmissions();
    } catch (error) {
      console.error('Error:', error);
      setError('更新失敗');
    }
  };

  const handleDelete = async () => {
    try {
      const { error } = await supabase
        .from('video_proposals')
        .delete()
        .eq('id', selectedSubmission.id);

      if (error) throw error;

      setSuccess('刪除成功！');
      setConfirmDelete(false);
      setViewDialog(false);
      fetchSubmissions();
    } catch (error) {
      console.error('Error:', error);
      setError('刪除失敗');
    }
  };

  if (authLoading || loading) {
    return <CircularProgress />;
  }

  if (!authorized) return null;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          影片企劃紀錄
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>影片標題</TableCell>
                <TableCell>提交時間</TableCell>
                <TableCell>預計完成日期</TableCell>
                <TableCell>審核狀態</TableCell>
                <TableCell>製作進度</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {submissions.map((submission) => (
                <TableRow key={submission.id}>
                  <TableCell>{submission.title}</TableCell>
                  <TableCell>
                    {new Date(submission.submitted_at).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {new Date(submission.deadline).toLocaleString()}
                  </TableCell>
                  <TableCell>{getStatusChip(submission.status)}</TableCell>
                  <TableCell>{getProgressChip(submission.progress_status)}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      onClick={() => {
                        setSelectedSubmission(submission);
                        setViewDialog(true);
                      }}
                    >
                      查看
                    </Button>
                    {submission.status === 'pending' && (
                      <>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => handleEdit(submission)}
                          sx={{ ml: 1 }}
                        >
                          編輯
                        </Button>
                        <Button
                          size="small"
                          color="error"
                          onClick={() => {
                            setSelectedSubmission(submission);
                            setConfirmDelete(true);
                          }}
                          sx={{ ml: 1 }}
                        >
                          刪除
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog 
        open={viewDialog} 
        onClose={() => setViewDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>影片企劃詳情</DialogTitle>
        <DialogContent>
          {selectedSubmission && (
            <Box sx={{ pt: 2 }}>
              <Typography variant="subtitle2">影片標題</Typography>
              <Typography paragraph>{selectedSubmission.title}</Typography>
              
              <Typography variant="subtitle2">影片說明</Typography>
              <Typography paragraph>{selectedSubmission.description || '無'}</Typography>
              
              <Typography variant="subtitle2">創意概念</Typography>
              <Typography paragraph>{selectedSubmission.concept || '無'}</Typography>
              
              <Typography variant="subtitle2">目標觀眾</Typography>
              <Typography paragraph>{selectedSubmission.target_audience || '無'}</Typography>
              
              <Typography variant="subtitle2">腳本/分鏡</Typography>
              <Typography paragraph>{selectedSubmission.script || '無'}</Typography>
              
              <Typography variant="subtitle2">需要器材</Typography>
              <Box sx={{ mb: 2 }}>
                {selectedSubmission.equipment?.map((item, index) => (
                  <Chip key={index} label={item} sx={{ mr: 1, mb: 1 }} />
                ))}
              </Box>

              {selectedSubmission.status !== 'pending' && (
                <>
                  <Typography variant="subtitle2">審核意見</Typography>
                  <Typography paragraph>{selectedSubmission.review_comment || '無'}</Typography>
                  
                  <Typography variant="subtitle2">審核人</Typography>
                  <Typography paragraph>{selectedSubmission.reviewer_name}</Typography>
                  
                  <Typography variant="subtitle2">審核時間</Typography>
                  <Typography paragraph>
                    {new Date(selectedSubmission.reviewed_at).toLocaleString()}
                  </Typography>
                </>
              )}

              {selectedSubmission.progress_status !== 'preparing' && (
                <>
                  <Typography variant="subtitle2">目前進度</Typography>
                  <Typography paragraph>
                    {getProgressChip(selectedSubmission.progress_status)}
                  </Typography>
                  
                  <Typography variant="subtitle2">進度備註</Typography>
                  <Typography paragraph>{selectedSubmission.progress_notes || '無'}</Typography>
                  
                  {selectedSubmission.video_url && (
                    <>
                      <Typography variant="subtitle2">影片連結</Typography>
                      <Typography paragraph>
                        <a href={selectedSubmission.video_url} target="_blank" rel="noopener noreferrer">
                          {selectedSubmission.video_url}
                        </a>
                      </Typography>
                    </>
                  )}
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewDialog(false)}>關閉</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={editDialog} 
        onClose={() => setEditDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>編輯影片企劃</DialogTitle>
        <DialogContent>
          {editFormData && (
            <Box sx={{ pt: 2 }}>
              <TextField
                fullWidth
                label="影片標題"
                value={editFormData.title}
                onChange={(e) => setEditFormData({ 
                  ...editFormData, 
                  title: e.target.value 
                })}
                required
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                label="影片說明"
                value={editFormData.description}
                onChange={(e) => setEditFormData({ 
                  ...editFormData, 
                  description: e.target.value 
                })}
                multiline
                rows={3}
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                label="創意概念"
                value={editFormData.concept}
                onChange={(e) => setEditFormData({ 
                  ...editFormData, 
                  concept: e.target.value 
                })}
                multiline
                rows={3}
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                label="目標觀眾"
                value={editFormData.target_audience}
                onChange={(e) => setEditFormData({ 
                  ...editFormData, 
                  target_audience: e.target.value 
                })}
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                label="腳本/分鏡"
                value={editFormData.script}
                onChange={(e) => setEditFormData({ 
                  ...editFormData, 
                  script: e.target.value 
                })}
                multiline
                rows={4}
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                label="預計完成日期"
                type="datetime-local"
                value={editFormData.deadline}
                onChange={(e) => setEditFormData({ 
                  ...editFormData, 
                  deadline: e.target.value 
                })}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 3 }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialog(false)}>取消</Button>
          <Button onClick={handleUpdate} variant="contained">
            更新
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
      >
        <DialogTitle>確認刪除</DialogTitle>
        <DialogContent>
          <Typography>
            確定要刪除這個影片企劃嗎？此操作無法復原。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)}>取消</Button>
          <Button onClick={handleDelete} color="error">
            確認刪除
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
} 