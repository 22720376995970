import { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  IconButton,
  Tooltip,
  Link,
  Chip
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Link as LinkIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import { supabase } from '../utils/supabase';
import { useAuth } from '../contexts/AuthContext';

// 檔案分類選項
const FILE_CATEGORIES = [
  { value: 'document', label: '文件' },
  { value: 'form', label: '表格' },
  { value: 'photo', label: '照片' },
  { value: 'other', label: '其他' }
];

export default function FileManager() {
  const { user } = useAuth();
  const [files, setFiles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    link: '',
    category: ''
  });

  // 檢查是否為會長或副會長
  const isLeader = user?.role === 'president' || user?.role === 'vice_president';

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('files')
        .select(`
          *,
          users:created_by (name)
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setFiles(data || []);
    } catch (error) {
      console.error('Error fetching files:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { error } = await supabase
        .from('files')
        .insert([
          {
            ...formData,
            created_by: user.id
          }
        ]);

      if (error) throw error;
      
      setOpenDialog(false);
      setFormData({
        title: '',
        description: '',
        link: '',
        category: ''
      });
      fetchFiles();
    } catch (error) {
      console.error('Error adding file:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('確定要刪除此檔案嗎？')) return;
    
    try {
      const { error } = await supabase
        .from('files')
        .delete()
        .eq('id', id);

      if (error) throw error;
      fetchFiles();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          檔案管理
        </Typography>
        {isLeader && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
          >
            新增檔案
          </Button>
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>標題</TableCell>
              <TableCell>說明</TableCell>
              <TableCell>分類</TableCell>
              <TableCell>建立者</TableCell>
              <TableCell>建立時間</TableCell>
              <TableCell>連結</TableCell>
              {isLeader && <TableCell align="right">操作</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file) => (
              <TableRow key={file.id}>
                <TableCell>{file.title}</TableCell>
                <TableCell>{file.description}</TableCell>
                <TableCell>
                  <Chip 
                    label={FILE_CATEGORIES.find(c => c.value === file.category)?.label} 
                    size="small"
                  />
                </TableCell>
                <TableCell>{file.users?.name}</TableCell>
                <TableCell>
                  {new Date(file.created_at).toLocaleString()}
                </TableCell>
                <TableCell>
                  <Link 
                    href={file.link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <IconButton size="small">
                      <LinkIcon />
                    </IconButton>
                  </Link>
                </TableCell>
                {isLeader && (
                  <TableCell align="right">
                    <Tooltip title="刪除">
                      <IconButton 
                        size="small" 
                        onClick={() => handleDelete(file.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* 新增檔案對話框 */}
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>新增檔案</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="標題"
              required
              value={formData.title}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                title: e.target.value
              }))}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="說明"
              multiline
              rows={3}
              value={formData.description}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                description: e.target.value
              }))}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="雲端連結"
              required
              value={formData.link}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                link: e.target.value
              }))}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              select
              label="分類"
              required
              value={formData.category}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                category: e.target.value
              }))}
            >
              {FILE_CATEGORIES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>取消</Button>
          <Button 
            variant="contained" 
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? '處理中...' : '確認'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
} 