import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';
import { useAuth } from '../contexts/AuthContext';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Paper,
  InputAdornment,
  IconButton,
  useTheme
} from '@mui/material';
import { motion } from 'framer-motion';
import { AccountCircle, Lock, Visibility, VisibilityOff } from '@mui/icons-material';

const Login = () => {
  const theme = useTheme();
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login, user } = useAuth();

  useEffect(() => {
    if (user) {
      navigate('/dashboard', { replace: true });
    }
  }, [user, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      
      // 查詢 users 表格
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('account', account)
        .eq('password', password);

      if (error) throw error;

      // 檢查是否有找到用戶
      if (!data || data.length === 0) {
        throw new Error('帳號或密碼錯誤');
      }

      // 使用第一筆資料
      const userData = data[0];

      // 使用 context 的 login 函數
      login(userData);
      
      // 登入成功後跳轉到首頁
      navigate('/dashboard', { replace: true });
      
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container 
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      maxWidth="sm"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: `linear-gradient(135deg, 
          ${theme.palette.primary.main}15 0%,
          ${theme.palette.background.default} 100%)`,
        padding: { xs: 2, md: 4 },
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'hidden',
          zIndex: 0
        }}
      >
        {[...Array(6)].map((_, i) => (
          <motion.div
            key={i}
            animate={{
              y: ['0%', '100%'],
              opacity: [0.3, 0],
              scale: [1, 1.2]
            }}
            transition={{
              duration: Math.random() * 5 + 3,
              repeat: Infinity,
              ease: 'linear',
              delay: i * 0.8
            }}
            style={{
              position: 'absolute',
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              background: `linear-gradient(135deg, ${theme.palette.primary.main}20, transparent)`,
              left: `${Math.random() * 100}%`,
              top: `-20%`
            }}
          />
        ))}
      </Box>

      <Paper
        elevation={3}
        component={motion.div}
        whileHover={{ scale: 1.01 }}
        transition={{ type: "spring", stiffness: 300 }}
        sx={{
          p: { xs: 4, md: 6 },
          width: '100%',
          maxWidth: '500px',
          borderRadius: 3,
          background: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 8px 32px rgba(31, 38, 135, 0.15)',
          border: '1px solid rgba(255, 255, 255, 0.18)',
          position: 'relative',
          zIndex: 1
        }}
      >
        <motion.div
          initial={{ scale: 0, rotate: -180 }}
          animate={{ scale: 1, rotate: 0 }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <img 
            src="/qq.png" 
            alt="Logo" 
            style={{ 
              width: '120px',
              height: '120px',
              marginBottom: 24,
              filter: 'drop-shadow(0 4px 6px rgba(0,0,0,0.1))'
            }} 
          />
        </motion.div>

        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography 
            component="h1" 
            variant="h4"
            sx={{ 
              fontWeight: 700,
              background: theme => `linear-gradient(45deg, 
                ${theme.palette.primary.main}, 
                ${theme.palette.primary.dark})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 1
            }}
          >
            畢聯會管理系統
          </Typography>
          
          <Typography 
            variant="subtitle2" 
            color="text.secondary"
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0.5 
            }}
          >
            <motion.div
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              ⚠️
            </motion.div>
            非畢聯會成員無法進入
          </Typography>
        </Box>

        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              width: '100%',
              mb: 2,
              borderRadius: 2
            }}
          >
            {error}
          </Alert>
        )}

        <Box 
          component="form" 
          onSubmit={handleLogin} 
          sx={{ 
            width: '100%',
            '& .MuiTextField-root': {
              mb: 3
            }
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label="帳號"
            autoFocus
            value={account}
            onChange={(e) => setAccount(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                backgroundColor: 'white',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                }
              }
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="密碼"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock color="primary" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                backgroundColor: 'white',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                }
              }
            }}
          />
          <Button
            component={motion.button}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{
              mt: 4,
              mb: 2,
              py: 1.5,
              borderRadius: 2,
              background: theme => `linear-gradient(45deg, 
                ${theme.palette.primary.main} 30%, 
                ${theme.palette.primary.dark} 90%)`,
              boxShadow: theme => `0 3px 5px 2px ${theme.palette.primary.main}30`,
              fontSize: '1.1rem',
              fontWeight: 500,
              letterSpacing: 1,
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: theme => `0 6px 20px ${theme.palette.primary.main}40`
              }
            }}
          >
            {loading ? '登入中...' : '登入'}
          </Button>
        </Box>

        <Typography 
          variant="caption" 
          color="text.secondary"
          align="center"
          sx={{ 
            display: 'block',
            mt: 3,
            opacity: 0.7
          }}
        >
          登入即表示您同意遵守畢聯會相關規定
        </Typography>
      </Paper>
    </Container>
  );
};

export default Login;