import { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Alert,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Zoom
} from '@mui/material';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import { supabase } from '../../utils/supabase';

export default function Feedback() {
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    type: '',  // 意見類型
    contact: '' // 聯絡方式（選填）
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // 基本驗證
      if (!formData.title.trim() || !formData.content.trim() || !formData.type) {
        throw new Error('請填寫必要欄位');
      }

      // 新增到資料庫
      const { error: submitError } = await supabase
        .from('feedback')
        .insert([
          {
            title: formData.title.trim(),
            content: formData.content.trim(),
            type: formData.type,
            contact: formData.contact.trim(),
            status: 'pending'  // 預設狀態為待處理
          }
        ]);

      if (submitError) throw submitError;

      // 清空表單
      setFormData({
        title: '',
        content: '',
        type: '',
        contact: ''
      });
      
      // 顯示成功對話框而不是 Snackbar
      setShowSuccessDialog(true);

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 4,
          borderRadius: 2
        }}
      >
        <Typography 
          variant="h4" 
          gutterBottom
          sx={{ 
            fontWeight: 700,
            textAlign: 'center',
            mb: 4
          }}
        >
          意見箱
        </Typography>

        <form onSubmit={handleSubmit}>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>意見類型</InputLabel>
            <Select
              value={formData.type}
              label="意見類型"
              onChange={(e) => setFormData({
                ...formData,
                type: e.target.value
              })}
              required
            >
              <MenuItem value="suggestion">建議</MenuItem>
              <MenuItem value="complaint">申訴</MenuItem>
              <MenuItem value="question">問題詢問</MenuItem>
              <MenuItem value="other">其他</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="標題"
            value={formData.title}
            onChange={(e) => setFormData({
              ...formData,
              title: e.target.value
            })}
            required
            sx={{ mb: 3 }}
          />

          <TextField
            fullWidth
            label="內容"
            value={formData.content}
            onChange={(e) => setFormData({
              ...formData,
              content: e.target.value
            })}
            required
            multiline
            rows={4}
            sx={{ mb: 3 }}
          />

          <TextField
            fullWidth
            label="聯絡方式（選填）"
            value={formData.contact}
            onChange={(e) => setFormData({
              ...formData,
              contact: e.target.value
            })}
            helperText="若希望收到回覆，請留下聯絡方式"
            sx={{ mb: 4 }}
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            disabled={loading}
          >
            {loading ? '提交中...' : '提交意見'}
          </Button>
        </form>

        <Dialog
          open={showSuccessDialog}
          onClose={() => setShowSuccessDialog(false)}
          TransitionComponent={Zoom}
          PaperProps={{
            sx: {
              borderRadius: 2,
              minWidth: { xs: '90%', sm: 400 }
            }
          }}
        >
          <DialogTitle sx={{ textAlign: 'center', pt: 3 }}>
            <CheckCircleOutlineIcon 
              color="success" 
              sx={{ 
                fontSize: 60,
                mb: 2,
                animation: 'bounce 0.5s ease'
              }} 
            />
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              提交成功！
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography 
              align="center" 
              color="text.secondary"
              sx={{ mb: 2 }}
            >
              感謝您的意見回饋，我們會認真處理您的建議。
              {formData.contact && '我們會透過您提供的聯絡方式與您聯繫。'}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
            <Button 
              variant="contained"
              onClick={() => setShowSuccessDialog(false)}
              sx={{ 
                px: 4,
                borderRadius: 2
              }}
            >
              確定
            </Button>
          </DialogActions>
        </Dialog>

        {error && (
          <Alert 
            severity="error" 
            sx={{ mt: 2 }}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        )}
      </Paper>

      <style>
        {`
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-20px);
            }
            60% {
              transform: translateY(-10px);
            }
          }
        `}
      </style>
    </Container>
  );
} 