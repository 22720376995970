import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../utils/supabase';
import {
  Container,
  Paper,
  Typography,
  Box,
  Avatar,
  Grid,
  Skeleton,
  Divider,
  Card,
  CardContent,
  Chip,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  IconButton,
  InputAdornment,
  Autocomplete,
  TextareaAutosize,
  Tooltip,
  Snackbar,
} from '@mui/material';
import { School as SchoolIcon, Class as ClassIcon, Badge as BadgeIcon, Lock as LockIcon, Visibility, VisibilityOff, Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { motion } from 'framer-motion';

// 角色映射函數
const getRoleDisplay = (role) => {
  const roleMap = {
    'president': '會長',
    'vice_president': '副會長',
    'leader': '組長',
    'member': '成員'
  };
  return roleMap[role] || role;
};

// 角色對應的顏色
const getRoleColor = (role) => {
  const colorMap = {
    'president': '#FF4081',      // 粉紅色
    'vice_president': '#7C4DFF', // 紫色
    'leader': '#00BCD4',         // 青色
    'member': '#4CAF50'          // 綠色
  };
  return colorMap[role] || '#757575';
};

// 添加可愛動物表情符號映射
const getAvatarEmoji = (name) => {
  const animalEmojis = [
    '🐰', '🐱', '🐨', '🐼', 
    '🐯', '🦁', '🐮', 
    '🦉', '🐧', '🦄', '🐶', '🐹'
  ];
  
  // 根據名字的第一個字計算索引
  const charCode = name?.charCodeAt(0) || 0;
  const index = charCode % animalEmojis.length;
  return animalEmojis[index];
};

// 獲取隨機柔和的背景顏色
const getPastelColor = (name) => {
  const pastelColors = [
    '#FFB3BA', // 粉紅
    '#BAFFC9', // 薄荷綠
    '#BAE1FF', // 天藍
    '#FFFFBA', // 檸檬黃
    '#FFB3F7', // 粉紫
    '#B3ECFF', // 淺藍
    '#FFC8BA', // 蜜桃
    '#E5BAFF', // 淺紫
    '#BAF7C3', // 淺綠
  ];
  
  const charCode = name?.charCodeAt(0) || 0;
  const index = charCode % pastelColors.length;
  return pastelColors[index];
};

const Profile = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [editing, setEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    bio: '',
    skills: [],
    interests: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // 預設的技能選項
  const skillOptions = [
    '程式設計', 'Web開發', '影片剪輯', '平面設計',
    '攝影', '寫作', '領導能力', '團隊合作',
    '公眾演說', '專案管理', '外語能力'
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Fetch error:', error);
          throw error;
        }
        
        console.log('Fetched data:', data);
        setUserData(data);
        setProfileData({
          bio: data.bio || '',
          skills: data.skills || [],
          interests: data.interests || []
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchUserData();
    }
  }, [user]);

  const handlePasswordChange = async () => {
    try {
      setError('');
      setSuccess('');

      // 基本驗證
      if (passwordData.newPassword !== passwordData.confirmPassword) {
        setError('新密碼與確認密碼不符');
        return;
      }

      if (passwordData.newPassword.length < 6) {
        setError('新密碼長度至少需要6個字元');
        return;
      }

      // 驗證當前密碼
      const { data: userData, error: verifyError } = await supabase
        .from('users')
        .select('*')
        .eq('id', user.id)
        .single();

      if (verifyError || !userData) {
        setError('找不到用戶資料');
        return;
      }

      if (userData.password !== passwordData.currentPassword) {
        setError('當前密碼不正確');
        return;
      }

      // 使用 RPC 呼叫來更新密碼
      const { error: updateError } = await supabase
        .rpc('update_user_password', {
          user_id: user.id,
          new_password: passwordData.newPassword
        });

      if (updateError) {
        console.error('Update error:', updateError);
        throw updateError;
      }

      // 重新獲取用戶資料確認更新
      const { data: checkData, error: checkError } = await supabase
        .from('users')
        .select('password')
        .eq('id', user.id)
        .single();

      if (checkError) {
        throw checkError;
      }

      if (checkData.password === passwordData.newPassword) {
        setSuccess('密碼修改成功！');
        
        setTimeout(() => {
          setOpenPasswordDialog(false);
          setPasswordData({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
          });
          setSuccess('');
        }, 1500);
      } else {
        throw new Error('密碼更新失敗');
      }

    } catch (error) {
      console.error('Error updating password:', error);
      setError(error.message || '密碼更新失敗，請稍後再');
    }
  };

  // 處理儲存
  const handleSave = async () => {
    try {
      setIsLoading(true);
      console.log('User ID:', user.id);
      console.log('Updating with data:', profileData);
      
      // 先嘗試直接更新
      const { data, error } = await supabase
        .from('users')
        .update({
          bio: profileData.bio || '',  // 確保不是 null
          skills: profileData.skills || [],  // 確保不是 null
          interests: profileData.interests || []  // 確保不是 null
        })
        .eq('id', user.id)
        .select()  // 立即返回更新後的數據
        .single();

      if (error) {
        console.error('Update error:', error);
        throw error;
      }

      console.log('Update response:', data);

      if (data) {
        // 更新成功
        setSnackbar({
          open: true,
          message: '個人資料更新成功！',
          severity: 'success'
        });
        
        setEditing(false);
        setUserData(data);
        setProfileData({
          bio: data.bio || '',
          skills: data.skills || [],
          interests: data.interests || []
        });
      }
      
    } catch (error) {
      console.error('Error updating profile:', error);
      setSnackbar({
        open: true,
        message: `更新失敗：${error.message}`,
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // 添加 Dialog 相關屬性
  const dialogProps = {
    onClose: () => setOpenPasswordDialog(false),
    maxWidth: 'xs',
    fullWidth: true,
    TransitionProps: {
      onExited: () => {
        setError('');
        setSuccess('');
        setPasswordData({
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
        setShowPassword({
          current: false,
          new: false,
          confirm: false,
        });
      }
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Paper sx={{ p: 4 }}>
          <Skeleton variant="rectangular" height={200} />
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      {/* 個人資料卡片 */}
      <Card elevation={3} sx={{ borderRadius: 2, mb: 3 }}>
        <CardContent sx={{ p: 3 }}>
          {/* 頭像和基本資訊 */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'center', sm: 'flex-start' },
            gap: 3,
            mb: 3
          }}>
            {/* 頭像區域 */}
            <Box sx={{ position: 'relative' }}>
              <Avatar
                sx={{ 
                  width: 120, 
                  height: 120,
                  bgcolor: getPastelColor(userData?.name),
                  fontSize: '3.5rem',  // 加大表情符號
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.1) rotate(5deg)',
                    boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                    cursor: 'pointer'
                  },
                  border: '4px solid white',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }}
              >
                {getAvatarEmoji(userData?.name)}
              </Avatar>
            </Box>

            {/* 基本資訊 */}
            <Box sx={{ flex: 1 }}>
              <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                {userData?.name}
              </Typography>
              
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                <Chip
                  icon={<SchoolIcon />}
                  label={userData?.class || '未設定班級'}
                  variant="outlined"
                  size="small"
                />
                <Chip
                  icon={<BadgeIcon />}
                  label={getRoleDisplay(userData?.role)}
                  sx={{ 
                    bgcolor: getRoleColor(userData?.role),
                    color: 'white'
                  }}
                  size="small"
                />
              </Box>

              <Typography variant="body2" color="text.secondary">
                帳號：{userData?.account}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ mb: 3 }} />

          {/* 自我介紹區塊 */}
          <Box sx={{ mb: 3 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2
            }}>
              <Typography variant="h6">
                自我介紹
              </Typography>
              <Button
                startIcon={editing ? <SaveIcon /> : <EditIcon />}
                onClick={editing ? handleSave : () => setEditing(true)}
                variant={editing ? "contained" : "outlined"}
                size="small"
                disabled={isLoading}
              >
                {isLoading ? "儲存中..." : (editing ? "儲存" : "編輯")}
              </Button>
            </Box>

            {editing ? (
              <TextField
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                value={profileData.bio}
                onChange={(e) => setProfileData({
                  ...profileData,
                  bio: e.target.value
                })}
                placeholder="介紹一下你自己..."
                variant="outlined"
              />
            ) : (
              <Typography 
                variant="body1" 
                sx={{ 
                  whiteSpace: 'pre-wrap',
                  color: profileData.bio ? 'text.primary' : 'text.secondary',
                  minHeight: '3em'
                }}
              >
                {profileData.bio || '尚未填寫自我介紹'}
              </Typography>
            )}
          </Box>

          {/* 技能標籤區塊 */}
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              專長與技能
            </Typography>
            
            {editing ? (
              <Autocomplete
                multiple
                options={skillOptions}
                value={profileData.skills}
                onChange={(_, newValue) => setProfileData({
                  ...profileData,
                  skills: newValue
                })}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="輸入或選擇你的技能"
                    size="small"
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    const { key, ...otherProps } = getTagProps({ index });
                    return (
                      <Chip
                        key={key}
                        {...otherProps}
                        label={option}
                        color="primary"
                        variant="outlined"
                        size="small"
                      />
                    );
                  })
                }
              />
            ) : (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {profileData.skills.length > 0 ? (
                  profileData.skills.map((skill, index) => (
                    <Chip
                      key={index}
                      label={skill}
                      color="primary"
                      variant="outlined"
                      size="small"
                    />
                  ))
                ) : (
                  <Typography color="text.secondary">
                    尚未添加任何技能
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>

      {/* 修改密碼按鈕 */}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          startIcon={<LockIcon />}
          onClick={() => setOpenPasswordDialog(true)}
          sx={{ 
            px: 4,
            py: 1,
            borderRadius: 2
          }}
        >
          修改密碼
        </Button>
      </Box>

      {/* 密碼修改對話框 */}
      <Dialog open={openPasswordDialog} {...dialogProps}>
        <DialogTitle>修改密碼</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {success}
            </Alert>
          )}
          
          {/* 目前密碼 */}
          <TextField
            margin="dense"
            label="目前密碼"
            type={showPassword.current ? 'text' : 'password'}
            fullWidth
            value={passwordData.currentPassword}
            onChange={(e) => setPasswordData({
              ...passwordData,
              currentPassword: e.target.value
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword({
                      ...showPassword,
                      current: !showPassword.current
                    })}
                    edge="end"
                  >
                    {showPassword.current ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          {/* 新密碼 */}
          <TextField
            margin="dense"
            label="新密碼"
            type={showPassword.new ? 'text' : 'password'}
            fullWidth
            value={passwordData.newPassword}
            onChange={(e) => setPasswordData({
              ...passwordData,
              newPassword: e.target.value
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword({
                      ...showPassword,
                      new: !showPassword.new
                    })}
                    edge="end"
                  >
                    {showPassword.new ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          {/* 確認新密碼 */}
          <TextField
            margin="dense"
            label="確認新密碼"
            type={showPassword.confirm ? 'text' : 'password'}
            fullWidth
            value={passwordData.confirmPassword}
            onChange={(e) => setPasswordData({
              ...passwordData,
              confirmPassword: e.target.value
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword({
                      ...showPassword,
                      confirm: !showPassword.confirm
                    })}
                    edge="end"
                  >
                    {showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpenPasswordDialog(false)}
            color="inherit"
          >
            取消
          </Button>
          <Button 
            onClick={handlePasswordChange}
            variant="contained"
          >
            確認修改
          </Button>
        </DialogActions>
      </Dialog>

      {/* 添加 Snackbar 提示 */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          elevation={6}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Profile;