import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  CircularProgress
} from '@mui/material';
import { supabase } from '../../utils/supabase';
import { useAuthCheck } from '../../hooks/useAuthCheck';

export default function TaskList() {
    const { loading: authLoading, authorized, user } = useAuthCheck(null, false);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (authorized && user?.department) {
      fetchTasks();
    }
  }, [authorized, user]);

  const fetchTasks = async () => {
    try {
      const { data, error } = await supabase
        .from('tasks')
        .select('*')
        .eq('department', user.department)
        .order('created_at', { ascending: false });

      if (error) throw error;

      setTasks(data);
    } catch (error) {
      console.error('Error:', error);
      setError('獲取任務列表失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (taskId, newStatus) => {
    try {
      const { error: updateError } = await supabase
        .from('tasks')
        .update({ 
          status: newStatus,
          updated_at: new Date().toISOString()
        })
        .eq('id', taskId);

      if (updateError) throw updateError;

      setSuccess('任務狀態已更新');
      fetchTasks();
      setOpenDialog(false);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || '更新失敗');
    }
  };

  const getPriorityChip = (priority) => {
    const config = {
      high: { label: '高', color: 'error' },
      medium: { label: '中', color: 'warning' },
      low: { label: '低', color: 'success' }
    };
    return <Chip label={config[priority].label} color={config[priority].color} size="small" />;
  };

  const getStatusChip = (status) => {
    const config = {
      pending: { label: '待處理', color: 'warning' },
      in_progress: { label: '進行中', color: 'info' },
      completed: { label: '已完成', color: 'success' }
    };
    return <Chip label={config[status].label} color={config[status].color} size="small" />;
  };

  if (authLoading || loading) {
    return <CircularProgress />;
  }

  if (!authorized) return null;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          任務列表
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>任務標題</TableCell>
                <TableCell>優先級</TableCell>
                <TableCell>截止日期</TableCell>
                <TableCell>指派者</TableCell>
                <TableCell>狀態</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task) => (
                <TableRow key={task.id}>
                  <TableCell>{task.title}</TableCell>
                  <TableCell>{getPriorityChip(task.priority)}</TableCell>
                  <TableCell>{task.due_date || '無'}</TableCell>
                  <TableCell>{task.assigned_by}</TableCell>
                  <TableCell>{getStatusChip(task.status)}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      onClick={() => {
                        setSelectedTask(task);
                        setOpenDialog(true);
                      }}
                    >
                      查看
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>任務詳情</DialogTitle>
        <DialogContent>
          {selectedTask && (
            <Box sx={{ pt: 2 }}>
              <Typography variant="subtitle2">任務標題</Typography>
              <Typography paragraph>{selectedTask.title}</Typography>
              
              <Typography variant="subtitle2">任務描述</Typography>
              <Typography paragraph>{selectedTask.description || '無'}</Typography>
              
              <Typography variant="subtitle2">截止日期</Typography>
              <Typography paragraph>{selectedTask.due_date || '無'}</Typography>
              
              <Typography variant="subtitle2">優先級</Typography>
              <Box sx={{ mb: 2 }}>{getPriorityChip(selectedTask.priority)}</Box>
              
              <Typography variant="subtitle2">目前狀態</Typography>
              <Box sx={{ mb: 2 }}>{getStatusChip(selectedTask.status)}</Box>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>更新狀態</InputLabel>
                <Select
                  value={selectedTask.status}
                  label="更新狀態"
                  onChange={(e) => handleStatusChange(selectedTask.id, e.target.value)}
                >
                  <MenuItem value="pending">待處理</MenuItem>
                  <MenuItem value="in_progress">進行中</MenuItem>
                  <MenuItem value="completed">已完成</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>關閉</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
} 