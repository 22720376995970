import { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  const login = async (userData) => {
    try {
      setUser(userData);
      return { user: userData, error: null };
    } catch (error) {
      console.error('Login error:', error);
      return { user: null, error };
    }
  };

  const logout = async () => {
    try {
      setUser(null);
      setSession(null);
      localStorage.removeItem('user');
      return { error: null };
    } catch (error) {
      console.error('Logout error:', error);
      return { error };
    }
  };

  useEffect(() => {
    const initAuth = async () => {
      try {
        const savedUser = localStorage.getItem('user');
        if (savedUser) {
          setUser(JSON.parse(savedUser));
        }

        const { data: { session: currentSession } } = await supabase.auth.getSession();
        setSession(currentSession);

      } catch (error) {
        console.error('Auth initialization error:', error);
      } finally {
        setLoading(false);
      }
    };

    initAuth();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, newSession) => {
      console.log('Auth state changed:', _event, newSession);
      setSession(newSession);
    });

    return () => subscription.unsubscribe();
  }, []);

    useEffect(() => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        localStorage.removeItem('user');
      }
    }, [user]);

    return (
      <AuthContext.Provider value={{ user, session, loading, login, logout }}>
        {!loading && children}
      </AuthContext.Provider>
    );
}; 