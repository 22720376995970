import { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardMedia,
  useTheme,
  useMediaQuery,
  styled,
  FormHelperText,
  IconButton,
  LinearProgress
} from '@mui/material';
import { PhotoCamera, CloudUpload, Delete as DeleteIcon } from '@mui/icons-material';
import { supabase } from '../../utils/supabase';
import { motion, AnimatePresence } from 'framer-motion';

// 修改動畫背景組件
const AnimatedBackground = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  overflow: 'hidden',
  background: `linear-gradient(135deg, 
    ${theme.palette.background.default} 0%,
    ${theme.palette.primary.main}15 100%)`
}));

// 改進動畫元素組件
const AnimatedShape = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  background: `linear-gradient(135deg, 
    ${theme.palette.primary.main}10,
    ${theme.palette.primary.light}20)`,
  backdropFilter: 'blur(3px)',
  borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%',
  pointerEvents: 'none'
}));

// 優化主容器樣式
const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3, 2),
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4)
  }
}));

// 優化標題區域
const TitleSection = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -theme.spacing(2),
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '4px',
    background: `linear-gradient(90deg, 
      ${theme.palette.primary.main}, 
      ${theme.palette.primary.light})`,
    borderRadius: '2px'
  }
}));

// 自定義主卡片樣式
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  width: '100%',
  maxWidth: '800px',
  background: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 8px 32px rgba(31, 38, 135, 0.15)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4)
  }
}));

// 自定義步驟指示器
const StyledStepper = styled(Stepper)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(4),
  '& .MuiStepLabel-root': {
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& .MuiStepIcon-root': {
    width: 35,
    height: 35,
    color: theme.palette.grey[300],
    '&.Mui-active': {
      color: theme.palette.primary.main,
      filter: 'drop-shadow(0 0 8px ${theme.palette.primary.main}40)'
    },
    '&.Mui-completed': {
      color: theme.palette.success.main
    }
  },
  '& .MuiStepLabel-label': {
    marginTop: theme.spacing(1),
    fontSize: '0.875rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem'
    }
  }
}));

// 美化表單輸入框
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      }
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '2px'
      }
    }
  }
}));

// 美化傳按鈕
const StyledUploadButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '120px',
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius * 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  background: 'rgba(255, 255, 255, 0.9)',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: `${theme.palette.primary.main}08`,
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 20px ${theme.palette.primary.main}20`
  }
}));

// 美化預覽卡片
const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: `0 8px 24px ${theme.palette.primary.main}20`
  }
}));

// 美化刪除按鈕
const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(4px)',
  '&:hover': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}));

// 美化提示卡片
const StyledInfoPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
  color: theme.palette.primary.contrastText,
  boxShadow: `0 8px 32px ${theme.palette.primary.main}30`,
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(45deg, transparent 0%, rgba(255,255,255,0.1) 100%)'
  }
}));

// 添加班級映射
const CLASS_MAPPING = {
  '301': 'class301',
  '302': 'class302',
  '303': 'class303',
  '304': 'class304',
  '高三忠': 'class601',
  '高三孝': 'class602',
  '高三仁': 'class603',
  '高三愛': 'class604'
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const PhotoSubmission = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    student_id: '',
    student_name: '',
    class: '',
    photo_type: '',
    photo_file: null
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [preview, setPreview] = useState(null);
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

  const steps = ['填寫基本資料', '選擇照片類型', '上傳照片', '確認提交'];

  // 生成安全的檔案名稱
  const generateSafeFileName = (originalName, studentId, photoType) => {
    const timestamp = new Date().getTime();
    const fileExt = originalName.split('.').pop().toLowerCase();
    
    // 確保學號是安全的格式
    const safeStudentId = studentId.replace(/[^a-zA-Z0-9]/g, '');
    
    // 修改檔案名稱格式
    return `${safeStudentId}_${photoType}_${timestamp}.${fileExt}`;
  };

  const handleFileUpload = async (file) => {
    try {
      const safeFileName = generateSafeFileName(
        file.name,
        formData.student_id,
        formData.photo_type
      );

      // 使用映射取得安全的班級代碼
      const safeClass = CLASS_MAPPING[formData.class];
      if (!safeClass) {
        throw new Error('無效的班級選擇');
      }

      // 修改檔案路徑格式，添加 photos/ 前綴
      const filePath = `photos/${safeClass}/${safeFileName}`;

      console.log('Uploading file to path:', filePath);

      const { data, error } = await supabase.storage
        .from('yearbook')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: true
        });

      if (error) throw error;

      // 返回完整路徑
      return filePath;
    } catch (error) {
      console.error('File upload error:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);

      if (!files.length) {
        throw new Error('請選擇要上傳的照片');
      }

      // 批量上傳所有文件
      const uploadPromises = files.map(async (file, index) => {
        const safeFileName = generateSafeFileName(
          file.name,
          formData.student_id,
          formData.photo_type
        );

        const safeClass = CLASS_MAPPING[formData.class];
        if (!safeClass) {
          throw new Error('無效的班級選擇');
        }

        const filePath = `photos/${safeClass}/${safeFileName}`;
        
        try {
          const { data, error } = await supabase.storage
            .from('yearbook')
            .upload(filePath, file, {
              cacheControl: '3600',
              upsert: true
            });

          if (error) throw error;

          // 更新進度
          setUploadProgress(prev => ({
            ...prev,
            [index]: 100
          }));

          return filePath;
        } catch (error) {
          console.error(`File ${index + 1} upload error:`, error);
          throw error;
        }
      });

      const uploadedPaths = await Promise.all(uploadPromises);

      // 批量創建資料庫記錄
      const submissionData = uploadedPaths.map(photoUrl => ({
        student_id: formData.student_id,
        student_name: formData.student_name,
        class: CLASS_MAPPING[formData.class],
        photo_url: photoUrl,
        photo_type: formData.photo_type,
        status: 'pending',
        created_at: new Date().toISOString()
      }));

      const { data, error: dbError } = await supabase
        .from('photo_submissions')
        .insert(submissionData)
        .select();

      if (dbError) throw dbError;

      setSuccess(true);
      setActiveStep(steps.length);
    } catch (error) {
      console.error('提交錯誤:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // 處理照片預覽
  const handlePhotoChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 0) {
      // 驗證文件
      const validFiles = selectedFiles.filter(file => {
        const isValidType = ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);
        const isValidSize = file.size <= 5 * 1024 * 1024; // 5MB
        return isValidType && isValidSize;
      });

      setFiles(prevFiles => [...prevFiles, ...validFiles]);
      
      // 生成預覽
      validFiles.forEach(file => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviews(prev => [...prev, reader.result]);
        };
        reader.readAsDataURL(file);
      });

      setFormData(prev => ({
        ...prev,
        photo_file: validFiles
      }));
    }
  };

  // 移除照片
  const handleRemovePhoto = (index) => {
    setFiles(prev => prev.filter((_, i) => i !== index));
    setPreviews(prev => prev.filter((_, i) => i !== index));
  };

  // 修改驗證函數
  const validateStep = (step) => {
    switch (step) {
      case 0: // 基本資料
        return !!(formData.student_id && 
                 formData.student_name && 
                 formData.class);
      case 1: // 照片類型
        return !!formData.photo_type;
      case 2: // 照片上傳
        return files.length > 0;
      default:
        return true;
    }
  };

  // 修改下一步按鈕處理函數
  const handleNext = () => {
    if (validateStep(activeStep)) {
      setActiveStep(prev => prev + 1);
      setError(null);
    } else {
      // 顯示對應的錯誤訊息
      switch (activeStep) {
        case 0:
          setError('請填寫完整的基本資料');
          break;
        case 1:
          setError('請選擇照片類型');
          break;
        case 2:
          setError('請至少上傳一張照片');
          break;
      }
    }
  };

  return (
    <>
      <AnimatedBackground>
        {[...Array(4)].map((_, i) => (
          <AnimatedShape
            key={i}
            initial={{ 
              x: '-50%',
              y: '-50%',
              scale: 0.5,
              opacity: 0.3,
              rotate: 0
            }}
            animate={{ 
              rotate: 360,
              scale: [0.5, 0.8, 0.5],
              opacity: [0.3, 0.5, 0.3],
              borderRadius: [
                '30% 70% 70% 30% / 30% 30% 70% 70%',
                '70% 30% 30% 70% / 70% 70% 30% 30%',
                '30% 70% 70% 30% / 30% 30% 70% 70%'
              ]
            }}
            transition={{
              duration: 20 + i * 5,
              repeat: Infinity,
              ease: "linear"
            }}
            style={{
              width: `${400 + i * 100}px`,
              height: `${400 + i * 100}px`,
              left: `${25 + i * 10}%`,
              top: `${25 + i * 10}%`,
              filter: 'blur(2px)',
              zIndex: -1
            }}
          />
        ))}
        
        {/* 添加一些裝飾性元素 */}
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={`dot-${i}`}
            style={{
              position: 'absolute',
              width: '4px',
              height: '4px',
              background: theme.palette.primary.main,
              borderRadius: '50%',
              opacity: 0.2,
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`
            }}
            animate={{
              opacity: [0.2, 0.5, 0.2],
              scale: [1, 1.5, 1]
            }}
            transition={{
              duration: 3 + Math.random() * 2,
              repeat: Infinity,
              delay: Math.random() * 2
            }}
          />
        ))}
      </AnimatedBackground>

      <StyledContainer>
        <AnimatePresence mode="sync">
          <motion.div
            key={activeStep}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
          >
            <StyledPaper elevation={0}>
              <TitleSection>
                <Box
                  component="img"
                  src="/qq.png"
                  alt="Logo"
                  sx={{
                    height: 80,
                    width: 80,
                    mb: 2,
                    filter: 'drop-shadow(0 4px 8px rgba(0,0,0,0.1))',
                    animation: 'pulse 2s infinite',
                    '@keyframes pulse': {
                      '0%': { transform: 'scale(1)' },
                      '50%': { transform: 'scale(1.05)' },
                      '100%': { transform: 'scale(1)' }
                    }
                  }}
                />
                <Typography 
                  variant={isMobile ? "h5" : "h4"} 
                  component="h1"
                  sx={{ 
                    fontWeight: 700,
                    background: theme => `linear-gradient(45deg, 
                      ${theme.palette.primary.main}, 
                      ${theme.palette.primary.dark})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    mb: 1
                  }}
                >
                  畢業照片上傳系統
                </Typography>
                <Typography 
                  variant="subtitle1" 
                  color="text.secondary"
                  sx={{ maxWidth: 500, mx: 'auto' }}
                >
                  請依照指示上傳您的畢業照片，確保照片符合規格要求
                </Typography>
              </TitleSection>

              <StyledStepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </StyledStepper>

              {success ? (
                <Alert 
                  severity="success" 
                  sx={{ 
                    mt: 2,
                    p: 2,
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    上傳成功！
                  </Typography>
                  <Typography>
                    您的照片已成功上傳，畢聯會感謝您。
                  </Typography>
                </Alert>
              ) : (
                <form onSubmit={handleSubmit}>
                  {/* 基本資料表單 */}
                  <Box sx={{ 
                    display: activeStep === 0 ? 'flex' : 'none',
                    flexDirection: 'column',
                    gap: 2
                  }}>
                    <StyledTextField
                      required
                      fullWidth
                      label="學號"
                      value={formData.student_id}
                      onChange={(e) => setFormData({
                        ...formData,
                        student_id: e.target.value
                      })}
                      error={!formData.student_id && error}
                      helperText={!formData.student_id && error ? '請輸入學號' : ''}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                    <StyledTextField
                      required
                      fullWidth
                      label="姓名"
                      value={formData.student_name}
                      onChange={(e) => setFormData({
                        ...formData,
                        student_name: e.target.value
                      })}
                      error={!formData.student_name && error}
                      helperText={!formData.student_name && error ? '請輸入姓名' : ''}
                    />
                    <FormControl 
                      fullWidth 
                      required 
                      error={!formData.class && error}
                    >
                      <InputLabel>班級</InputLabel>
                      <Select
                        value={formData.class}
                        onChange={(e) => setFormData({
                          ...formData,
                          class: e.target.value
                        })}
                        label="班級"
                      >
                        <MenuItem value="301">301</MenuItem>
                        <MenuItem value="302">302</MenuItem>
                        <MenuItem value="303">303</MenuItem>
                        <MenuItem value="304">304</MenuItem>
                        <MenuItem value="高三忠">高三忠</MenuItem>
                        <MenuItem value="高三孝">高三孝</MenuItem>
                        <MenuItem value="高三仁">高三仁</MenuItem>
                        <MenuItem value="高三愛">高三愛</MenuItem>
                      </Select>
                      {!formData.class && error && (
                        <FormHelperText>請選擇班級</FormHelperText>
                      )}
                    </FormControl>
                  </Box>

                  {/* 照片類型選擇 */}
                  <Box sx={{ 
                    display: activeStep === 1 ? 'block' : 'none'
                  }}>
                    <FormControl 
                      fullWidth 
                      required
                      error={!formData.photo_type && error}
                    >
                      <InputLabel>照片類型</InputLabel>
                      <Select
                        value={formData.photo_type}
                        onChange={(e) => setFormData({
                          ...formData,
                          photo_type: e.target.value
                        })}
                        label="照片類型"
                      >
                        <MenuItem value="personal">個人照</MenuItem>
                        <MenuItem value="friends">朋友合照</MenuItem>
                        <MenuItem value="class">全班合照</MenuItem>
                      </Select>
                      {!formData.photo_type && error && (
                        <FormHelperText>請選擇照片類型</FormHelperText>
                      )}
                    </FormControl>
                  </Box>

                  {/* 照片上傳區域 */}
                  <Box sx={{ display: activeStep === 2 ? 'block' : 'none' }}>
                    <StyledUploadButton
                      component="label"
                      variant="outlined"
                    >
                      <CloudUpload sx={{ fontSize: 40 }} />
                      <Typography>
                        {files.length > 0 ? '新增更多照片' : '點擊或拖曳上傳照片'}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        可以選擇多張照片
                      </Typography>
                      <VisuallyHiddenInput
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handlePhotoChange}
                      />
                    </StyledUploadButton>

                    {/* 預覽區域 */}
                    <Box sx={{ 
                      mt: 2,
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                      gap: 2
                    }}>
                      {previews.map((preview, index) => (
                        <StyledCard key={index}>
                          <CardMedia
                            component="img"
                            image={preview}
                            alt={`照片預覽 ${index + 1}`}
                            sx={{ 
                              height: 200,
                              objectFit: 'cover'
                            }}
                          />
                          <StyledDeleteButton
                            size="small"
                            onClick={() => handleRemovePhoto(index)}
                            sx={{ 
                              bgcolor: 'background.paper',
                              '&:hover': { bgcolor: 'error.light' }
                            }}
                          >
                            <DeleteIcon />
                          </StyledDeleteButton>
                          {uploadProgress[index] !== undefined && (
                            <LinearProgress 
                              variant="determinate" 
                              value={uploadProgress[index]}
                              sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
                            />
                          )}
                        </StyledCard>
                      ))}
                    </Box>
                  </Box>

                  {/* 操作按鈕 */}
                  <Box sx={{ 
                    mt: 4, 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    gap: 2
                  }}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={() => {
                        setActiveStep(prev => prev - 1);
                        setError(null);
                      }}
                      variant="outlined"
                      sx={{ flex: 1 }}
                    >
                      上一步
                    </Button>
                    {activeStep === steps.length - 1 ? (
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={loading || !validateStep(activeStep)}
                        sx={{ flex: 1 }}
                      >
                        {loading ? '提交中...' : '完成'}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={!validateStep(activeStep)}
                        sx={{ flex: 1 }}
                      >
                        下一步
                      </Button>
                    )}
                  </Box>
                </form>
              )}
            </StyledPaper>
          </motion.div>
        </AnimatePresence>

        {/* 上傳提示 */}
        <AnimatePresence>
          {activeStep === 2 && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <StyledInfoPaper>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  上傳需知：
                </Typography>
                <Typography variant="body2">
                  • 檔案格式：JPG 或 PNG
                  <br />
                  • 檔案大小：不超過 5MB
                  <br />
                  • 建議解析度：至少 1200x1600 像素
                  <br />
                  • 檔案名稱：請勿使用特殊字元
                </Typography>
              </StyledInfoPaper>
            </motion.div>
          )}
        </AnimatePresence>

        {/* 錯誤提示 */}
        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <Alert 
                severity="error" 
                sx={{ 
                  mt: 2,
                  width: '100%',
                  maxWidth: '800px'
                }}
                onClose={() => setError(null)}
              >
                {error}
              </Alert>
            </motion.div>
          )}
        </AnimatePresence>
      </StyledContainer>
    </>
  );
};

export default PhotoSubmission; 