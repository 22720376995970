import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  IconButton,
  TextField,
  Alert,
  CircularProgress,
  Divider
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import { supabase } from '../../utils/supabase';
import { useAuthCheck } from '../../hooks/useAuthCheck';

export default function PostList() {
  const { loading: authLoading, authorized, user } = useAuthCheck(null, true);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState({});
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    console.log('Effect triggered');
    console.log('Authorized:', authorized);
    console.log('User:', user);
    
    if (authorized) {
      fetchPosts();
    }
  }, [authorized]);

  const fetchPosts = async () => {
    try {
      console.log('Fetching posts...');
      
      // 先只獲取 posts
      const { data: postsData, error: postsError } = await supabase
        .from('posts')
        .select('*')
        .order('created_at', { ascending: false });

      if (postsError) {
        console.error('Posts fetch error:', postsError);
        throw postsError;
      }

      console.log('Posts data:', postsData);

      // 如果成功獲取 posts，再獲取 likes 和 comments
      if (postsData) {
        const postsWithDetails = await Promise.all(
          postsData.map(async (post) => {
            const [likesResponse, commentsResponse] = await Promise.all([
              supabase
                .from('likes')
                .select('*')
                .eq('post_id', post.id),
              supabase
                .from('comments')
                .select('*')
                .eq('post_id', post.id)
            ]);

            return {
              ...post,
              likes: likesResponse.data || [],
              comments: commentsResponse.data || []
            };
          })
        );

        console.log('Posts with details:', postsWithDetails);
        setPosts(postsWithDetails);
      }

    } catch (error) {
      console.error('Error:', error);
      setError('獲取貼文失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async (postId) => {
    try {
      console.log('Handling like for post:', postId);
      console.log('Current user:', user);
      
      const { data: existingLike } = await supabase
        .from('likes')
        .select()
        .eq('post_id', postId)
        .eq('user_id', user.account)
        .single();

      if (existingLike) {
        await supabase
          .from('likes')
          .delete()
          .eq('post_id', postId)
          .eq('user_id', user.account);
      } else {
        await supabase
          .from('likes')
          .insert({
            post_id: postId,
            user_id: user.account
          });
      }

      fetchPosts();
    } catch (error) {
      console.error('Like error:', error);
      setError('操作失敗');
    }
  };

  const handleComment = async (postId) => {
    try {
      if (!newComment.trim()) return;

      await supabase
        .from('comments')
        .insert({
          post_id: postId,
          content: newComment,
          user_id: user.account,
          user_name: user.name
        });

      setNewComment('');
      fetchPosts();
    } catch (error) {
      console.error('Comment error:', error);
      setError('評論失敗');
    }
  };

  if (authLoading || loading) return <CircularProgress />;
  if (!authorized) return null;

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 2,
            borderRadius: 2,
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
          }} 
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}

      {posts.map((post) => (
        <Card 
          key={post.id} 
          sx={{ 
            mb: 4,
            borderRadius: 3,
            backgroundColor: '#ffffff',
            boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            transition: 'all 0.3s ease',
            '&:hover': {
              boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
              transform: 'translateY(-2px)'
            }
          }}
        >
          <CardContent sx={{ p: 3 }}>
            {/* 標題區域 */}
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ 
                fontWeight: 700,
                color: '#2c3e50',
                mb: 2,
                fontSize: '1.5rem',
                letterSpacing: '-0.5px'
              }}
            >
              {post.title}
            </Typography>

            {/* 作者和時間 */}
            <Box 
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                mb: 3
              }}
            >
              <Box 
                component="span" 
                sx={{ 
                  bgcolor: '#f0f7ff', 
                  color: '#1976d2',
                  px: 2, 
                  py: 0.75, 
                  borderRadius: 2,
                  fontSize: '0.875rem',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {post.author}
              </Box>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: '#64748b',
                  fontSize: '0.875rem'
                }}
              >
                {new Date(post.created_at).toLocaleString('zh-TW', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </Typography>
            </Box>

            {/* 內容區域 */}
            <Typography 
              variant="body1" 
              sx={{ 
                whiteSpace: 'pre-wrap',
                lineHeight: 1.8,
                color: '#334155',
                mb: 3,
                fontSize: '1rem',
                letterSpacing: '0.3px'
              }}
            >
              {post.content}
            </Typography>

            {/* 圖片區域 */}
            {post.image_url && (
              <Box 
                sx={{ 
                  mt: 2, 
                  mb: 3,
                  borderRadius: 2,
                  overflow: 'hidden',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
              >
                <CardMedia
                  component="img"
                  image={post.image_url}
                  alt={post.title}
                  sx={{ 
                    width: '100%',
                    maxHeight: 500,
                    objectFit: 'cover'
                  }}
                />
              </Box>
            )}
          </CardContent>

          <Divider sx={{ mx: 3, borderColor: '#e2e8f0' }} />

          {/* 點讚區域 */}
          <CardActions 
            sx={{ 
              px: 3, 
              py: 1.5,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <IconButton 
              onClick={() => handleLike(post.id)}
              sx={{ 
                mr: 1,
                color: post.likes.some(like => like.user_id === user.account) ? '#1976d2' : '#94a3b8',
                '&:hover': {
                  bgcolor: 'rgba(25, 118, 210, 0.04)'
                }
              }}
            >
              {post.likes.some(like => like.user_id === user.account) ? 
                <ThumbUpIcon /> : 
                <ThumbUpOutlinedIcon />
              }
            </IconButton>
            <Typography 
              variant="body2"
              sx={{ 
                color: '#64748b',
                fontWeight: 600,
                fontSize: '0.875rem'
              }}
            >
              {post.likes.length} 個讚
            </Typography>
          </CardActions>

          {/* 評論區域 */}
          <CardContent sx={{ px: 3, pt: 2, pb: 3 }}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontSize: '1.125rem',
                fontWeight: 700,
                color: '#334155',
                mb: 2
              }}
            >
              評論區
            </Typography>

            {/* 評論列表 */}
            <Box sx={{ mb: 3 }}>
              {post.comments.map((comment) => (
                <Box 
                  key={comment.id} 
                  sx={{ 
                    mb: 2,
                    p: 2,
                    bgcolor: '#f8fafc',
                    borderRadius: 2,
                    border: '1px solid #e2e8f0'
                  }}
                >
                  <Box 
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mb: 1
                    }}
                  >
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: '#1976d2',
                        fontWeight: 600,
                        fontSize: '0.875rem'
                      }}
                    >
                      {comment.user_name}
                    </Typography>
                    <Typography 
                      variant="caption"
                      sx={{ 
                        color: '#64748b',
                        fontSize: '0.75rem'
                      }}
                    >
                      {new Date(comment.created_at).toLocaleString('zh-TW', {
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </Typography>
                  </Box>
                  <Typography 
                    variant="body2"
                    sx={{ 
                      color: '#334155',
                      whiteSpace: 'pre-wrap',
                      lineHeight: 1.6
                    }}
                  >
                    {comment.content}
                  </Typography>
                </Box>
              ))}
            </Box>

            {/* 評論輸入框 */}
            <Box 
              sx={{ 
                display: 'flex', 
                gap: 2,
                alignItems: 'flex-start'
              }}
            >
              <TextField
                fullWidth
                multiline
                rows={2}
                placeholder="寫下你的評論..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    backgroundColor: '#f8fafc',
                    '&:hover': {
                      backgroundColor: '#f1f5f9'
                    },
                    '& fieldset': {
                      borderColor: '#e2e8f0'
                    },
                    '&:hover fieldset': {
                      borderColor: '#94a3b8'
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2'
                    }
                  }
                }}
              />
              <Button 
                variant="contained" 
                onClick={() => handleComment(post.id)}
                sx={{ 
                  px: 3,
                  py: 1.5,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 600,
                  backgroundColor: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#1565c0'
                  }
                }}
              >
                發送
              </Button>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Container>
  );
} 