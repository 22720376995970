// 班級代碼映射
export const CLASS_MAPPING = {
  '301': 'class301',
  '302': 'class302',
  '303': 'class303',
  '304': 'class304',
  '601': 'class601',
  '602': 'class602',
  '603': 'class603',
  '604': 'class604'
};

// 班級顯示名稱映射
export const getClassDisplayName = (classCode) => {
  // 移除 'class' 前綴
  const code = classCode.replace('class', '');
  
  // 高中部
  if (code.startsWith('6')) {
    const classNames = {
      '601': '高三忠',
      '602': '高三孝',
      '603': '高三仁',
      '604': '高三愛'
    };
    return classNames[code] || code;
  }
  
  // 國中部
  if (code.startsWith('3')) {
    return code;  // 直接返回 301, 302 等
  }
  
  return classCode;  // 預設返回原始代碼
}; 