import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Alert,
  Chip,
  Stack
} from '@mui/material';
import {
  Assignment as TaskIcon,
  Announcement as AnnouncementIcon,
  Event as EventIcon,
  People as PeopleIcon,
  TrendingUp as TrendingUpIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  Warning as WarningIcon,
  Home as HomeIcon,
  Feedback as FeedbackIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';
import { useAuthCheck } from '../hooks/useAuthCheck';
import CountdownTimer from '../components/CountdownTimer';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';

// 添加全局樣式
const globalStyles = {
  gradientText: {
    background: 'linear-gradient(45deg, #1976d2, #64b5f6)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },
  glassEffect: {
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(8px)',
    borderRadius: 2,
    border: '1px solid rgba(255, 255, 255, 0.3)'
  }
};

export default function Dashboard() {
  const navigate = useNavigate();
  const { loading: authLoading, authorized, user } = useAuthCheck();
  const [stats, setStats] = useState({
    totalTasks: 0,
    completedTasks: 0,
    pendingTasks: 0,
    upcomingEvents: 0
  });
  const [recentTasks, setRecentTasks] = useState([]);
  const [recentAnnouncements, setRecentAnnouncements] = useState([]);
  const [events, setEvents] = useState([
    {
      date: '2025-01-18',
      title: '學測',
      description: '114學年度學科能力測驗',
      color: 'primary'
    },
    {
      date: '2025-01-10',
      title: '畢聯會啟動',
      description: '拍攝團照',
      color: 'success'
    }
  ]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (authorized) {
      fetchDashboardData();
    }
  }, [authorized]);

  const fetchDashboardData = async () => {
    try {
      // 獲取任務統計
      const { data: tasksData, error: tasksError } = await supabase
        .from('tasks')
        .select('*')
        .eq('department', user.department);

      if (tasksError) throw tasksError;

      // 獲取最近公告
      const { data: announcements, error: announcementsError } = await supabase
        .from('announcements')
        .select('*')
        .order('created_at', { ascending: false })
        .limit(5);

      if (announcementsError) throw announcementsError;

      // 更新統計數據
      setStats({
        totalTasks: tasksData.length,
        completedTasks: tasksData.filter(task => task.status === 'completed').length,
        pendingTasks: tasksData.filter(task => task.status === 'pending').length,
        upcomingEvents: events.length
      });

      setRecentTasks(tasksData.slice(0, 5));
      setRecentAnnouncements(announcements);

    } catch (error) {
      console.error('Error:', error);
      setError('獲取資料失敗');
    } finally {
      setLoading(false);
    }
  };

  if (authLoading || loading) return <CircularProgress />;
  if (!authorized) return null;

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* 歡迎區塊 - 改進設計 */}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: { xs: 2, sm: 4 },  // 在手機上減少 padding
              mb: 3,
              background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
              color: 'white',
              borderRadius: 3,
              position: 'relative',
              overflow: 'hidden',
              boxShadow: '0 8px 32px rgba(26, 35, 126, 0.2)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box sx={{ position: 'relative', zIndex: 1 }}>
              <Typography 
                variant="h3" 
                gutterBottom 
                fontWeight="bold"
                sx={{
                  fontSize: { 
                    xs: '1.75rem',  // 手機上的字體大小
                    sm: '2.5rem',   // 平板的字體大小
                    md: '3rem'      // 桌面的字體大小
                  },
                  lineHeight: {
                    xs: 1.2,        // 手機上的行高
                    sm: 1.3
                  },
                  mb: { xs: 1, sm: 2 }  // 調整下方間距
                }}
              >
                歡迎回來，{user?.name || '使用者'}
              </Typography>
              <Typography 
                variant="h6" 
                sx={{ 
                  opacity: 0.9,
                  fontSize: { 
                    xs: '0.875rem',  // 手機上的字體大小
                    sm: '1rem',      // 平板的字體大小
                    md: '1.25rem'    // 桌面的字體大小
                  }
                }}
              >
                今天是 {new Date().toLocaleDateString('zh-TW', { 
                  weekday: 'long', 
                  year: 'numeric', 
                  month: 'long', 
                  day: 'numeric' 
                })}
              </Typography>
            </Box>
            {/* 裝飾性元素 */}
            <Box
              sx={{
                position: 'absolute',
                top: '-50%',
                right: { xs: '-20%', sm: '-10%' },  // 調整手機上的位置
                width: { xs: '80%', sm: '60%' },    // 調整手機上的大小
                height: '200%',
                background: 'linear-gradient(45deg, transparent, rgba(255,255,255,0.1))',
                transform: 'rotate(-20deg)',
                borderRadius: '50%'
              }}
            />
          </Paper>
        </Grid>


        {/* 統計卡片區域 - 添加標題 */}
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600, color: 'text.primary' }}>
            數據概覽
          </Typography>
        </Grid>
        
        {/* 統計卡片 - 保持原有樣式 */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="總任務數"
            value={stats.totalTasks}
            icon={<TaskIcon />}
            color="#1976d2"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="已完成任務"
            value={stats.completedTasks}
            icon={<CheckCircleIcon />}
            color="#2e7d32"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="待處理任務"
            value={stats.pendingTasks}
            icon={<WarningIcon />}
            color="#ed6c02"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="即將到來的活動"
            value={stats.upcomingEvents}
            icon={<EventIcon />}
            color="#9c27b0"
          />
        </Grid>

        {/* 主要內容區域 - 添加標題 */}
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ my: 3, fontWeight: 600, color: 'text.primary' }}>
            詳細資訊
          </Typography>
        </Grid>

        {/* 左側面板 */}
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            {/* 最近任務 */}
            <Grid item xs={12}>
              <DashboardCard
                title="最近任務"
                icon={<TaskIcon />}
                action={
                  <Button 
                    variant="contained"
                    size="small" 
                    onClick={() => navigate('/dashboard/tasks/list')}
                    sx={{ 
                      background: 'linear-gradient(45deg, #1976d2 30%, #2196f3 90%)',
                      boxShadow: '0 3px 5px 2px rgba(33, 150, 243, .3)'
                    }}
                  >
                    查看全部
                  </Button>
                }
              >
                <List>
                  {recentTasks.map((task) => (
                    <ListItem 
                      key={task.id}
                      sx={{
                        borderRadius: 2,
                        mb: 1,
                        '&:hover': {
                          bgcolor: 'rgba(0,0,0,0.02)'
                        }
                      }}
                    >
                      <ListItemIcon>
                        {task.status === 'completed' ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <ScheduleIcon color="warning" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={task.title}
                        secondary={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography variant="body2">
                              截止日期: {task.due_date || '無'}
                            </Typography>
                            <Chip 
                              label={task.priority || '一般'} 
                              size="small"
                              color={task.priority === '高' ? 'error' : 'default'}
                            />
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </DashboardCard>
            </Grid>

            {/* 活動時間軸 */}
            <Grid item xs={12}>
              <DashboardCard 
                title="重要活動" 
                icon={<EventIcon />}
                sx={{ minHeight: '400px' }}  // 確保有足夠的高度
              >
                <Timeline position="alternate">
                  {events.map((event, index) => (
                    <TimelineItem key={index}>
                      <TimelineOppositeContent color="text.secondary">
                        {new Date(event.date).toLocaleDateString('zh-TW')}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color={event.color || "primary"} />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography variant="h6" component="span">
                          {event.title}
                        </Typography>
                        <Typography>{event.description}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </DashboardCard>
            </Grid>
          </Grid> 
        </Grid>

        {/* 右側面板 */}
        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            {/* 最新公告 */}
            <DashboardCard
              title="最新公告"
              icon={<AnnouncementIcon />}
              action={
                <Button size="small" onClick={() => navigate('/dashboard/announcements')}>
                  查看全部
                </Button>
              }
            >
              <List>
                {recentAnnouncements.map((announcement) => (
                  <ListItem 
                    key={announcement.id}
                    sx={{
                      borderRadius: 2,
                      mb: 1,
                      '&:hover': {
                        bgcolor: 'rgba(0,0,0,0.02)'
                      }
                    }}
                  >
                    <ListItemIcon>
                      <AnnouncementIcon color="info" />
                    </ListItemIcon>
                    <ListItemText
                      primary={announcement.title}
                      secondary={new Date(announcement.created_at).toLocaleDateString()}
                    />
                  </ListItem>
                ))}
              </List>
            </DashboardCard>

            {/* 快速操作 */}
            <DashboardCard 
              title="快速操作" 
              icon={<TrendingUpIcon />}
              sx={{ 
                background: 'linear-gradient(135deg, #f5f5f5 0%, #ffffff 100%)',
                boxShadow: '0 8px 32px rgba(0,0,0,0.1)'
              }}
            >
              <Grid container spacing={2} sx={{ p: 1 }}>
                <Grid item xs={12} sm={6}>
                  <ActionButton
                    title="任務管理"
                    icon={<TaskIcon />}
                    onClick={() => navigate('/dashboard/tasks')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ActionButton
                    title="公告管理"
                    icon={<AnnouncementIcon />}
                    onClick={() => navigate('/dashboard/announcements')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ActionButton
                    title="活動管理"
                    icon={<EventIcon />}
                    onClick={() => navigate('/dashboard/activities')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ActionButton
                    title="成員管理"
                    icon={<PeopleIcon />}
                    onClick={() => navigate('/dashboard/members')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ActionButton
                    title="回到主頁"
                    icon={<HomeIcon />}
                    onClick={() => navigate('/')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ActionButton
                    title="意見箱"
                    icon={<FeedbackIcon />}
                    onClick={() => navigate('/dashboard/feedback')}
                  />
                </Grid>
              </Grid>
            </DashboardCard>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

// 修改統計卡片元件
function StatCard({ title, value, icon, color, trend }) {
  return (
    <Paper
      sx={{
        p: 3,
        height: '100%',
        borderRadius: 3,
        position: 'relative',
        overflow: 'hidden',
        transition: 'all 0.3s ease',
        background: `linear-gradient(135deg, ${color}08 0%, #ffffff 100%)`,
        border: '1px solid rgba(0,0,0,0.08)',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: `0 12px 24px ${color}20`
        }
      }}
    >
      <Box sx={{ position: 'relative', zIndex: 2 }}>
        {/* 標題和圖標 */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <Box
            sx={{
              p: 1,
              borderRadius: 2,
              bgcolor: `${color}15`,
              color: color,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {icon}
          </Box>
          <Typography 
            variant="subtitle1" 
            sx={{ 
              fontWeight: 600,
              color: 'text.secondary'
            }}
          >
            {title}
          </Typography>
        </Box>

        {/* 數值 */}
        <Typography 
          variant="h3" 
          sx={{ 
            fontWeight: 700,
            color: color,
            mb: 1
          }}
        >
          {value}
        </Typography>

        {/* 趨勢 */}
        {trend && (
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'text.secondary',
              display: 'flex',
              alignItems: 'center',
              gap: 0.5 
            }}
          >
            {trend}
          </Typography>
        )}
      </Box>
    </Paper>
  );
}

// 修改儀表板卡片元件
function DashboardCard({ title, icon, children, action }) {
  return (
    <Paper
      sx={{
        height: '100%',
        borderRadius: 3,
        overflow: 'hidden',
        transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 12px 28px rgba(0,0,0,0.1)'
        }
      }}
    >
      <Box
        sx={{
          p: 2.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1.5 
        }}>
          <Box sx={{ color: 'primary.main' }}>
            {icon}
          </Box>
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 600,
              color: 'text.primary'
            }}
          >
            {title}
          </Typography>
        </Box>
        {action && (
          <Box>
            {action}
          </Box>
        )}
      </Box>
      <Box sx={{ p: 2 }}>
        {children}
      </Box>
    </Paper>
  );
}

// 修改操作按鈕元件
function ActionButton({ title, icon, onClick }) {
  return (
    <Button
      fullWidth
      variant="outlined"
      startIcon={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'primary.main',
            transition: 'all 0.3s ease'
          }}
        >
          {icon}
        </Box>
      }
      onClick={onClick}
      sx={{
        p: 2,
        justifyContent: 'flex-start',
        borderRadius: 2,
        borderColor: 'divider',
        background: 'linear-gradient(45deg, #f5f5f5, #ffffff)',
        transition: 'all 0.3s ease',
        '&:hover': {
          borderColor: 'primary.main',
          bgcolor: 'primary.light',
          color: 'primary.main',
          transform: 'translateY(-3px)',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
        }
      }}
    >
      <Typography variant="button" sx={{ ml: 1, fontWeight: 600 }}>
        {title}
      </Typography>
    </Button>
  );
}