import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  CircularProgress
} from '@mui/material';
import { supabase } from '../../utils/supabase';
import { useAuthCheck } from '../../hooks/useAuthCheck';

export default function ArtSubmissions() {
  const { loading: authLoading, authorized, user } = useAuthCheck('美宣', true);
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewDialog, setViewDialog] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (authorized) {
      fetchSubmissions();
    }
  }, [authorized]);

  const fetchSubmissions = async () => {
    try {
      const { data, error } = await supabase
        .from('art_submissions')
        .select('*')
        .eq('department', '美宣')
        .order('created_at', { ascending: false });

      if (error) throw error;

      setSubmissions(data);
    } catch (error) {
      console.error('Error:', error);
      setError('獲取提交記錄失敗');
    } finally {
      setLoading(false);
    }
  };

  const getStatusChip = (status) => {
    const statusConfig = {
      pending: { label: '待審核', color: 'warning' },
      approved: { label: '已通過', color: 'success' },
      rejected: { label: '已駁回', color: 'error' }
    };
    const config = statusConfig[status] || statusConfig.pending;
    return <Chip label={config.label} color={config.color} size="small" />;
  };

  if (authLoading || loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          美宣作品提交記錄
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>作品標題</TableCell>
                <TableCell>提交時間</TableCell>
                <TableCell>狀態</TableCell>
                <TableCell>審核意見</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {submissions.map((submission) => (
                <TableRow key={submission.id}>
                  <TableCell>{submission.title}</TableCell>
                  <TableCell>
                    {new Date(submission.created_at).toLocaleString()}
                  </TableCell>
                  <TableCell>{getStatusChip(submission.status)}</TableCell>
                  <TableCell>{submission.review_comment || '-'}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      onClick={() => {
                        setSelectedSubmission(submission);
                        setViewDialog(true);
                      }}
                    >
                      查看
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* 查看詳情對話框 */}
      <Dialog 
        open={viewDialog} 
        onClose={() => setViewDialog(false)} 
        maxWidth="md" 
        fullWidth
      >
        <DialogTitle>作品詳情</DialogTitle>
        <DialogContent>
          {selectedSubmission && (
            <Box sx={{ pt: 2 }}>
              <Typography variant="subtitle2">作品標題</Typography>
              <Typography paragraph>{selectedSubmission.title}</Typography>
              
              <Typography variant="subtitle2">作品描述</Typography>
              <Typography paragraph>{selectedSubmission.description || '無'}</Typography>
              
              <Typography variant="subtitle2">作品圖片</Typography>
              <Box sx={{ mt: 1, mb: 2 }}>
                <img
                  src={selectedSubmission.image_url}
                  alt={selectedSubmission.title}
                  style={{ maxWidth: '100%', maxHeight: '500px', objectFit: 'contain' }}
                />
              </Box>

              {selectedSubmission.status !== 'pending' && (
                <>
                  <Typography variant="subtitle2">審核意見</Typography>
                  <Typography paragraph>{selectedSubmission.review_comment || '無'}</Typography>
                  
                  <Typography variant="subtitle2">審核人</Typography>
                  <Typography paragraph>{selectedSubmission.reviewer_name}</Typography>
                  
                  <Typography variant="subtitle2">審核時間</Typography>
                  <Typography>
                    {new Date(selectedSubmission.reviewed_at).toLocaleString()}
                  </Typography>
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewDialog(false)}>關閉</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
} 