import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from '@mui/material';
import { supabase } from '../../utils/supabase';
import { useAuthCheck } from '../../hooks/useAuthCheck';

export default function TaskAssignment() {
  const { loading: authLoading, authorized, user } = useAuthCheck(null, true, ['president']);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    department: '',
    due_date: '',
    priority: 'medium'
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const departments = ['文務', '美宣', '活動', '影器'];
  const priorities = [
    { value: 'high', label: '高' },
    { value: 'medium', label: '中' },
    { value: 'low', label: '低' }
  ];

  if (authLoading) {
    return <CircularProgress />;
  }

  if (!authorized) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitting(true);
      setError(null);

      if (!formData.title || !formData.department) {
        throw new Error('請填寫必填欄位');
      }

      const { error: submitError } = await supabase
        .from('tasks')
        .insert([{
          ...formData,
          assigned_by: user.name,
          status: 'pending'
        }]);

      if (submitError) throw submitError;

      setSuccess('任務已指派');
      setFormData({
        title: '',
        description: '',
        department: '',
        due_date: '',
        priority: 'medium'
      });
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || '指派失敗');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          指派任務
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            required
            label="任務標題"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          
          <TextField
            label="任務描述"
            multiline
            rows={4}
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />

          <FormControl required>
            <InputLabel>指派部門</InputLabel>
            <Select
              value={formData.department}
              label="指派部門"
              onChange={(e) => setFormData({ ...formData, department: e.target.value })}
            >
              {departments.map((dept) => (
                <MenuItem key={dept} value={dept}>{dept}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            type="date"
            label="截止日期"
            value={formData.due_date}
            onChange={(e) => setFormData({ ...formData, due_date: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />

          <FormControl>
            <InputLabel>優先級</InputLabel>
            <Select
              value={formData.priority}
              label="優先級"
              onChange={(e) => setFormData({ ...formData, priority: e.target.value })}
            >
              {priorities.map((priority) => (
                <MenuItem key={priority.value} value={priority.value}>
                  {priority.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button 
            type="submit" 
            variant="contained" 
            disabled={submitting}
            sx={{ mt: 2 }}
          >
            {submitting ? '指派中...' : '指派任務'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
} 