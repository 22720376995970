import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  CircularProgress,
  ImageList,
  ImageListItem
} from '@mui/material';
import { supabase } from '../../utils/supabase';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function ArtReview() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [reviewComment, setReviewComment] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (user?.role !== 'president') {
      navigate('/dashboard');
      return;
    }
    fetchSubmissions();
  }, [user]);

  const fetchSubmissions = async () => {
    try {
      const { data, error } = await supabase
        .from('art_submissions')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;

      setSubmissions(data);
    } catch (error) {
      console.error('Error:', error);
      setError('獲取提交記錄失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleReview = async (status) => {
    try {
      if (!reviewComment && status === 'rejected') {
        setError('駁回時必須填寫審核意見');
        return;
      }

      if (!user) {
        throw new Error('未登入或用戶資料未載入');
      }

      const updateData = {
        status,
        review_comment: reviewComment,
        reviewed_at: new Date().toISOString(),
        reviewed_by: user.name,
        reviewer_name: user.name
      };

      const { error } = await supabase
        .from('art_submissions')
        .update(updateData)
        .eq('id', selectedSubmission.id);

      if (error) throw error;

      setSuccess(`作品已${status === 'approved' ? '通過' : '駁回'}`);
      setOpenDialog(false);
      setReviewComment('');
      fetchSubmissions();
    } catch (error) {
      console.error('Review error:', error);
      setError(error.message || '審核失敗');
    }
  };

  const getStatusChip = (status) => {
    const statusConfig = {
      pending: { label: '待審核', color: 'warning' },
      approved: { label: '已通過', color: 'success' },
      rejected: { label: '已駁回', color: 'error' }
    };
    const config = statusConfig[status] || statusConfig.pending;
    return <Chip label={config.label} color={config.color} size="small" />;
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          美宣作品審核
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>作品標題</TableCell>
                <TableCell>提交者</TableCell>
                <TableCell>提交時間</TableCell>
                <TableCell>狀態</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {submissions.map((submission) => (
                <TableRow key={submission.id}>
                  <TableCell>{submission.title}</TableCell>
                  <TableCell>{submission.submitted_by}</TableCell>
                  <TableCell>
                    {new Date(submission.created_at).toLocaleString()}
                  </TableCell>
                  <TableCell>{getStatusChip(submission.status)}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      onClick={() => {
                        setSelectedSubmission(submission);
                        setViewDialog(true);
                      }}
                    >
                      查看
                    </Button>
                    {submission.status === 'pending' && (
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                          setSelectedSubmission(submission);
                          setOpenDialog(true);
                        }}
                      >
                        審核
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* 查看詳情對話框 */}
      <Dialog 
        open={viewDialog} 
        onClose={() => setViewDialog(false)} 
        maxWidth="md" 
        fullWidth
      >
        <DialogTitle>作品詳情</DialogTitle>
        <DialogContent>
          {selectedSubmission && (
            <Box sx={{ pt: 2 }}>
              <Typography variant="subtitle2">作品標題</Typography>
              <Typography paragraph>{selectedSubmission.title}</Typography>
              
              <Typography variant="subtitle2">作品描述</Typography>
              <Typography paragraph>{selectedSubmission.description || '無'}</Typography>
              
              <Typography variant="subtitle2">作品圖片</Typography>
              <Box sx={{ mt: 1, mb: 2 }}>
                <img
                  src={selectedSubmission.image_url}
                  alt={selectedSubmission.title}
                  style={{ maxWidth: '100%', maxHeight: '500px', objectFit: 'contain' }}
                />
              </Box>

              {selectedSubmission.status !== 'pending' && (
                <>
                  <Typography variant="subtitle2">審核意見</Typography>
                  <Typography paragraph>{selectedSubmission.review_comment || '無'}</Typography>
                  
                  <Typography variant="subtitle2">審核人</Typography>
                  <Typography paragraph>{selectedSubmission.reviewer_name}</Typography>
                  
                  <Typography variant="subtitle2">審核時間</Typography>
                  <Typography>
                    {new Date(selectedSubmission.reviewed_at).toLocaleString()}
                  </Typography>
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewDialog(false)}>關閉</Button>
        </DialogActions>
      </Dialog>

      {/* 審核對話框 */}
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)} 
        maxWidth="md" 
        fullWidth
      >
        <DialogTitle>審核作品</DialogTitle>
        <DialogContent>
          {selectedSubmission && (
            <Box sx={{ pt: 2 }}>
              <img
                src={selectedSubmission.image_url}
                alt={selectedSubmission.title}
                style={{ 
                  width: '100%', 
                  maxHeight: '400px', 
                  objectFit: 'contain',
                  marginBottom: '20px'
                }}
              />
              <TextField
                fullWidth
                multiline
                rows={4}
                label="審核意見"
                value={reviewComment}
                onChange={(e) => setReviewComment(e.target.value)}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>取消</Button>
          <Button 
            color="error" 
            onClick={() => handleReview('rejected')}
          >
            駁回
          </Button>
          <Button 
            color="primary" 
            onClick={() => handleReview('approved')}
          >
            通過
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
} 