import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert
} from '@mui/material';
import { supabase } from '../../utils/supabase';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function MeetingReview() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [reviewComment, setReviewComment] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (user?.role !== 'president') {
      navigate('/dashboard');
      return;
    }
    fetchSubmissions();
  }, [user]);

  const fetchSubmissions = async () => {
    try {
      const { data, error } = await supabase
        .from('meeting_minutes')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;

      const formattedData = data.map(submission => ({
        ...submission,
        submitted_by: {
          name: submission.submitted_by,
          department: submission.department
        }
      }));

      setSubmissions(formattedData);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleReview = async (status) => {
    try {
      if (!reviewComment && status === 'rejected') {
        setError('駁回時必須填寫審核意見');
        return;
      }

      if (!user) {
        throw new Error('未登入或用戶資料未載入');
      }

      const updateData = {
        status,
        review_comment: reviewComment,
        reviewed_at: new Date().toISOString(),
        reviewed_by: user.name,
        reviewer_name: user.name
      };

      console.log('Updating with data:', updateData);

      const { error } = await supabase
        .from('meeting_minutes')
        .update(updateData)
        .eq('id', selectedSubmission.id);

      if (error) throw error;

      setSuccess(`會議記錄已${status === 'approved' ? '通過' : '駁回'}`);
      setOpenDialog(false);
      setReviewComment('');
      fetchSubmissions();
    } catch (error) {
      console.error('Review error:', error);
      setError(error.message || '審核失敗');
    }
  };

  const getStatusChip = (status) => {
    const statusConfig = {
      pending: { label: '待審核', color: 'warning' },
      approved: { label: '已通過', color: 'success' },
      rejected: { label: '已駁回', color: 'error' }
    };
    const config = statusConfig[status] || statusConfig.pending;
    return <Chip label={config.label} color={config.color} size="small" />;
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          會議記錄審核
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>會議日期</TableCell>
                <TableCell>提交者</TableCell>
                <TableCell>部門</TableCell>
                <TableCell>狀態</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {submissions.map((submission) => (
                <TableRow key={submission.id}>
                  <TableCell>{submission.date}</TableCell>
                  <TableCell>{submission.submitted_by.name}</TableCell>
                  <TableCell>{submission.submitted_by.department}</TableCell>
                  <TableCell>{getStatusChip(submission.status)}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      onClick={() => {
                        setSelectedSubmission(submission);
                        setViewDialog(true);
                      }}
                    >
                      查看
                    </Button>
                    {submission.status === 'pending' && (
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                          setSelectedSubmission(submission);
                          setOpenDialog(true);
                        }}
                      >
                        審核
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* 查看詳情對話框 */}
      <Dialog open={viewDialog} onClose={() => setViewDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>會議記錄詳情</DialogTitle>
        <DialogContent>
          {selectedSubmission && (
            <Box sx={{ pt: 2 }}>
              <Typography variant="subtitle2">會議日期</Typography>
              <Typography paragraph>{selectedSubmission.date}</Typography>
              
              <Typography variant="subtitle2">出席人員</Typography>
              <Typography paragraph>{selectedSubmission.attendees}</Typography>
              
              <Typography variant="subtitle2">會議內容</Typography>
              <Typography paragraph>{selectedSubmission.content}</Typography>
              
              <Typography variant="subtitle2">決議事項</Typography>
              <Typography paragraph>{selectedSubmission.decisions}</Typography>
              
              <Typography variant="subtitle2">下次會議日期</Typography>
              <Typography>{selectedSubmission.next_meeting || '未設定'}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewDialog(false)}>關閉</Button>
        </DialogActions>
      </Dialog>

      {/* 審核對話框 */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>審核會議記錄</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="審核意見"
            value={reviewComment}
            onChange={(e) => setReviewComment(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>取消</Button>
          <Button 
            color="error" 
            onClick={() => handleReview('rejected')}
          >
            駁回
          </Button>
          <Button 
            color="primary" 
            onClick={() => handleReview('approved')}
          >
            通過
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
} 