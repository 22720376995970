import { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Alert,
  Box,
  Chip
} from '@mui/material';
import { supabase } from '../utils/supabase';

export default function DepartmentManagement() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  // 部門選項
  const departments = ['影器', '活動', '美宣', '公關', '文務'];
  
  // 檢查是否為會長
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user?.role !== 'president') {
      window.location.href = '/'; // 非會長導回首頁
    }
  }, []);

  // 獲取所有用戶
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .order('name');
        
      if (error) throw error;
      setUsers(data);
    } catch (error) {
      setError('獲取用戶資料失敗');
      console.error('Error:', error);
    }
  };

  // 更新用戶部門
  const handleDepartmentChange = async (userId, newDepartment) => {
    try {
      const { error } = await supabase
        .from('users')
        .update({ department: newDepartment })
        .eq('id', userId);

      if (error) throw error;
      
      // 更新本地狀態
      setUsers(users.map(user => 
        user.id === userId 
          ? { ...user, department: newDepartment }
          : user
      ));
      
      setSuccess('更新成功');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      setError('更新失敗');
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          組別管理
        </Typography>
        
        {/* 狀態提示 */}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>姓名</TableCell>
                <TableCell>學號</TableCell>
                <TableCell>班級</TableCell>
                <TableCell>目前組別</TableCell>
                <TableCell>設定組別</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.student_id}</TableCell>
                  <TableCell>{user.class}</TableCell>
                  <TableCell>
                    {user.department ? (
                      <Chip 
                        label={user.department}
                        color="primary"
                        size="small"
                      />
                    ) : (
                      <Typography color="text.secondary">
                        未設定
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Select
                      value={user.department || ''}
                      onChange={(e) => handleDepartmentChange(user.id, e.target.value)}
                      size="small"
                      sx={{ minWidth: 120 }}
                    >
                      <MenuItem value="">
                        <em>未設定</em>
                      </MenuItem>
                      {departments.map((dept) => (
                        <MenuItem key={dept} value={dept}>
                          {dept}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* 說明區塊 */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="subtitle2" color="text.secondary">
          說明：
        </Typography>
        <Typography variant="body2" color="text.secondary">
          • 此頁面僅供會長設定各成員的組別<br />
          • 變更將即時生效<br />
          • 組別設定會影響成員的權限及可見功能
        </Typography>
      </Box>
    </Container>
  );
} 