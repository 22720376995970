import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export const useAuthCheck = (requiredDepartment = null, allowPresident = true) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      console.log('=== Auth Check ===');
      console.log('Current user:', user);
      console.log('Required department:', requiredDepartment);
      console.log('Allow president:', allowPresident);

      // 等待用戶數據載入
      if (!user) {
        console.log('Waiting for user data...');
        setLoading(true);
        setAuthorized(false);
        return;
      }

      // 檢查權限
      let hasAccess = false;
      
      // 如果沒有指定部門要求，則所有登入用戶都有權限
      if (!requiredDepartment) {
        hasAccess = true;
      } else {
        // 否則檢查部門和會長權限
        hasAccess = (user.department === requiredDepartment) ||
                   (allowPresident && user.role === 'president');
      }

      console.log('Access check:', {
        department: user.department,
        role: user.role,
        hasAccess
      });

      if (!hasAccess) {
        console.log('Access denied');
        navigate('/dashboard');
        return;
      }

      setAuthorized(true);
      setLoading(false);
    };

    checkAuth();
  }, [user, requiredDepartment, allowPresident, navigate]);

  return { loading, authorized, user };
}; 