import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { supabase } from '../../utils/supabase';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useAuthCheck } from '../../hooks/useAuthCheck';

export default function MeetingSubmissions() {
  const { loading, authorized, user } = useAuthCheck('文務', true);
  const [submissions, setSubmissions] = useState([]);
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [formData, setFormData] = useState({
    date: '',
    attendees: '',
    content: '',
    decisions: '',
    next_meeting: ''
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (authorized) {
      fetchSubmissions();
    }
  }, [authorized]);

  const fetchSubmissions = async () => {
    try {
      console.log('Fetching submissions...');
      
      const { data, error } = await supabase
        .from('meeting_minutes')
        .select('*')
        .eq('department', '文務')
        .order('date', { ascending: false });

      if (error) {
        console.error('Fetch error:', error);
        throw error;
      }

      const formattedData = data.map(submission => ({
        ...submission,
        submitted_by: {
          name: submission.submitted_by
        }
      }));

      console.log('Fetched data:', formattedData);
      setSubmissions(formattedData);
    } catch (error) {
      console.error('Error fetching submissions:', error);
    }
  };

  const getStatusChip = (status) => {
    const statusConfig = {
      pending: { label: '待審核', color: 'warning' },
      approved: { label: '已通過', color: 'success' },
      rejected: { label: '已駁回', color: 'error' }
    };
    const config = statusConfig[status] || statusConfig.pending;
    return <Chip label={config.label} color={config.color} size="small" />;
  };

  const handleEdit = async () => {
    try {
      const { error } = await supabase
        .from('meeting_minutes')
        .update({
          date: formData.date,
          attendees: formData.attendees,
          content: formData.content,
          decisions: formData.decisions,
          next_meeting: formData.next_meeting
        })
        .eq('id', selectedSubmission.id);

      if (error) throw error;

      setSuccess('會議記錄已更新');
      setEditDialog(false);
      fetchSubmissions();
    } catch (error) {
      setError('更新失敗');
      console.error('Error:', error);
    }
  };

  const handleDelete = async () => {
    try {
      const { error } = await supabase
        .from('meeting_minutes')
        .delete()
        .eq('id', selectedSubmission.id);

      if (error) throw error;

      setSuccess('會議記錄已刪除');
      setDeleteDialog(false);
      fetchSubmissions();
    } catch (error) {
      setError('刪除失敗');
      console.error('Error:', error);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6">檢查權限中...</Typography>
            <Typography color="textSecondary">
              {user ? `當前用戶: ${user.name} (${user.department})` : '載入用戶資料...'}
            </Typography>
          </Box>
        </Paper>
      </Container>
    );
  }

  if (!authorized) return null;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          會議記錄提交紀錄
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>會議日期</TableCell>
                <TableCell>提交者</TableCell>
                <TableCell>狀態</TableCell>
                <TableCell>審核意見</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {submissions.map((submission) => (
                <TableRow key={submission.id}>
                  <TableCell>{submission.date}</TableCell>
                  <TableCell>{submission.submitted_by.name}</TableCell>
                  <TableCell>{getStatusChip(submission.status)}</TableCell>
                  <TableCell>{submission.review_comment}</TableCell>
                  <TableCell>
                    <IconButton 
                      color="primary" 
                      onClick={() => {
                        setSelectedSubmission(submission);
                        setFormData({
                          date: submission.date,
                          attendees: submission.attendees,
                          content: submission.content,
                          decisions: submission.decisions,
                          next_meeting: submission.next_meeting || ''
                        });
                        setEditDialog(true);
                      }}
                      disabled={submission.status !== 'pending'}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton 
                      color="error"
                      onClick={() => {
                        setSelectedSubmission(submission);
                        setDeleteDialog(true);
                      }}
                      disabled={submission.status !== 'pending'}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* 編輯對話框 */}
      <Dialog open={editDialog} onClose={() => setEditDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>編輯會議記錄</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              fullWidth
              label="會議日期"
              type="date"
              value={formData.date}
              onChange={(e) => setFormData({ ...formData, date: e.target.value })}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              label="出席人員"
              multiline
              rows={2}
              value={formData.attendees}
              onChange={(e) => setFormData({ ...formData, attendees: e.target.value })}
            />
            <TextField
              fullWidth
              label="會議內容"
              multiline
              rows={4}
              value={formData.content}
              onChange={(e) => setFormData({ ...formData, content: e.target.value })}
            />
            <TextField
              fullWidth
              label="決議事項"
              multiline
              rows={4}
              value={formData.decisions}
              onChange={(e) => setFormData({ ...formData, decisions: e.target.value })}
            />
            <TextField
              fullWidth
              label="下次會議日期"
              type="date"
              value={formData.next_meeting}
              onChange={(e) => setFormData({ ...formData, next_meeting: e.target.value })}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialog(false)}>取消</Button>
          <Button color="primary" onClick={handleEdit}>
            保存
          </Button>
        </DialogActions>
      </Dialog>

      {/* 刪除確認對話框 */}
      <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
        <DialogTitle>確認刪除</DialogTitle>
        <DialogContent>
          <Typography>
            確定要刪除這條會議記錄嗎？此操作無法撤銷。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)}>取消</Button>
          <Button color="error" onClick={handleDelete}>
            刪除
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
} 