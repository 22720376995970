import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Alert,
  CircularProgress
} from '@mui/material';
import { supabase } from '../../utils/supabase';
import { useAuth } from '../../contexts/AuthContext';
import { useAuthCheck } from '../../hooks/useAuthCheck';

export default function ActivitySubmission() {
  const { loading: authLoading, authorized, user } = useAuthCheck('活動', true);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    location: '',
    budget: '',
    participants: ''
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  if (authLoading) {
    return <CircularProgress />;
  }

  if (!authorized) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitting(true);
      setError(null);

      // 檢查必填欄位
      if (!formData.title || !formData.date || !formData.location) {
        throw new Error('請填寫所有必填欄位');
      }

      const submitData = {
        ...formData,
        department: '活動',
        submitted_by: user.name,
        submitted_at: new Date().toISOString(),
        status: 'pending'
      };

      const { error: submitError } = await supabase
        .from('activity_submissions')
        .insert([submitData]);

      if (submitError) throw submitError;

      setSuccess('活動提案已提交，等待審核');
      setFormData({
        title: '',
        description: '',
        date: '',
        location: '',
        budget: '',
        participants: ''
      });
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || '提交失敗');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          提交活動提案
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            required
            label="活動名稱"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          
          <TextField
            label="活動描述"
            multiline
            rows={4}
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />

          <TextField
            required
            type="date"
            label="活動日期"
            value={formData.date}
            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            required
            label="活動地點"
            value={formData.location}
            onChange={(e) => setFormData({ ...formData, location: e.target.value })}
          />

          <TextField
            label="預算"
            value={formData.budget}
            onChange={(e) => setFormData({ ...formData, budget: e.target.value })}
            helperText="請輸入預計花費金額"
          />

          <TextField
            label="預計參與人數"
            value={formData.participants}
            onChange={(e) => setFormData({ ...formData, participants: e.target.value })}
          />

          <Button 
            type="submit" 
            variant="contained" 
            disabled={submitting}
            sx={{ mt: 2 }}
          >
            {submitting ? '提交中...' : '提交'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
} 