import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper
} from '@mui/material';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import ShareIcon from '@mui/icons-material/Share';
import IosShareIcon from '@mui/icons-material/IosShare';

const steps = [
  {
    label: '點擊分享按鈕',
    description: (
      <Box>
        <Typography variant="body2" paragraph>
          在瀏覽器底部找到並點擊分享按鈕 <IosShareIcon fontSize="small" sx={{ verticalAlign: 'middle', color: '#007AFF' }} />
        </Typography>
        <Paper variant="outlined" sx={{ p: 1, mb: 2 }}>
          <img 
            src="/pwa-tutorial/step1.jpg" 
            alt="點擊分享按鈕"
            style={{ width: '100%', maxWidth: 300, display: 'block', margin: '0 auto' }}
          />
        </Paper>
      </Box>
    ),
  },
  {
    label: '選擇「加入主畫面」',
    description: (
      <Box>
        <Typography variant="body2" paragraph>
          在彈出的選單中，向上滑動並找到「加入主畫面」選項
        </Typography>
        <Paper variant="outlined" sx={{ p: 1, mb: 2 }}>
          <img 
            src="/pwa-tutorial/step2.jpg" 
            alt="選擇加入主畫面"
            style={{ width: '100%', maxWidth: 300, display: 'block', margin: '0 auto' }}
          />
        </Paper>
      </Box>
    ),
  },
  {
    label: '確認新增',
    description: (
      <Box>
        <Typography variant="body2" paragraph>
          點擊「新增」按鈕完成安裝
        </Typography>
        <Paper variant="outlined" sx={{ p: 1, mb: 2 }}>
          <img 
            src="/pwa-tutorial/step3.jpg" 
            alt="確認新增"
            style={{ width: '100%', maxWidth: 300, display: 'block', margin: '0 auto' }}
          />
        </Paper>
      </Box>
    ),
  },
];

export default function PWAPrompt({ 
  open = false, 
  onClose = () => {} 
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [isIOSSafari, setIsIOSSafari] = useState(false);

  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setIsIOSSafari(isIOS && isSafari);
  }, []);

  const handleClose = () => {
    onClose?.();
    localStorage.setItem('pwaPromptShown', 'true');
  };

  if (!isIOSSafari) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Box display="flex" alignItems="center" gap={1}>
          <AddToHomeScreenIcon color="primary" />
          <Typography variant="h6">
            將應用程式加入主畫面
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          將學生會管理系統加入主畫面，享受更好的使用體驗：
        </Typography>
        <Box sx={{ pl: 2, mb: 3 }}>
          <Typography variant="body1" component="div">
            • 快速存取，一鍵開啟<br />
            • 離線使用核心功能<br />
            • 更流暢的操作體驗<br />
            • 節省行動數據用量<br />
            • 獲得原生應用程式體驗
          </Typography>
        </Box>
        
        <Typography variant="h6" sx={{ mb: 2 }}>
          安裝步驟：
        </Typography>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                {step.description}
                <Box sx={{ mb: 2 }}>
                  <Button
                    variant="contained"
                    onClick={() => setActiveStep(index + 1)}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? '完成' : '下一步'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={() => setActiveStep(index - 1)}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    上一步
                  </Button>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={handleClose} color="inherit">
          稍後再說
        </Button>
        <Button 
          variant="contained"
          onClick={handleClose}
          startIcon={<AddToHomeScreenIcon />}
        >
          我知道了
        </Button>
      </DialogActions>
    </Dialog>
  );
} 