import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia,
  Button,
  Stack,
  Divider,
  Paper,
  Fade,
  Slide,
  Zoom,
  IconButton,
  useTheme,
  useMediaQuery,
  Avatar,
  Chip,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Skeleton,
  LinearProgress,
  Backdrop,
  Grow,
  Fab
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { supabase } from '../../utils/supabase';
import {
  Instagram as InstagramIcon,
  Facebook as FacebookIcon,
  YouTube as YouTubeIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Announcement as AnnouncementIcon,
  AccessTime as AccessTimeIcon,
  School as SchoolIcon,
  EmojiEvents as EmojiEventsIcon,
  PhotoCamera as PhotoCameraIcon,
  Chat as ChatIcon,
  People as PeopleIcon
} from '@mui/icons-material';
import CountdownTimer from '../../components/CountdownTimer';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import { DataGrid } from '@mui/x-data-grid';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";

const glassEffect = {
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)'
};

export default function Home() {
  const navigate = useNavigate();
  const [recentAnnouncements, setRecentAnnouncements] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeSection, setActiveSection] = useState(0);

  useEffect(() => {
    fetchRecentAnnouncements();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.pageYOffset > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.animate-section');
      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.75) {
          setActiveSection(index);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1500);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const fetchRecentAnnouncements = async () => {
    try {
      const { data, error } = await supabase
        .from('announcements')
        .select(`
          *,
          users:created_by (name)
        `)
        .eq('visibility', 'external')  // 只顯示外部公告
        .order('created_at', { ascending: false })
        .limit(3);

      if (error) throw error;
      setRecentAnnouncements(data || []);
    } catch (error) {
      console.error('Error fetching announcements:', error);
    }
  };

  // 活動時間軸數據
  const timelineEvents = [
    {
      title: '學測',
      date: '2024/01/18',
      description: '114學年度學科能力測驗',
      color: '#1a237e'
    },
    {
      title: '統測',
      date: '2024/04/26',
      description: '114學年度統一入學測驗',
      color: '#0d47a1'
    },
    {
      title: '畢業典禮',
      date: '？？？？？',
      description: '畢業典禮',
      color: '#2196f3'
    }
  ];

  // 統計數據
  const statsData = [
    { name: '學測', value: 120 },
    { name: '統測', value: 80 },
    { name: '會考', value: 150 },
    { name: '分科', value: 90 },
  ];

  const features = [
    {
      title: '照片上傳',
      description: '輕鬆上傳並分享你的精彩時刻',
      longDescription: '隨時隨地上傳你的精彩照片，與同學們分享美好回憶。支援批量上傳、智能相簿分類等功能。',
      icon: <PhotoCameraIcon />,
      path: '/photo-submission',
      color: '#2196f3',
      stats: '未來的畢業相簿',
      image: 'https://i.imgur.com/BDma6JK.jpg',
      highlight: true,
      actions: [
        { text: '立即上傳', path: '/photo-submission', primary: true },
        { text: '瀏覽相簿', path: '/album', primary: false }
      ]
    },
    {
      title: '活動資訊',
      description: '掌握最新活動動態',
      longDescription: '隨時隨地掌握最新活動動態，包括畢聯活動、畢業典禮、等。',
      icon: <EmojiEventsIcon />,
      path: '/events',
      color: '#f50057',
      stats: '隨時掌握最新活動',
      image: 'https://i.imgur.com/4CCbKaE.png',
      highlight: true,
      actions: [
        { text: '查看活動', path: '/events', primary: true },
        { text: '活動回顧', path: '/events/history', primary: false }
      ]
    },
    {
      title: '畢業相簿',
      description: '珍藏美好回憶',
      longDescription: '隨時隨地珍藏你的美好回憶，包括畢業前回憶、校慶、學校活動等。',
      icon: <PhotoCameraIcon />,
      path: '/album',
      color: '#4caf50',
      stats: '未來的畢業相簿',
      image: 'https://i.imgur.com/ZmehiGl.jpg',
      highlight: true,
      actions: [
        { text: '立即上傳', path: '/photo-submission', primary: true },
        { text: '瀏覽相簿', path: '/album', primary: false }
      ]
    },
    {
      title: '意見箱',
      description: '分享你的想法',
      longDescription: '隨時隨地分享你的想法，包括對畢聯的建議、對活動的意見等。',
      icon: <ChatIcon />,
      path: '/feedback',
      color: '#ff9800',
      stats: '畢聯會需要您的意見',
      image: 'https://i.imgur.com/ZmehiGl.jpg',
      highlight: true,
      actions: [
        { text: '立即上傳', path: '/photo-submission', primary: true },
        { text: '瀏覽相簿', path: '/album', primary: false }
      ]
    }
  ];

  // 如果沒有圖片，可以使用這些替代圖片
  const defaultImages = {
    logo: '/qq.png',
    feature: 'https://i.imgur.com/GoiXvgA.jpg',
    background: 'https://i.imgur.com/GoiXvgA.jpg'
  };

  // 新增功能區塊
  const sections = [
    {
      title: '最新消息',
      subtitle: '掌握第一手資訊',
      content: (
        <Box sx={{ py: 8, bgcolor: 'background.default' }}>
          <Container maxWidth="lg">
            <Timeline position="alternate">
              {recentAnnouncements.map((announcement, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent color="text.secondary">
                    {new Date(announcement.created_at).toLocaleDateString()}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} sx={{ p: 3, cursor: 'pointer' }}>
                      <Typography variant="h6">{announcement.title}</Typography>
                      <Typography color="text.secondary">
                        {announcement.content.substring(0, 100)}...
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Container>
        </Box>
      )
    },
    {
      title: '重要時程',
      subtitle: '掌握關鍵時間點',
      content: (
        <Box sx={{ py: 8, bgcolor: 'background.paper' }}>
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              {timelineEvents.map((event, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Paper
                    sx={{
                      p: 3,
                      height: '100%',
                      background: `linear-gradient(135deg, ${event.color}20 0%, ${event.color}40 100%)`,
                      position: 'relative',
                      overflow: 'hidden',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        '& .event-overlay': {
                          opacity: 1
                        }
                      }
                    }}
                  >
                    <Typography variant="h5" gutterBottom>
                      {event.title}
                    </Typography>
                    <Typography variant="h4" sx={{ color: event.color, fontWeight: 700 }}>
                      {event.date}
                    </Typography>
                    <Typography>{event.description}</Typography>
                    <Box
                      className="event-overlay"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: `${event.color}10`,
                        opacity: 0,
                        transition: 'opacity 0.3s ease'
                      }}
                    />
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      )
    }
  ];

  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  const particlesOptions = {
    background: {
      color: {
        value: "transparent",
      },
    },
    particles: {
      number: {
        value: 30,
        density: {
          enable: true,
          value_area: 800
        }
      },
      color: {
        value: "#ffffff"
      },
      shape: {
        type: "circle"
      },
      opacity: {
        value: 0.3,
        random: false
      },
      size: {
        value: 2,
        random: true
      },
      line_linked: {
        enable: true,
        distance: 150,
        color: "#ffffff",
        opacity: 0.2,
        width: 1
      },
      move: {
        enable: true,
        speed: 1,
        direction: "none",
        random: false,
        straight: false,
        out_mode: "out",
        bounce: false,
      }
    },
    interactivity: {
      detect_on: "canvas",
      events: {
        onhover: {
          enable: true,
          mode: "repulse"
        },
        resize: true
      },
      modes: {
        repulse: {
          distance: 100,
          duration: 0.4
        }
      }
    },
    retina_detect: true
  };

  return (
    <Box>
      {/* Loading 效果 - 添加更平滑的動畫 */}
      <Backdrop
        open={isLoading}
        sx={{ 
          ...glassEffect,
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: 'linear-gradient(135deg, rgba(26,35,126,0.95) 0%, rgba(13,71,161,0.95) 100%)',
        }}
      >
        <Stack spacing={3} alignItems="center">
          <Box
            component="img"
            src={defaultImages.logo}
            alt="Logo"
            sx={{
              width: 120,
              height: 120,
              animation: 'pulse 1.5s infinite',
              filter: 'brightness(0) invert(1)'
            }}
          />
          <Typography variant="h6">載入中...</Typography>
          <LinearProgress 
            sx={{ 
              width: '200px',
              '& .MuiLinearProgress-bar': {
                background: 'linear-gradient(45deg, #f50057 30%, #ff4081 90%)'
              }
            }}
          />
        </Stack>
      </Backdrop>

      {/* Hero Section - 添加動態背景 */}
      <Box 
        sx={{ 
          position: 'relative',
          minHeight: '100vh',
          background: 'linear-gradient(135deg, rgba(26,35,126,0.98) 0%, rgba(13,71,161,0.98) 100%)',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'radial-gradient(circle at 50% 50%, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 50%)',
            animation: 'pulse 15s infinite'
          }
        }}
      >
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={particlesOptions}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1
          }}
        />

        {/* Hero Content - 添加玻璃效果 */}
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
          <Box sx={{ ...glassEffect, borderRadius: 4, p: 4, mt: 8 }}>
            <Grid container spacing={6} alignItems="center">
              <Grid item xs={12} md={7}>
                <Slide direction="right" in timeout={1000}>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                      <Box
                        component="img"
                        src={defaultImages.logo}
                        alt="Logo"
                        sx={{
                          height: isMobile ? '40px' : '60px',
                          width: 'auto',
                          mr: 2,
                          filter: 'brightness(0) invert(1)'
                        }}
                      />
                      <Typography 
                        variant={isMobile ? "h3" : "h2"}
                        sx={{ 
                          fontWeight: 800,
                          background: 'linear-gradient(45deg, #fff 30%, #e3f2fd 90%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                        }}
                      >
                        中山畢聯會
                      </Typography>
                    </Box>
                    
                    {/* 標語 */}
                    <Typography 
                      variant="h5"
                      sx={{ 
                        color: 'rgba(255,255,255,0.9)',
                        mb: 4,
                        fontWeight: 400,
                        letterSpacing: 1
                      }}
                    >
                      攜手共創美好回憶
                    </Typography>

                    {/* 倒數計時器 */}
                   

                    {/* 快速操作按鈕 */}
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          bgcolor: 'primary.main',
                          color: 'white',
                          px: 4,
                          py: 1.5,
                          '&:hover': {
                            bgcolor: 'primary.dark',
                            transform: 'translateY(-2px)'
                          },
                          transition: 'all 0.3s'
                        }}
                        onClick={() => navigate('/photo-submission')}
                      >
                        上傳照片
                      </Button>
                    </Stack>
                  </Box>
                </Slide>
              </Grid>

              {/* Hero 圖片 */}
              <Grid item xs={12} md={5}>
                <Slide direction="left" in timeout={1000}>
                  <Box
                    component="img"
                    src={defaultImages.feature}
                    alt="Feature"
                    sx={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: 4,
                      boxShadow: '0 20px 40px rgba(0,0,0,0.3)',
                      transform: 'perspective(1000px) rotateY(-10deg)',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'perspective(1000px) rotateY(0deg)'
                      }
                    }}
                  />
                </Slide>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      {/* 功能特色區 - 改進卡片設計 */}
      <Container maxWidth="lg" sx={{ py: 12 }}>
        <Typography 
          variant="h3" 
          align="center" 
          gutterBottom
          sx={{ 
            fontWeight: 700,
            background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          功能特色
        </Typography>
        <Typography 
          variant="h6" 
          align="center" 
          color="text.secondary"
          sx={{ mb: 8 }}
        >
          探索豐富功能
        </Typography>

        {/* 重點功能展示 */}
        {features.filter(f => f.highlight).map((feature, index) => (
          <Paper
            key={index}
            sx={{
              mb: 6,
              overflow: 'hidden',
              borderRadius: 4,
              background: `linear-gradient(135deg, ${feature.color}05 0%, ${feature.color}10 100%)`,
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: `0 20px 40px ${feature.color}20`
              },
              transition: 'all 0.3s ease'
            }}
          >
            <Grid container spacing={0} direction={index % 2 === 0 ? 'row' : 'row-reverse'}>
              {/* 圖片區塊 */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    position: 'relative',
                    height: '400px',
                    overflow: 'hidden'
                  }}
                >
                  <Box
                    component="img"
                    src={feature.image}
                    alt={feature.title}
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      transition: 'transform 0.3s ease',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'scale(1.05)'
                      }
                    }}
                    onClick={() => navigate(feature.actions[0].path)}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      p: 2,
                      background: 'linear-gradient(to top, rgba(0,0,0,0.7), transparent)',
                      color: 'white',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography variant="h6">
                      {feature.stats}
                    </Typography>
                    <Chip 
                      label="點擊查看" 
                      sx={{ 
                        color: 'white',
                        bgcolor: 'rgba(255,255,255,0.2)',
                        '&:hover': {
                          bgcolor: 'rgba(255,255,255,0.3)'
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              {/* 內容區塊 */}
              <Grid item xs={12} md={6} sx={{ p: 4 }}>
                <Box sx={{ mb: 3 }}>
                  <Avatar
                    sx={{
                      width: 80,
                      height: 80,
                      bgcolor: `${feature.color}15`,
                      color: feature.color,
                      mb: 2
                    }}
                  >
                    {feature.icon}
                  </Avatar>
                  <Typography variant="h4" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {feature.longDescription}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={2}>
                  {feature.actions.map((action, idx) => (
                    <Button
                      key={idx}
                      variant={action.primary ? "contained" : "outlined"}
                      onClick={() => navigate(action.path)}
                      sx={{
                        bgcolor: action.primary ? feature.color : 'transparent',
                        borderColor: feature.color,
                        color: action.primary ? 'white' : feature.color,
                        px: 3,
                        py: 1,
                        '&:hover': {
                          bgcolor: action.primary ? `${feature.color}dd` : `${feature.color}11`,
                          transform: 'translateY(-2px)'
                        },
                        transition: 'all 0.3s ease'
                      }}
                    >
                      {action.text}
                    </Button>
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        ))}

        {/* 其他功能網格展示 */}
        <Grid container spacing={4}>
          {features.filter(f => !f.highlight).map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Paper
                  sx={{
                    height: '100%',
                    textAlign: 'center',
                    transition: 'all 0.3s ease',
                    cursor: 'pointer',
                    position: 'relative',
                    overflow: 'hidden',
                    background: `linear-gradient(135deg, ${feature.color}10 0%, ${feature.color}20 100%)`,
                    '&:hover': {
                      transform: 'translateY(-10px)',
                      '& .feature-stats': {
                        transform: 'translateY(0)'
                      },
                      '& .feature-image': {
                        transform: 'scale(1.1)'
                      }
                    }
                  }}
                  onClick={() => navigate(feature.path)}
                >
                  <Box
                    className="feature-image"
                    sx={{
                      position: 'relative',
                      height: 200,
                      overflow: 'hidden',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: `linear-gradient(to bottom, transparent 0%, ${feature.color}20 100%)`
                      }
                    }}
                  >
                    <Box
                      component="img"
                      src={feature.image || defaultImages.feature}
                      alt={feature.title}
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        transition: 'transform 0.3s ease'
                      }}
                    />
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Avatar
                      sx={{
                        width: 60,
                        height: 60,
                        bgcolor: `${feature.color}15`,
                        color: feature.color,
                        margin: '-50px auto 16px',
                        border: '4px solid #fff',
                        boxShadow: '0 8px 16px rgba(0,0,0,0.1)'
                      }}
                    >
                      {feature.icon}
                    </Avatar>
                    <Typography variant="h6" gutterBottom>
                      {feature.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {feature.description}
                    </Typography>
                  </Box>
                  <Box
                    className="feature-stats"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      p: 1,
                      bgcolor: feature.color,
                      color: 'white',
                      transform: 'translateY(100%)',
                      transition: 'transform 0.3s ease'
                    }}
                  >
                    {feature.stats}
                  </Box>
                </Paper>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* 時程區塊 */}
      <Box sx={{ bgcolor: 'background.paper', py: 12 }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            align="center" 
            gutterBottom
            sx={{ 
              fontWeight: 700,
              mb: 6
            }}
          >
            重要時程
          </Typography>
          <Grid container spacing={4}>
            {timelineEvents.map((event, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    height: '100%',
                    background: `linear-gradient(135deg, ${event.color}10 0%, ${event.color}20 100%)`,
                    border: `1px solid ${event.color}30`,
                    borderRadius: 4,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: `0 12px 24px -10px ${event.color}40`
                    }
                  }}
                >
                  <Typography variant="h5" gutterBottom color={event.color}>
                    {event.title}
                  </Typography>
                  <Typography variant="h4" sx={{ fontWeight: 700, mb: 2 }}>
                    {event.date}
                  </Typography>
                  <Typography color="text.secondary">
                    {event.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* 最新消息區塊 */}
      <Box sx={{ py: 12 }}>
        <Container maxWidth="lg">
          {/* ... 原有的最新消息內容 ... */}
        </Container>
      </Box>

      {/* 回到頂部按鈕 */}
      <Zoom in={showScrollTop}>
        <Box
          onClick={scrollToTop}
          sx={{
            position: 'fixed',
            bottom: 90,
            right: 16,
            cursor: 'pointer'
          }}
        >
          <Fab color="primary" aria-label="scroll-to-top">
            <KeyboardArrowUpIcon />
          </Fab>
        </Box>
      </Zoom>

      {/* 快速操作選單 */}
      <SpeedDial
        ariaLabel="快速操作"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {features.map((action) => (
          <SpeedDialAction
            key={action.title}
            icon={action.icon}
            tooltipTitle={action.title}
            onClick={() => navigate(action.path)}
          />
        ))}
      </SpeedDial>

      {/* 自定義動畫 */}
      <style>
        {`
          @keyframes pulse {
            0% { transform: scale(1); opacity: 0.5; }
            50% { transform: scale(1.05); opacity: 0.8; }
            100% { transform: scale(1); opacity: 0.5; }
          }
          
          @keyframes backgroundScroll {
            0% { background-position: 0 0; }
            100% { background-position: 100% 100%; }
          }
          
          @keyframes float {
            0% { transform: translateY(0px); }
            50% { transform: translateY(-20px); }
            100% { transform: translateY(0px); }
          }
          
          @keyframes glow {
            0% { box-shadow: 0 0 5px rgba(255,255,255,0.5); }
            50% { box-shadow: 0 0 20px rgba(255,255,255,0.8); }
            100% { box-shadow: 0 0 5px rgba(255,255,255,0.5); }
          }
          
          .floating {
            animation: float 6s ease-in-out infinite;
          }
          
          .glowing {
            animation: glow 2s ease-in-out infinite;
          }
        `}
      </style>
    </Box>
  );
} 