import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  Box,
  Typography,
  Divider,
  Tooltip,
  ListItemButton,
  // ... 其他導入
} from '@mui/material';
import {
  Assignment as AssignmentIcon,
  AssignmentLate as UrgentTaskIcon,  // 緊急任務圖標
  Description as DescriptionIcon,
  Brush as BrushIcon,
  Videocam as VideoIcon,
  Event as EventIcon,
  Article as ArticleIcon,
  PostAdd as PostAddIcon,
  Chat as ChatIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';

export default function DepartmentSidebar({ user }) {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [pendingTasks, setPendingTasks] = useState(0);
  const [urgentTasks, setUrgentTasks] = useState(0);

  // 獲取任務數據
  useEffect(() => {
    const fetchTasks = async () => {
      if (user?.department) {
        const { data, error } = await supabase
          .from('tasks')
          .select('*')
          .eq('department', user.department)
          .order('priority', { ascending: false })
          .order('created_at', { ascending: false });

        if (!error && data) {
          setTasks(data);
          setPendingTasks(data.filter(task => task.status === 'pending').length);
          setUrgentTasks(data.filter(task => 
            task.priority === 'high' && task.status !== 'completed'
          ).length);
        }
      }
    };

    fetchTasks();
    const interval = setInterval(fetchTasks, 60000);
    return () => clearInterval(interval);
  }, [user]);

  // 任務列表項目
  const TaskListItem = () => (
    <Tooltip title={`待處理: ${pendingTasks} | 緊急: ${urgentTasks}`} placement="right">
      <ListItemButton
        onClick={() => navigate('/dashboard/tasks/list')}
        sx={{
          bgcolor: urgentTasks > 0 ? 'error.lighter' : 
                 pendingTasks > 0 ? 'warning.lighter' : 
                 'transparent',
          '&:hover': {
            bgcolor: urgentTasks > 0 ? 'error.light' : 
                     pendingTasks > 0 ? 'warning.light' : 
                     'action.hover'
          }
        }}
      >
        <ListItemIcon>
          <Badge 
            badgeContent={pendingTasks} 
            color={urgentTasks > 0 ? "error" : "warning"}
          >
            {urgentTasks > 0 ? <UrgentTaskIcon color="error" /> : <AssignmentIcon />}
          </Badge>
        </ListItemIcon>
        <ListItemText 
          primary={
            <Typography 
              variant="body1" 
              color={urgentTasks > 0 ? "error" : "inherit"}
              fontWeight={pendingTasks > 0 ? "bold" : "normal"}
            >
              任務列表
            </Typography>
          }
          secondary={urgentTasks > 0 ? "有緊急任務！" : null}
        />
      </ListItemButton>
    </Tooltip>
  );

  // 最近任務預覽
  const RecentTasks = () => (
    <Box sx={{ p: 2, display: pendingTasks > 0 ? 'block' : 'none' }}>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        最新任務
      </Typography>
      {tasks
        .filter(task => task.status === 'pending')
        .slice(0, 3)
        .map(task => (
          <Box 
            key={task.id} 
            sx={{ 
              mb: 1,
              p: 1,
              borderRadius: 1,
              bgcolor: task.priority === 'high' ? 'error.lighter' : 'background.default'
            }}
          >
            <Typography variant="body2" noWrap>
              {task.title}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              截止日期: {task.due_date || '無'}
            </Typography>
          </Box>
        ))}
    </Box>
  );

  // 獲取菜單項目
  const getMenuItems = () => {
    let items = [];

    // 添加聊天室選項（所有用戶都可見）
  

    // 添加任務列表（始終顯示在最上方）
    if (user?.department) {
      items.push({
        component: TaskListItem
      });

      if (pendingTasks > 0) {
        items.push({
          component: RecentTasks
        });
      }
    }

    // 會長功能
    if (user?.role === 'president') {
      items.push(
        {
          text: '指派任務',
          icon: <AssignmentIcon />,
          path: '/dashboard/tasks/assign'
        },
        {
          text: '任務管理',
          icon: <AssignmentIcon />,
          path: '/dashboard/tasks/manage'
        },
        {
          text: '會議記錄審核',
          icon: <DescriptionIcon />,
          path: '/dashboard/document/meeting-review'
        },
        {
          text: '美宣作品審核',
          icon: <BrushIcon />,
          path: '/dashboard/art/review'
        },
        {
          text: '活動提案審核',
          icon: <EventIcon />,
          path: '/dashboard/activity/review'
        },
        {
            text: '影片企劃審核',
            icon: <VideoIcon />,
            path: '/dashboard/video/review'
        }
      );
    }

    // 文務組功能
    if (user?.department === '文務') {
      items.push(
        {
          text: '會議記錄',
          icon: <DescriptionIcon />,
          path: '/dashboard/document/meeting-minutes'
        },
        {
          text: '提交紀錄',
          icon: <DescriptionIcon />,
          path: '/dashboard/document/meeting-submissions'
        }
      );
    }

    // 美宣組功能
    if (user?.department === '美宣') {
      items.push(
        {
          text: '作品提交',
          icon: <BrushIcon />,
          path: '/dashboard/art/submit'
        },
        {
          text: '提交紀錄',
          icon: <BrushIcon />,
          path: '/dashboard/art/submissions'
        }
      );
    }

    // 活動組功能
    if (user?.department === '活動') {
      items.push(
        {
          text: '活動提案',
          icon: <EventIcon />,
          path: '/dashboard/activity/submit'
        },
        {
          text: '提交紀錄',
          icon: <EventIcon />,
          path: '/dashboard/activity/submissions'
        }
      );
    }
    if (user?.department === '影器') {
        items.push(
          {
            text: '影片企劃',
            icon: <VideoIcon />,
            path: '/dashboard/video/submit'
          },
          {
            text: '提交紀錄',
            icon: <VideoIcon />,
            path: '/dashboard/video/submissions'
          },
          {
            text: '進度追蹤',
            icon: <VideoIcon />,
            path: '/dashboard/video/progress'
          }
        );
      }

    // 所有成員都可以看到貼文列表
    items.push({
      text: '貼文列表',
      icon: <ArticleIcon />,
      path: '/dashboard/posts'
    });

    // 如果是會長，添加貼文管理選項
    if (user?.role === 'president') {
      items.push({
        text: '發布貼文',
        icon: <PostAddIcon />,
        path: '/dashboard/posts/manage'
      });
    }

    return items;
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* 頂部標題 */}
      <Box sx={{ 
        p: 2, 
        borderBottom: 1, 
        borderColor: 'divider',
        bgcolor: 'primary.lighter'
      }}>
        <Typography 
          variant="subtitle1" 
          fontWeight={600}
          color="primary.dark"
        >
          {user?.department || '功能選單'}
        </Typography>
      </Box>

      {/* 選單列表 */}
      <List sx={{ 
        flex: 1, 
        overflow: 'auto',
        px: 1.5,
        py: 2,
        '& .MuiListItemButton-root': {
          borderRadius: 1,
          mb: 0.5,
          '&:hover': {
            bgcolor: 'primary.lighter',
          },
          '&.Mui-selected': {
            bgcolor: 'primary.main',
            color: 'white',
            '&:hover': {
              bgcolor: 'primary.dark',
            },
            '& .MuiListItemIcon-root': {
              color: 'white',
            },
            '& .MuiTypography-root': {
              fontWeight: 600,
            }
          }
        }
      }}>
        {getMenuItems().map((item, index) => {
          if (item.component) {
            return <item.component key={index} />;
          }
          
          const isActive = location.pathname === item.path;
          
          return (
            <ListItemButton
              key={index}
              onClick={() => navigate(item.path)}
              selected={isActive}
              sx={{
                transition: 'all 0.2s',
                '& .MuiListItemIcon-root': {
                  color: isActive ? 'inherit' : 'primary.main',
                  minWidth: 40,
                },
                '& .MuiTypography-root': {
                  fontSize: '0.95rem',
                  transition: 'font-weight 0.2s',
                }
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText 
                primary={item.text}
                primaryTypographyProps={{
                  fontWeight: isActive ? 600 : 500
                }}
              />
            </ListItemButton>
          );
        })}
      </List>

      {/* 底部資訊 */}
      <Box sx={{ 
        p: 2, 
        borderTop: 1, 
        borderColor: 'divider',
        bgcolor: 'background.default'
      }}>
        <Typography 
          variant="caption" 
          color="text.secondary"
          sx={{ display: 'block', textAlign: 'center' }}
        >
          {user?.name} • {user?.department || '系統管理員'}
        </Typography>
      </Box>
    </Box>
  );
} 